import React, { useContext, useEffect, useState } from 'react';
import { IonIcon } from '@ionic/react';
import { heart, heartOutline } from 'ionicons/icons';
import classes from './styles.module.scss';
import { GlobalContext } from '../../../../context/provider';
import {
  addToWishList,
  getWishList,
} from '../../../../context/actions/products';
import { useHistory } from 'react-router';
import { isLoggedIn } from '../../../../utils/isLoggedIn';

const WishlistIcon = ({ productId }: { productId: any }) => {
  const [isBookmarked, setIsBookmarked] = useState<boolean>(false);
  const { productState, productDispatch } = useContext<any>(GlobalContext);

  const history = useHistory();
  const clickHandler = async (e: any) => {
    e.stopPropagation();
    if (!isLoggedIn()) {
      history.push('/login');
      return;
    }
    setIsBookmarked((prevIsBookmarked) => !prevIsBookmarked);
    await addToWishList(productId)(productDispatch);
    await getWishList()(productDispatch);
  };

  useEffect(() => {
    const initialIsBookmarked = productState?.wishlist?.some(
      (item: any) => item?.product?.id === productId
    );
    setIsBookmarked(initialIsBookmarked);
  }, [productState.wishlist, productId]);

  return (
    <div className={isBookmarked ? classes.clicked : classes.icon}>
      {isLoggedIn() && isBookmarked ? (
        <IonIcon icon={heart} onClick={(e) => clickHandler(e)} />
      ) : (
        <IonIcon icon={heartOutline} onClick={(e) => clickHandler(e)} />
      )}
    </div>
  );
};

export default WishlistIcon;
