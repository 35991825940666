import { axiosInstance } from "../../../utils/axios";
import { Dispatch } from "react";
import { GET_ADMIN_STORES, GET_ADMIN_STORE_ERRORS } from "../types";

export const getAdminStores =
  () => async (dispatch: Dispatch<{ type: string; payload: any }>) => {
    try {
      const res = await axiosInstance.get(`/v1/admin/stores`);
      dispatch({ type: GET_ADMIN_STORES, payload: res.data.data });
    } catch (error: any) {
      dispatch({ type: GET_ADMIN_STORE_ERRORS, payload: error.response.data.message || error.message });
    }
  };
