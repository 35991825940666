/* eslint-disable no-restricted-globals */
import { IonIcon, IonItem, IonLabel, IonRippleEffect } from '@ionic/react';
import { personSharp, closeOutline } from 'ionicons/icons';
import { MouseEvent, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import SearchBar from '../Layout/user/searchBar/searchBar';
import { otherRoutes } from '../../utils/user/routes';
import classes from './styles.module.scss';
import { DeviceContext } from '../../deviceType';
import { isLoggedIn } from '../../utils/isLoggedIn';
import { GlobalContext } from '../../context/provider';
import logo from '../../Assets/logo/logo_clear_96x96.svg';
import arrowDown from '../../Assets/arrow-ios-downward-svgrepo-com.svg';
import arrowForward from '../../Assets/arrow-ios-forward-svgrepo-com.svg';
import { authService } from '../../utils/authService';

const User = (props: { action: any }) => {
  const { userState } = useContext<any>(GlobalContext);
  const userDetails = userState.getUser;
  const history = useHistory();
  const device = useContext(DeviceContext);
  const loggedIn = isLoggedIn();
  const [openGroup, setOpenGroup] = useState<number | undefined>(undefined);
  const handleOpen = (id: number) => {
    if (id === openGroup) {
      setOpenGroup(undefined);
    } else {
      setOpenGroup(id);
    }
  };

  return (
    <div className={classes.user}>
      <div className={classes.menuBody}>
        <div className={classes.header}>
          <div className={classes.logo}>
            <IonIcon src={logo}></IonIcon>
            <span className={classes.span1}>Bazaar</span>{' '}
            <span className={classes.span2}>Cheap</span>
          </div>
          <div
            className={classes.close + ' ion-activatable ripple-parent circle'}
            onClick={() => {
              props.action.close();
            }}
          >
            <IonRippleEffect></IonRippleEffect>
            <IonIcon icon={closeOutline} color={'secondary'}></IonIcon>
          </div>
        </div>
        {/* Disabled for first version */}
        <div
          className={classes.searchBar}
          style={{
            height: device.width < 769 ? '7vh' : '18vh',
            display: 'none',
          }}
          onClickCapture={(e: MouseEvent) => {
            const target: any = e.target;
            const elem: any[] = Object.values({ ...target }).filter(
              (item: any) => {
                return (
                  item instanceof Object &&
                  Object.keys(item).includes('elementType')
                );
              }
            );
            if (Object.values(elem[0]).includes('ion-button')) {
              props.action.close();
            }
          }}
        >
          <SearchBar />
        </div>
        <div className={classes.links}>
          <div
            className={classes.auth + ' ion-activatable ripple-parent circle'}
            onClick={() => {
              props.action.close();
              if (loggedIn) {
              } else {
                history.push('login');
              }
            }}
          >
            <IonRippleEffect></IonRippleEffect>
            <IonIcon slot="start" icon={personSharp} color="primary"></IonIcon>
            <div
              className={classes.name}
              style={{ color: loggedIn ? '#0f324d' : '#A94064' }}
            >
              {loggedIn ? userDetails?.firstName : 'Log In / Sign Up'}
            </div>
          </div>
          {otherRoutes.map((item, index) => {
            if (item.disabled) return null;
            if (item.isloggedIn && !userDetails) return null;
            return (
              <div className={classes.linkGrp} key={index}>
                <div className={classes.divider}>
                  <div className={classes.labelContainer}>
                    <IonIcon
                      icon={item.icon}
                      color="primary"
                      size="default"
                      style={{ marginRight: '3%' }}
                    ></IonIcon>
                    <div
                      className={
                        classes.title + ' ion-activatable ripple-parent circle'
                      }
                      onClick={() => {
                        // TODO: check logic validity
                        if (item.name === 'Log Out') {
                          authService.logoutUser();
                        }
                        props.action.close();
                        history.push({ pathname: item.route });
                      }}
                    >
                      <IonRippleEffect></IonRippleEffect>
                      {item.name}
                    </div>
                  </div>
                  {item.children && (
                    <div
                      className={classes.collapseList}
                      onClick={() => {
                        handleOpen(index);
                      }}
                    >
                      <IonIcon
                        mode="ios"
                        slot="icon-only"
                        src={index === openGroup ? arrowDown : arrowForward}
                        color="primary"
                        size="small"
                      />
                    </div>
                  )}
                </div>
                {index === openGroup && item.children && (
                  <div className={classes.list}>
                    {item.children.map((value, ind) => {
                      return (
                        <IonItem
                          className={classes.item}
                          key={ind}
                          lines="none"
                          onClick={() => {
                            props.action.close();
                            history.push({ pathname: value.route });
                          }}
                        >
                          <IonLabel className={classes.label}>
                            {value.name}
                          </IonLabel>
                        </IonItem>
                      );
                    })}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
      <div className={classes.menuFooter}>
        Copyright &copy; Bazaar Cheap {new Date().getFullYear()} | All Rights
        Reserved
      </div>
    </div>
  );
};

export default User;
