import React, { useContext, useEffect, useRef, useState } from 'react';
import Layout from '../../../components/Layout/dashboard';
import classes from './styles.module.scss';
import { DeviceContext, DeviceTypeProvider } from '../../../deviceType';
import { IonButton, IonIcon, IonRippleEffect } from '@ionic/react';
import {
  analyticsSharp,
  closeOutline,
  closeSharp,
  codeDownloadSharp,
  download,
  downloadSharp,
} from 'ionicons/icons';
import { GlobalContext, GlobalProvider } from '../../../context/provider';
import { addBulkProducts } from '../../../context/actions/products';
import {
  ADD_BULK_PRODUCTS,
  ADD_BULK_PRODUCTS_ERROR,
} from '../../../context/actions/types';
import sample from '../../../Assets/sample.csv';
import Spinner from '../../../components/spinner';
import { useHistory } from 'react-router';

const BulkProducts = () => {
  const size = useContext(DeviceContext);
  const { productState, productDispatch } = useContext<any>(GlobalContext);
  const input = useRef<any>(null);
  const inputContainer = useRef<any>(null);
  const [file, setFile] = useState<File>();
  const [error, setError] = useState<string | undefined>();
  const [success, setSuccess] = useState<string | undefined>();
  const sampledownload = useRef<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const history = useHistory();

  useEffect(() => {
    const i_cont = inputContainer.current;
    if (i_cont) {
      i_cont.addEventListener(
        'dragover',
        (e: DragEvent) => {
          e.stopPropagation();
          e.preventDefault();
        },
        false
      );
      i_cont.addEventListener(
        'drop',
        (e: DragEvent) => {
          e.stopPropagation();
          e.preventDefault();
          const file = e.dataTransfer?.files[0];
          setFile(file);
        },
        false
      );
    }
  }, []);

  useEffect(() => {
    if (productState.addBulkProducts) {
      setSuccess(
        'You will be notified by email when your products have been uploaded successfully. You will have to refresh the dashboard to see your new products'
      );
      setTimeout(() => setSuccess(undefined), 20000);
      productDispatch({ type: ADD_BULK_PRODUCTS, payload: null });
    }
    if (productState.addBulkProductsError) {
      setError(
        productState.addBulkProductsError.response?.data
          ? productState.addBulkProductsError.response.data.message
          : productState.addBulkProductsError.message
      );
      setTimeout(() => setError(undefined), 20000);
      productDispatch({ type: ADD_BULK_PRODUCTS_ERROR, payload: null });
    }
    setLoading(false);
  }, [productState]);

  const handleSubmit = () => {
    setLoading(true);
    const csv = file;
    if (csv?.type !== 'text/csv') {
      setError('CSV file required for Bulk Upload');
      return;
    }
    uploadFile(csv);
    setFile(undefined);
  };
  const uploadFile = async (e: File) => {
    await addBulkProducts(e)(productDispatch);
  };
  const handleFileDownload = () => {
    sampledownload.current.click();
  };
  return (
    <Layout>
      <div
        className={classes.container}
        style={{
          padding: size.width >= 769 ? '2% 10%' : '3% 5%',
          overflow: size.width >= 769 ? 'hidden' : 'scroll',
        }}
      >
        {loading ? (
          <div className={classes.loading}>
            <Spinner type={'crescent'}></Spinner>
          </div>
        ) : (
          <>
            <div className={classes.head}>Bulk Products</div>
            <div
              className={classes.holder}
              style={{
                flexDirection: size.width >= 769 ? 'row' : 'column',
                display: size.width >= 769 ? 'flex' : 'block',
              }}
            >
              <div
                className={classes.typography}
                style={{ width: size.width >= 769 ? '60%' : '100%' }}
              >
                <div className={classes.paragraph + ' ' + classes.one}>
                  <h3>What is Bulk Products?</h3>
                  <p>
                    Bulk Products allows you to add a large number of products
                    to your store portfolio by uploading a list of the product
                    details in a CSV file.
                  </p>
                </div>
                <div className={classes.paragraph + ' ' + classes.two}>
                  <h3>How to add Bulk Products.</h3>
                  <ol>
                    <li>
                      Download the sample csv file and add products following
                      the sample data.
                    </li>
                    <li>
                      Replace the sample data with your own products or remove
                      it.
                    </li>
                    <li>Save the file as a csv.</li>
                    <li>
                      Upload your file or drag and drop it in the top-right
                      corner of this page.
                    </li>
                  </ol>
                </div>
                <div className={classes.paragraph + ' ' + classes.three}>
                  <h3>Get Notified of Bulk Products Success</h3>
                  <p>
                    After the success mark, please check your email for the
                    status of the Bulk Products Operation. If Unsuccessful,
                    please refer to the error message returned in the email.
                  </p>
                </div>
              </div>
              <div
                className={classes.actions}
                style={{ width: size.width >= 769 ? '40%' : '100%' }}
              >
                <div className={classes.fileHolder}>
                  {success ? (
                    <div className={classes.successMessage}>
                      <p>Upload Successful!</p>
                      <p>{success}</p>
                    </div>
                  ) : error ? (
                    <div className={classes.errorMessage}>
                      <p> Error!</p>
                      {error}
                    </div>
                  ) : file ? (
                    <div className={classes.file}>
                      <IonIcon
                        icon={analyticsSharp}
                        color="secondary"
                      ></IonIcon>
                      <p>{file.name}</p>
                      <div className={classes.btn}>
                        <div
                          className="ion-activatable ripple-parent cirlce"
                          onClick={() => {
                            setFile(undefined);
                          }}
                        >
                          <IonRippleEffect></IonRippleEffect>
                          <IonIcon
                            slot="icon-only"
                            icon={closeOutline}
                            color="secondary"
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className={classes.input} ref={inputContainer}>
                      <p>Drag & Drop to Upload File</p>
                      <p>Or</p>
                      <input
                        ref={input}
                        type="file"
                        accept=".csv"
                        onChange={(e) => {
                          setFile(e.target.files!?.[0]);
                        }}
                        style={{ display: 'none' }}
                      />
                      <IonButton
                        className={classes.btn}
                        fill="solid"
                        color="secondary"
                        onClick={() => {
                          input.current.click();
                        }}
                      >
                        Upload
                      </IonButton>
                    </div>
                  )}
                </div>

                <div className={classes.sampleHolder}>
                  <h3>Sample File</h3>
                  <div
                    className={
                      classes.sample + ' ion-activatable ripple-parent circle'
                    }
                    onClick={handleFileDownload}
                  >
                    <a ref={sampledownload} href={sample} download />
                    <IonRippleEffect></IonRippleEffect>
                    <IonIcon icon={analyticsSharp} color="secondary" />
                    <p>sample.csv</p>
                    <IonIcon icon={download} color="secondary" />
                  </div>
                </div>

                <div className={classes.submit}>
                  {success && (
                    <IonButton
                      fill="solid"
                      color="secondary"
                      expand={size.width <= 768 ? 'block' : undefined}
                      style={{ width: size.width <= 768 ? '100%' : 'auto' }}
                      onClick={() => {
                        history.push('store');
                      }}
                    >
                      Return to Store
                    </IonButton>
                  )}
                  <IonButton
                    color={'secondary'}
                    fill="solid"
                    expand={size.width <= 768 ? 'block' : undefined}
                    onClick={handleSubmit}
                    disabled={!file}
                    style={{ width: size.width <= 768 ? '100%' : 'auto' }}
                  >
                    Submit
                  </IonButton>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </Layout>
  );
};

export default BulkProducts;
