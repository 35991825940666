/**
 * returns the absolute position of an element regardless of position/float issues
 * @param {HTMLElement} el - element to return position for
 * @returns {object} { x: num, y: num }
 */
export const getElementPosition = (el: HTMLElement | null) => {
  let x:number = 0,
    y:number = 0,
    n = true;

  do {
    if (n) {
      x += el?.offsetLeft || 0;
      y += el?.offsetTop || 0;
      n = false;
    } else if (el && getComputedStyle(el).position === 'relative') {
      n = true;
    }
    el = el!?.parentElement;
  } while (el && (el.tagName || '').toLowerCase() !== 'html');

  return { x, y};
};
