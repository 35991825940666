import { IonGrid, IonRow, IonCol } from '@ionic/react';
import React from 'react';
import ProductBlock from '../../../components/cards/Product_Block';
import classes from './styles.module.scss';
import { ProductType } from '../../../types/product';
import { storeSchema } from '../../../types/store';

const StoreProducts = ({
  products,
  getAll,
  store,
}: {
  getAll: () => void;
  products: ProductType[];
  store: storeSchema;
}) => {
  return (
    <div className={classes.productsWrapper}>
      <IonGrid className={classes.grid}>
        <IonRow className={classes.recentItems}>
          <IonCol>
            <div className={classes.recentLabel} onClick={getAll}>
              All
            </div>
            <div className={classes.list}>
              {products?.length ? (
                <div className={classes.recent}>
                  {products?.map((item: any, index: number) => {
                    return (
                      <ProductBlock
                        data={{ ...item, store }}
                        key={index}
                      />
                    );
                  })}
                </div>
              ) : (
                <div className={classes.noResults}>
                  Looks like theres nothing here
                </div>
              )}
            </div>
          </IonCol>
        </IonRow>
      </IonGrid>
    </div>
  );
};

export default StoreProducts;
