import React from 'react';

const UserProfile = (props: { color: string; placeholder: string }) => {
  return (
    <svg width="100%" height="100%">
      <circle cx="50%" cy="50%" r="50%" fill={props.color} />
      <text
        x="50%"
        y="50%"
        alignmentBaseline="central"
        textAnchor="middle"
        fontFamily="-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
        Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif"
        fontSize="18"
        fontWeight="bold"
        fill="#ffff"
      >
        {props.placeholder}
      </text>
    </svg>
  );
};

export default UserProfile;
