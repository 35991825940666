import { IonCol, IonGrid, IonList, IonRow } from '@ionic/react';
import {useContext, useEffect} from 'react'
import {useHistory} from 'react-router-dom';
import CategoryCard from '../../../components/cards/Category';
import Layout from '../../../components/Layout/user/Layout';
import {getTopCategories} from '../../../context/actions/top_categories';
import {GlobalContext} from '../../../context/provider'
import classes from './styles.module.scss'

const CategoriesPage = ()=>{
    const { topCategoriesState, topCategoriesDispatch } = useContext<any>(GlobalContext);

    const history = useHistory();

    useEffect(()=>{
        getCategoriesData()
    },[])

    const handleClick = (category:{type:string})=>{
    history.push({
        pathname: `/categories/${category.type}`,
        state: category
    })
   }
   const getCategoriesData = async()=>{
       await getTopCategories()(topCategoriesDispatch)
   }

   return(
      <Layout>
          <section className={classes.desktop}>
          <IonGrid className={classes.grid} fixed={true}>
          <IonRow>
              <IonCol>
                  <IonList className={classes.flex}>
                    {topCategoriesState?.topCategories?.map((item:any, index: number)=>
                         <CategoryCard
                        img= "https://images.unsplash.com/photo-1590779033100-9f60a05a013d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80"
                        name ={item.type.type}
                        children={item.products}
                        key={index}
                        onClick={()=>handleClick(item.type)}
                        />
                    )}
                  </IonList>
              </IonCol>
          </IonRow>
        </IonGrid>
        </section>
      </Layout>
   )
}

export default CategoriesPage;