import {
  IonCard,
  IonCol,
  IonGrid,
  IonRippleEffect,
  IonRow,
} from '@ionic/react';
import React, { useContext } from 'react';
import { useHistory } from 'react-router';
import { DeviceContext } from '../../../deviceType';
import classes from './styles.module.scss';
import { storeSchema } from '../../../types/store';

const StoreCard = (props: {
  data: storeSchema;
}) => {
  const size = useContext(DeviceContext);
  const history = useHistory();

  function dimensions() {
    if (size.width > 769) {
      return { width: '20vw', height: '15vw', margin: '0.5em' };
    } else {
      if (size.width > 460) {
        return { width: '40vw', height: '30vw', margin: '0.5em' };
      } else {
        return { width: '70vw', height: '50vw', margin: '0.5em' };
      }
    }
  }
  return (
    <IonCard
      className={
        classes.storeCard + ' ion-activatable ripple-parent rounded-rectangle'
      }
      style={{
        width: dimensions().width,
        height: dimensions().height,
        margin: dimensions().margin,
      }}
      onClick={() => {
        history.push({
          pathname: props.data.slug,
          state: { id: props.data.id },
        });
      }}
    >
      <IonRippleEffect></IonRippleEffect>
      <div className={classes.overlay}></div>
      <IonGrid className={classes.holder}>
        <IonRow className={classes.img}>
          <IonCol>
            <div>
              <img src={props.data?.logo!} alt="" />
            </div>
          </IonCol>
        </IonRow>
        <IonRow className={classes.info}>
          <IonCol>
            <div className={classes.storeName}>{props.data.name}</div>
            <div className={classes.storeType}>{props.data.type}</div>
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonCard>
  );
};

export default StoreCard;
