import {
  IonCard,
  IonCardContent,
  IonItem,
  IonSelect,
  IonSelectOption,
} from '@ionic/react';
import React, { useEffect, useState } from 'react';
import './styles.scss';

const SelectField = (props: {
  value: (arg0: (form: any) => any) => void;
  name: string;
  placeholder:string;
  options: any[];
  mode: 'ios' | 'md';
  formValues?: any;
}) => {
  const optionalProps: { value?: string | number } = {};
  if (props.formValues) {
    optionalProps.value = props.formValues?.[props.name];
  }
  return (
    <IonCard className="container" mode={props.mode}>
      <IonCardContent>
        <IonItem lines="none">
          <IonSelect
            color="primary"
            placeholder={props.placeholder}
            {...optionalProps}
            onIonChange={(e) => {
              props.value((form) => ({
                ...form,
                [props.name]: e.detail.value,
              }));
            }}
          >
            {props.options.map((item, index) => (
              <IonSelectOption key={index} value={item}>
                <p>{item}</p>
              </IonSelectOption>
            ))}
          </IonSelect>
        </IonItem>
      </IonCardContent>
    </IonCard>
  );
};

export default SelectField;
