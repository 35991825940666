import { axiosInstance } from '../../utils/axios';
import { Dispatch } from 'react';
import { GET_TOP_CATEGORIES, GET_TOP_CATEGORIES_ERROR } from './types';

export const getTopCategories =
  (limit: number = 6) =>
  async (dispatch: Dispatch<{ type: string; payload: any }>) => {
    try {
      const res = await axiosInstance.get(
        `/v1/most-searched?type=categories&limit=${limit}`
      );
      dispatch({ type: GET_TOP_CATEGORIES, payload: res.data.data });
    } catch (error: any) {
      dispatch({ type: GET_TOP_CATEGORIES_ERROR, payload: error });
    }
  };
