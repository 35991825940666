import { IonCol, IonGrid, IonRow } from '@ionic/react'
import Layout from '../../../components/Layout/user/Layout'
import {faqs} from '../../../data/faqs';
import FaqsItem from '../../../components/Faqs';
import { FaqsType } from '../../../types/faqs';

const AllQuestions = ()=>{
      return(
        <Layout>
        <IonGrid>
            <IonRow>
              <IonCol>
               {faqs.map((item:FaqsType)=>{
              return  <FaqsItem
                title={item.title}
                content={item.content}
                />
               })}
              </IonCol>
            </IonRow>
        </IonGrid>
        </Layout>
      )
}
export default AllQuestions