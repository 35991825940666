import { IonCard, IonRippleEffect } from '@ionic/react';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { DeviceContext } from '../../../deviceType';
import classes from './styles.module.scss';
import { getCurrentUser } from '../../../utils/getClientInfo';
import { ProductType } from '../../../types/product';
import WishlistIcon from '../../../pages/user/wish-list/wish-list-icon';

const ProductFlex = (props: { data: ProductType }) => {
  const size = useContext(DeviceContext);
  const history = useHistory();
  const description =
    props.data.description!?.length > 100
      ? props.data.description!?.substring(0, 100)
      : props.data.description;

  const price = props.data.price.toLocaleString('en-US', {
    maximumFractionDigits: 2,
  });

  const client = getCurrentUser();
  const [path, setPath] = useState<string>('');
  useEffect(() => {
    if (client.role === 'seller') {
      setPath(
        `/dashboard/items/detail?q=${props.data.id}&i=${props.data.name.slice(
          0,
          30
        )}`
      );
    } else {
      setPath(`/${props.data.store?.name}/prod?q=${props.data.id}&i=${props.data.name.slice(0, 30)}`);
    }
  }, []);

  function dimensions() {
    if (size.width > 769) {
      return { width: '27vw', height: '13vw', margin: '0.5em 0.8em' };
    } else {
      if (size.width > 460) {
        return { width: '40vw', height: '20vw', margin: '0.6em' };
      } else {
        return { width: '80vw', height: '40vw', margin: '0.4em auto' };
      }
    }
  }
  return (
    <div
      className={classes.productFlex}
      style={{
        width: dimensions().width,
        height: dimensions().height,
        margin: dimensions().margin,
      }}
      onClick={() => {
        history.push({
          pathname: path,
          state: { id: props.data.id },
        });
      }}
    >
      <div className={classes.overlay}>
        <div className="ion-activatable ripple-parent rounded-rectangle">
          <IonRippleEffect></IonRippleEffect>
        </div>
      </div>
      <section className={classes.contentHolder}>
        <div className={classes.img}>
          <div className={classes.holder}>
            <img alt="" src={props.data.images?.[0]} />
          </div>
        </div>
        <div className={classes.info}>
          <div className={classes.holder} style={{ padding: '0.5em' }}>
            <div className={classes.product}>
              <div>
                <div className={classes.title}>{props.data.name}</div>
                <div className={classes.shortDescription}>{description}</div>
              </div>
              <div>
                <WishlistIcon productId={props.data?.id} />
              </div>
            </div>

            <div className={classes.price}>UGX {price}</div>
            <div className={classes.store}>{props.data.store?.name}</div>
            <div className={classes.location}>
              {props.data.store?.location?.split(',')?.[0]}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ProductFlex;
