import { IonMenu } from '@ionic/react';
import { useRef } from 'react';
import classes from './styles.module.scss';
import SideNav from '../sidenav';
import Spinner from '../../../spinner';
import useGlobalContext from '../../../../context/provider';

const AdminSlideMenu = () => {
  const slideMenuRef = useRef<any>(null);
  const {userState} = useGlobalContext();
  const user = userState!.getUser;
  return (
    <IonMenu
      side="end"
      swipe-gesture
      contentId="main"
      className={classes.menu_holder}
      menuId="admin-menu"
      ref={slideMenuRef}
    >
      {user?.id ? (
        <SideNav adminDetails={user} action={slideMenuRef.current}></SideNav>
      ) : (
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Spinner />
        </div>
      )}
    </IonMenu>
  );
};

export default AdminSlideMenu;
