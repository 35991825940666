import {
  IonCard,
  IonCardContent,
  IonIcon,
  IonInput,
  IonItem,
  IonTextarea,
} from '@ionic/react';
import { checkmark, close } from 'ionicons/icons';
import './styles.scss';
import { TextFieldTypes } from '@ionic/core';
import { format } from 'date-fns';
import React, { useEffect, useRef, useState } from 'react';

export interface InputCardTypes {
  Ref?: any;
  onFocusOff?: (event: CustomEvent<FocusEvent>) => void;
  onFocus?: (event: CustomEvent<FocusEvent>) => void;
  type: TextFieldTypes;
  placeholder: string;
  formValues?: any;
  name: string;
  label?:boolean;
  icon?: boolean;
  disabled?:boolean;
  mode?: 'ios' | 'md';
  maxLength?: number | 256;
  minLength?: number | 1;
  clear?: boolean;
  inputType: 'text-field' | 'text-box';
  required: boolean;
  value: (arg0: (form: any) => any) => any;
  enterEvent?: (event: KeyboardEvent) => void;
}

const InputCard = ({
  Ref,
  onFocusOff,
  onFocus,
  type,
  placeholder,
  name,
  icon,
  mode,
  maxLength,
  minLength,
  clear,
  inputType,
  required,
  formValues,
  value,
  enterEvent,
  disabled
}: InputCardTypes) => {
  const optionalProps: { value?: string | number } = {};

  if (formValues) {
    optionalProps.value = formValues?.[name];
  }
  const dispatchEnterAction = (e: Event) => {
    e.target?.addEventListener('keydown', (e: Event) => {
      if ((e as KeyboardEvent).key === 'Enter') {
        enterEvent && enterEvent(e as KeyboardEvent);
      }
    });
  };
  return (
    <IonCard className="inputCard" mode={mode}>
      <IonCardContent>
        <IonItem lines="none" >
          {inputType === 'text-box' ? (
            <IonTextarea
              {...(optionalProps as { value: string | null | any })}
              onIonFocus={dispatchEnterAction}
              required={required}
              ref={Ref}
              placeholder={placeholder}
              name={name}
              color="primary"
              onIonBlur={onFocusOff}
              minlength={minLength}
              maxlength={maxLength}
              onIonChange={(e) => {
                value((form: any) => {
                  if (
                    Object.prototype.toString.call(form) === '[object Object]'
                  ) {
                    return {
                      ...form,
                      [name]: e.detail!.value,
                    };
                  }
                  if (Array.isArray(form)) {
                    return [...form, e.detail!.value];
                  }
                });
              }}
            ></IonTextarea>
          ) : (
            <IonInput
              {...optionalProps}
              onIonFocus={dispatchEnterAction}
              required={required}
              ref={Ref}
              type={type}
              placeholder={placeholder}
              name={name}
              pattern={'string'}
              color="primary"
              clearInput={clear}
              onIonBlur={onFocusOff}
              minlength={minLength}
              maxlength={maxLength}
              onIonChange={(e) => {
                value((form: any) => {
                  if (
                    Object.prototype.toString.call(form) === '[object Object]'
                  ) {
                    return {
                      ...form,
                      [name]: e.detail!.value,
                    };
                  }
                  if (Array.isArray(form)) {
                    return [...form, e.detail!.value];
                  }
                });
              }}
            ></IonInput>
          )}

          {icon !== undefined &&
            (icon === true ? (
              <IonIcon slot="end" color="success" icon={checkmark}></IonIcon>
            ) : (
              <IonIcon slot="end" color="danger" icon={close}></IonIcon>
            ))}
        </IonItem>
      </IonCardContent>
    </IonCard>
  );
};

export default InputCard;
