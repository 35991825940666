import { axiosInstance } from '../../utils/axios';
import { Dispatch } from 'react';
import { CONTACT_US, CONTACT_US_ERROR } from './types';

export const contactUs =
  (payload: any) =>
  async (dispatch: Dispatch<{ type: string; payload: any}>) => {
    try {
      const res = await axiosInstance.post('/v1/contact-us', payload);
      console.log('res', res.data)
      dispatch({ type: CONTACT_US, payload: res.data.data });
    } catch (error: any) {
      console.log('error', error)
      dispatch({ type: CONTACT_US_ERROR, payload: error });
    }
  };
