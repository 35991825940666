import { IonLabel, IonRow, IonCol, IonInput, IonButton } from '@ionic/react';
import React, { useEffect, useReducer, useRef, useState } from 'react';
import classes from './style.module.scss';

function priceReducer(state: any, action: { type: string; payload: any }) {
  switch (action.type) {
    case 'setPriceBelow':
      return { ...state, below: action.payload };
    case 'setPriceAbove':
      return { ...state, above: action.payload };
    default:
      return state;
  }
}

const PriceRangeInput = (props: {
  range: { max: number; min: number };
  setPrice: React.Dispatch<any>;
}) => {
  const priceRangeOverlay = useRef<any>();
  const [priceRange, setPriceRange] = useState<{ min: number; max: number }>({
    max: 0,
    min: 0,
  });
  const [price, priceDispatch] = useReducer(priceReducer, {});

  useEffect(() => {
    setPriceRange(props.range);
    priceDispatch({
      type: 'setPriceAbove',
      payload: props.range.min,
    });
    priceDispatch({
      type: 'setPriceBelow',
      payload: props.range.max,
    });
  }, []);

  function updatePriceParam() {
     props.setPrice({
       type: 'setPriceRange',
       payload: `${price.above}-${price.below}`,
     });
  }
  return (
    <div className={classes.mainContainer}>
      <IonLabel>Price (UGX)</IonLabel>
      <div className={classes.form}>
        <IonRow className={classes.priceRange}>
          <div className={classes.range}>
            <div className={classes.labels}>
              <span>Min</span>
              <span>Max</span>
            </div>

            <div className={classes.slider}>
              <div
                className={classes.overlay}
                ref={priceRangeOverlay}
                style={{ width: '100%', left: '0%' }}
              ></div>
              <input
                id={classes.fromSlider}
                type="range"
                value={price.above}
                min={priceRange.min}
                max={priceRange.max}
                onChange={(e) => {
                  priceDispatch({
                    type: 'setPriceAbove',
                    payload: e.target.value,
                  });

                  const percentageMoved = Math.floor(
                    ((Number(e.target.value) - Number(e.target.min)) /
                      (Number(e.target.max) - Number(e.target.min))) *
                      100
                  );
                  const currentWidth =
                    priceRangeOverlay.current.style.width.replace('%', '');

                  priceRangeOverlay.current.style.left = `${percentageMoved}%`;
                  priceRangeOverlay.current.style.width = `${
                    100 - percentageMoved
                  }%`;
                }}
              />
              <input
                type="range"
                className={classes.toSlider}
                value={price.below}
                min={priceRange.min}
                max={priceRange.max}
                onChange={(e) => {
                  const percentageMoved = Math.round(
                    ((Number(e.target.max) - Number(e.target.value)) /
                      Number(e.target.max)) *
                      100
                  );
                  // debugger
                  const currentWidth =
                    priceRangeOverlay.current.style.width.replace('%', '');

                  priceRangeOverlay.current.style.width = `${
                    100 - percentageMoved
                  }%`;

                  priceDispatch({
                    type: 'setPriceBelow',
                    payload: e.target.value,
                  });
                }}
              />
            </div>
          </div>
        </IonRow>
        <IonRow className={classes.priceInput}>
          <IonCol size={'4'} className={classes.min}>
            <IonInput
              type="number"
              placeholder="Min"
              onIonChange={(e) => {
                priceDispatch({
                  type: 'setPriceAbove',
                  payload: e.detail.value,
                });
              }}
              value={price.above}
            ></IonInput>
          </IonCol>
          <IonCol size={'4'} className={classes.max}>
            <IonInput
              type="number"
              placeholder="Max"
              onIonChange={(e) => {
                priceDispatch({
                  type: 'setPriceBelow',
                  payload: e.detail.value,
                });
              }}
              value={price.below}
            ></IonInput>
          </IonCol>
          <IonCol size={'4'} className={classes.btn}>
            <IonButton
              color="secondary"
              fill="solid"
              onClick={() => {
                setPriceRange({
                  max: price.below,
                  min: price.above,
                });
                updatePriceParam();
              }}
            >
              Update
            </IonButton>
          </IonCol>
        </IonRow>
      </div>
    </div>
  );
};

export default PriceRangeInput;
