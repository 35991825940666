import { IonCol,IonRow } from '@ionic/react';
import { useContext, useEffect, useState } from 'react';
import classes from './styles.module.scss';
import InputCard from '../../pages/auth/components/inputCard';
import Btn from '../Btn';
import { CONTACT_US, CONTACT_US_ERROR } from '../../context/actions/types';
import { GlobalContext } from '../../context/provider';
import { contactUs } from '../../context/actions/contact_us';

interface contactFormType {
  name: string;
  email: string;
  subject: string;
  body: string;
}

const ContactUsForm =()=>{
  const { contactUsState, contactUsDispatch } = useContext<any>(GlobalContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [confirmMessage, setConfirmMessage] = useState<String>();

  const [form, setForm] = useState<contactFormType>({
    email: '',
    name: '',
    body: '',
    subject: '',
  });
  const [error, setError] = useState<{
    state: boolean;
    message: string | null;
  }>({
    state: false,
    message: null
  });
  useEffect(() => {
    if (contactUsState?.contactMessageId) {
      contactUsDispatch({ type: CONTACT_US, payload: null });

      setForm({
        email: '',
        name: '',
        body: '',
        subject: '',
      });
    }
    if (contactUsState?.contactMessageIdError) {
      setError({
        state: true,
        message: contactUsState?.contactMessageIdError?.response?.data
      });
      console.log('eeeeeeeeeeee',contactUsState?.contactMessageIdError?.response)
      contactUsDispatch({ type: CONTACT_US_ERROR, payload: null });
    }
  }, [contactUsState?.contactMessageId, contactUsState?.contactMessageIdError, contactUsDispatch]);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setError({
      state: false,
      message: null
    });

    setLoading(true);
    const user = {
      email: form?.email,
      name: form?.name,
      subject: form?.subject,
      body: form?.body,
    };
    await contactUs(user)(contactUsDispatch);
    setLoading(false);
    setConfirmMessage('We have received your email, will get back to you shortly')
  };

   return(
    <form onSubmit={handleSubmit}>
    <IonRow>
      <IonCol>
        {confirmMessage && <p className={classes.message}>{confirmMessage}</p>}
        {error && <p className={classes.error}>{error.message}</p>}
        </IonCol>
    </IonRow>
    <IonRow>
      <IonCol>
        <InputCard
          title="Name"
          name="name"
          type="name"
          setForm={setForm}
          value={form.name}
          inputType="input"
        />
      </IonCol>
    </IonRow>
    <IonRow>
      <IonCol>
        <InputCard
          title="Email"
          name="email"
          type="email"
          setForm={setForm}
          value={form.email}
          inputType="input"
        />
      </IonCol>
    </IonRow>
    <IonRow>
      <IonCol>
        <InputCard
          title="Subject"
          name="subject"
          type="text"
          setForm={setForm}
          value={form.subject}
          inputType="input"
        />
      </IonCol>
    </IonRow>
    <IonRow>
      <IonCol>
        <InputCard
          title="Message"
          name="body"
          type="text"
          setForm={setForm}
          value={form.body}
          inputType="textarea"
        />
      </IonCol>
    </IonRow>
    <IonRow >
      <IonCol className={classes.button}>
        <Btn match={true} name="Submit" loading={loading} />
      </IonCol>
    </IonRow>
  </form>
   )
}
export default ContactUsForm;


