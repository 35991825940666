import React, { useEffect, useState } from 'react';
import classes from './styles.module.scss';
import { IonIcon } from '@ionic/react';
import { closeSharp } from 'ionicons/icons';

const Delete = (props: {
  index: number;
  action: React.Dispatch<any>;
  reset: React.Dispatch<any>;
}) => {
  const [index, setIndex] = useState<number>();
  useEffect(() => {
    setIndex(props.index);
  }, []);
  return (
    <div
      className={classes.remove}
      onClick={(e) => {
        e.stopPropagation();
        props.action((params: any) => {
          const images = params.filter((i: any, ind: number) => {
            return ind !== index;
          });

          return [...images];
        });
          props.reset(() => {
             return 0
          })
      }}
    >
      <IonIcon
        icon={closeSharp}
        aria-details="Delete"
        color={'primary'}
      ></IonIcon>
    </div>
  );
};

export default Delete;
