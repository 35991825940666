import { IonGrid, IonRow, IonCol } from '@ionic/react';
import React, { useContext, useEffect, useState } from 'react';
import StoreCard from '../../../components/cards/store';
import Filterbar from '../../../components/filterbar';
import Layout from '../../../components/Layout/user/Layout';
import { getStores } from '../../../context/actions/store';
import { DeviceContext } from '../../../deviceType';
import classes from './styles.module.scss';
import { GET_STORES_ERROR } from '../../../context/actions/types';
import { storeSchema } from '../../../types/store';
import Spinner from '../../../components/spinner';
import ErrorToast from '../../../components/error_toast';
import useGlobalContext from '../../../context/provider';

const Stores = () => {
  const size = useContext(DeviceContext);
  const [stores, setStores] = useState<storeSchema[]>([]);
  const [filters, setFilters] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const {storeState,storeDispatch} = useGlobalContext();
  const [isErrorOpen, setIsErrorOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  useEffect(() => {
    async function fetch() {
      if (storeState!.bulkStores) {
        setLoading(false);
        return setStores(storeState!.bulkStores as storeSchema[]);
      }
      await getStores()(storeDispatch!);
    }
    fetch();
  }, []);

  useEffect(() => {
    if (storeState!.bulkStores) {
      setStores(storeState!.bulkStores as storeSchema[]);
      setLoading(false);
    }
    if (storeState!.bulkStoresError) {
      setIsErrorOpen(true);
      setErrorMessage(
        storeState!.bulkStoresError!?.response!?.data!?.message
          ? storeState!.bulkStoresError!?.response!?.data!?.message
          : storeState!.bulkStoresError!?.message
      );

      storeDispatch!({ type: GET_STORES_ERROR, payload: null });
    }
  }, [storeState]);

  return (
    <Layout>
      <ErrorToast
        message={errorMessage}
        type={'error'}
        isOpen={isErrorOpen}
        onClose={() => {
          setIsErrorOpen(false);
        }}
      />
      <IonGrid className={classes.storesContainer}>
        <IonRow className={classes.allContent}>
          <IonCol>
            <div className={classes.head}>
              <div className={classes.title}>All Stores</div>
              <div
                className={classes.filterBar}
                style={{ width: size.width >= 568 ? '20%' : '60%' }}
              >
                <Filterbar action={setFilters} />
              </div>
            </div>
            <div
              className={classes.content}
              style={{ justifyContent: size.width < 568 ? 'center' : '' }}
            >
              {loading ? (
                <Spinner type="circles"></Spinner>
              ) : (
                stores
                  ?.filter((item) => {
                    return filters.length > 0
                      ? filters.includes(item.type)
                      : true;
                  })
                  .map((item, index) => {
                    return <StoreCard data={item} key={index} />;
                  })
              )}
            </div>
          </IonCol>
        </IonRow>
      </IonGrid>
    </Layout>
  );
};

export default Stores;
