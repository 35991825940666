import { useContext, useEffect, useRef, useState } from 'react';
import classes from './styles.module.scss';
import {
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonRippleEffect,
  IonRow,
} from '@ionic/react';
import InputCard from '../../../components/inputField';
import { Store } from '../../../types/store';
import SelectField from '../../../components/selectField';
import { GlobalContext } from '../../../context/provider';
import {
  CREATE_PRODUCT_ERROR,
  GET_PRODUCT_TYPES_ERROR,
} from '../../../context/actions/types';
import { addOutline, closeOutline, closeSharp } from 'ionicons/icons';
import { User } from '../../../types/user';
import { getStoreTypes } from '../../../context/actions/store';

export const Info = ({
  store,
  storeUpdate,
  authNext,
  loading,
}: {
  storeUpdate: (arg0: (form: any) => any) => any;
  authNext: (arg0: (form: boolean) => any) => any;
  store: Store;
  loading: (arg0: (form: boolean) => any) => any;
}): JSX.Element => {
  const { userState, productTypes, productTypesDispatch, storeTypesDispatch, storeTypes } =
    useContext<any>(GlobalContext);
  const [info, setInfo] = useState<Store>({} as Store);
  const [types, setTypes] = useState<string[]>([]);
  const [message, setMessage] = useState<string>('');
  const logoInput = useRef<any>(null);
  const bannerInput = useRef<any>(null);
  const [user, setUser] = useState<User>({} as User);

  useEffect(() => {
    fetchTypes();
    setInfo({ ...store });
  }, []);

  useEffect(() => {
    const _user = userState?.getUser!;
    if (_user?.id) setUser({ ..._user });
  }, [userState.getUser]);
  useEffect(() => {
    if (user?.id) {
      setInfo({ ...info, email: user.email, contacts: [user.phoneNumber] });
    }
  }, [user]);
  useEffect(() => {
    if (logoInput.current) {
      logoInput.current.addEventListener('drop', (e: DragEvent) =>
        handleDrop(e, 'logo')
      );
      logoInput.current.addEventListener('change', (e: Event) =>
        handleClick(e, 'logo')
      );
    }
    if (bannerInput.current) {
      bannerInput.current.addEventListener('drop', (e: DragEvent) =>
        handleDrop(e, 'banner')
      );
      bannerInput.current.addEventListener('change', (e: Event) =>
        handleClick(e, 'banner')
      );
    }
  }, [logoInput, bannerInput, info]);

  useEffect(() => {
    handleInput();
  }, [info]);

  useEffect(() => {
    if (storeTypes.storeTypes) {
      const _types = storeTypes.storeTypes;
      setTypes(_types);
      loading(() => false);
    }
    if (productTypes.productTypesError) {
      setMessage(
        productTypes.productTypesError.response?.data
          ? productTypes.productTypesError.response.data.message
          : productTypes.productTypesError.message
      );
      productTypesDispatch({ type: GET_PRODUCT_TYPES_ERROR, payload: null });
    }
  }, [storeTypes.storeTypes, loading]);

  const fetchTypes = async () => {
    if (storeTypes.storeTypes?.length) {
      loading!(() => false);
      return setTypes(storeTypes.storeTypes);
    }
    await getStoreTypes()(storeTypesDispatch);
  };

  const handleDrop = (e: DragEvent, item: string) => {
    e.stopPropagation();
    e.preventDefault();
    const file = e.dataTransfer?.files[0];
    if (file!.size > Math.floor(3 * 1048576)) {
      return setMessage('The File Limit is 3Mb!');
    }
    setInfo({ ...info, [item]: file });
  };

  const handleClick = (e: Event, item: string) => {
    e.stopPropagation();
    e.preventDefault();
    const file = (e.target! as HTMLInputElement)!?.files![0];
    if (file.size > Math.floor(3 * 1048576)) {
      return setMessage('The File Limit is 3Mb!');
    }
    setInfo({ ...info, [item]: file });
  };

  const handleInput = () => {
    const re =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (info.name && info.type && info.email && re.test(info.email)) {
      authNext(() => true);
    } else {
      authNext(() => false);
    }
    storeUpdate((params: any) => {
      return {
        ...params,
        ...info,
      };
    });
  };
  const handleEnter = (e: any) => {
    if (e.target.name === 'name') {
    }
    if (e.target.name === 'email') {
    }
  };
  return (
    <div className={classes.info}>
      <div className={classes.images}>
        <div className={classes.cont}>
          <div className={classes.profile}>
            {!info.logo && (
              <div
                className={
                  classes.input + ' ion-activatable ripple-parent circle'
                }
                title="Click or Drag and Drop to Add Logo"
                onClick={() => {
                  logoInput?.current.click();
                }}
              >
                <IonRippleEffect></IonRippleEffect>
                <IonIcon icon={addOutline} size="large"></IonIcon>
                <input ref={logoInput} type={'file'} accept={'image/*'} />
              </div>
            )}
            {info.logo && (
              <div className={classes.display}>
                <div className={classes.discard}>
                  <IonButton
                    color={'secondary'}
                    fill={'solid'}
                    onClick={() => {
                      setInfo({ ...info, logo: undefined });
                    }}
                  >
                    <IonIcon
                      icon={closeOutline}
                      slot={'icon-only'}
                      color={'light'}
                      size={'large'}
                    />
                  </IonButton>
                </div>
                <img
                  src={URL.createObjectURL(info.logo! as File)}
                  alt={'Store Logo'}
                />
              </div>
            )}
          </div>
          <div className={classes.overlay}>
            {!info.banner && (
              <div
                className={
                  classes.input + ' ion-activatable ripple-parent circle'
                }
                title={'Click or Drag and Drop to Add Banner'}
                onClick={() => {
                  bannerInput?.current.click();
                }}
              >
                <IonRippleEffect></IonRippleEffect>
                <input ref={bannerInput} type={'file'} accept={'image/*'} />
              </div>
            )}
          </div>
          <div className={classes.banner}>
            {info.banner && (
              <div className={classes.display}>
                <div className={classes.discard}>
                  <IonButton
                    color={'secondary'}
                    fill={'solid'}
                    onClick={() => {
                      setInfo({ ...info, banner: undefined });
                    }}
                  >
                    <IonIcon
                      icon={closeOutline}
                      slot={'icon-only'}
                      color={'light'}
                      size="large"
                    />
                  </IonButton>
                </div>
                <img
                  src={URL.createObjectURL(info.banner! as File)}
                  alt={'Store Banner'}
                />
              </div>
            )}
          </div>
          {message && (
            <div className={classes.message}>
              <p>{message}</p>
              <IonButton
                color="secondary"
                size="small"
                fill="clear"
                onClick={() => {
                  setMessage('');
                }}
              >
                <IonIcon slot="icon-only" icon={closeOutline}></IonIcon>
              </IonButton>
            </div>
          )}
        </div>
      </div>
      <div className={classes.typography}>
        <IonGrid>
          <IonRow className={classes.head}>
            <IonCol size="6" className={classes.name}>
              <div className={classes.label}>Store Name</div>
              <div className={classes.input}>
                <InputCard
                  type="text"
                  placeholder="Store Name"
                  name="name"
                  inputType="text-field"
                  required
                  value={setInfo}
                  formValues={info}
                  enterEvent={handleEnter}
                />
              </div>
            </IonCol>
            <IonCol size="6" className={classes.type}>
              <div className={classes.label}>Store Type</div>
              <div className={classes.input}>
                <SelectField
                  value={setInfo}
                  name={'type'}
                  options={types}
                  formValues={info}
                  placeholder="Store Types"
                  mode="md"
                />
              </div>
            </IonCol>
          </IonRow>
          <IonRow className={classes.email}>
            <IonCol size="12">
              <div className={classes.label}>Store Email</div>
              <div className={classes.input}>
                <InputCard
                  type="text"
                  placeholder="Email Name"
                  inputType="text-field"
                  name="email"
                  required
                  value={setInfo}
                  formValues={info}
                  enterEvent={handleEnter}
                />
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
    </div>
  );
};

export const Contacts = ({
  store,
  storeUpdate,
  authNext,
  loading,
}: {
  storeUpdate: (arg0: (form: any) => any) => any;
  authNext: (arg0: (form: boolean) => any) => any;
  store: Store;
  loading?: (arg0: (form: boolean) => any) => any;
}): JSX.Element => {
  const [info, setInfo] = useState<Store>({} as Store);
  const [currentContact, setCurrentContact] = useState<{ current: string }>(
    {} as any
  );
  const addref = useRef<any>();
  const helperText = {
    description:
      'A brief description about the store and the products offered. It must not be more than 256 characters long',
    contact:
      'Add a phone number by entering the number into the field below and clicking add. You can add a maximum of 5 phone numbers ',
  };

  useEffect(() => {
    setInfo({ ...store });
  }, []);
  useEffect(() => {
    handleInput();
  }, [info]);
  const handleInput = () => {
    if (
      info.description &&
      info.contacts &&
      info.description!.length &&
      info.contacts!.length
    ) {
      authNext(() => true);
    } else {
      authNext(() => false);
    }
    const { contacts, ...rest } = info;
    let conts: string[];
    if (contacts && contacts.length !== 5 && currentContact.current) {
      conts = [...contacts, currentContact.current];
      return storeUpdate((params: any) => {
        return {
          ...params,
          ...rest,
          contacts: conts,
        };
      });
    }
    storeUpdate((params: any) => {
      return {
        ...params,
        ...info,
      };
    });
  };
  const handlecontacts = () => {
    if (currentContact!.current) {
      if (info.contacts && info.contacts.length) {
        setInfo({
          ...info,
          contacts: [...info.contacts, currentContact!.current],
        });
      } else {
        setInfo({
          ...info,
          contacts: [currentContact!.current],
        });
      }
    }

    setCurrentContact({ current: '' });
  };
  const handleEnter = (e: any) => {
    if (e.target.name === 'current') {
      addref.current.click();
    }
    if (e.target.name === 'description') {
    }
  };
  return (
    <div className={classes.contacts}>
      <div className={classes.description}>
        <div className={classes.label}>Store Description</div>
        <div className={classes.helperText}>{helperText.description}</div>
        <div className={classes.container}>
          <InputCard
            type="text"
            placeholder="Store Description"
            name="description"
            inputType={'text-box'}
            required
            maxLength={256}
            value={setInfo}
            formValues={info}
            enterEvent={handleEnter}
          />
        </div>
      </div>
      <div className={classes.contacts}>
        <div className={classes.label}>Contact Details </div>
        <div className={classes.helperText}>{helperText.contact}</div>
        <div className={classes.container}>
          <div className={classes.input}>
            <div className={classes.field}>
              <InputCard
                type="tel"
                placeholder="Store PhoneNumber"
                name="current"
                inputType={'text-field'}
                required
                maxLength={10}
                value={setCurrentContact}
                formValues={currentContact}
                enterEvent={handleEnter}
              />
            </div>

            <IonButton
              onClick={handlecontacts}
              color="secondary"
              ref={addref}
              disabled={info.contacts?.length !== 5 ? false : true}
            >
              Add
            </IonButton>
          </div>
          <div className={classes.list}>
            {info.contacts?.map((item, index) => {
              return (
                <div key={index} className={classes.item}>
                  {item}
                  <IonButton
                    onClick={() => {
                      const list = info.contacts;
                      const filtered = list?.filter((i, n) => {
                        return n !== index;
                      });

                      setInfo({ ...info, contacts: [...filtered!] });
                    }}
                    fill={'clear'}
                    className={classes.btn}
                  >
                    <IonIcon
                      icon={closeSharp}
                      color={'secondary'}
                      size="default"
                    />
                  </IonButton>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export const Location = ({
  store,
  storeUpdate,
  loading,
}: {
  storeUpdate: (arg0: (form: any) => any) => any;
  store: Store;
  loading?: (arg0: (form: boolean) => any) => any;
}): JSX.Element => {
  const [info, setInfo] = useState<Store>({} as Store);
  const { storeState, storeDispatch } = useContext<any>(GlobalContext);
  const [currentLocation, setCurrentLocation] = useState<{ current: string }>(
    {} as any
  );
  const addref = useRef<any>();
  const [_error, setError] = useState<{
    state: boolean;
    msg: string;
  }>({
    state: false,
    msg: '',
  });
  const helperText = {
    locations:
      'Add a location by entering the location into the field below and clicking add. You can add a maximum of 8 locations',
  };

  useEffect(() => {
    setInfo({ ...store });
  }, []);

  useEffect(() => {
    if (storeState.storeError) {
      setError({
        state: true,
        msg: storeState.storeError.response?.data
          ? storeState.storeError.response.data.message
          : storeState.storeError.message,
      });
      storeDispatch({ type: CREATE_PRODUCT_ERROR, payload: null });
      // update loading state
      loading!((e: boolean) => {
        return !e;
      });
    }
  }, [storeState]);

  useEffect(() => {
    handleInput();
  }, [info]);

  const handleInput = () => {
    const { location, ...rest } = info;
    let locs: string[];
    if (location && location.length !== 5 && currentLocation.current) {
      locs = [...location, currentLocation.current];
      return storeUpdate((params: any) => {
        return {
          ...params,
          ...rest,
          contacts: locs,
        };
      });
    }
    storeUpdate((params: any) => {
      return {
        ...params,
        ...info,
      };
    });
  };
  const handleLocations = () => {
    if (currentLocation!.current) {
      if (info.location && info.location.length) {
        setInfo({
          ...info,
          location: [...info.location!, currentLocation!.current],
        });
      } else {
        setInfo({
          ...info,
          location: [currentLocation!.current],
        });
      }
    }

    setCurrentLocation({ current: '' });
  };
  const handleEnter = (e: any) => {
    addref.current.click();
  };
  return (
    <div className={classes.location}>
      <div className={classes.holder}>
        <div className={classes.label}>Location Details</div>
        <div className={classes.helperText}>{helperText.locations}</div>
        <div className={classes.container}>
          <div className={classes.input}>
            <div className={classes.field}>
              <InputCard
                type="text"
                placeholder="Store Locations"
                name="current"
                inputType={'text-field'}
                required
                maxLength={32}
                value={setCurrentLocation}
                formValues={currentLocation}
                enterEvent={handleEnter}
              />
            </div>

            <IonButton
              onClick={handleLocations}
              ref={addref}
              color="secondary"
              disabled={info.location?.length !== 8 ? false : true}
            >
              Add
            </IonButton>
          </div>
          <div className={classes.list}>
            {(info.location! as string[])?.map((item, index) => {
              return (
                <div key={index} className={classes.item}>
                  {item}
                  <IonButton
                    onClick={() => {
                      const list = info.location;
                      const filtered = (list as string[])?.filter((i, n) => {
                        return n !== index;
                      });

                      setInfo({ ...info, location: [...filtered!] });
                    }}
                    fill={'clear'}
                    className={classes.btn}
                  >
                    <IonIcon
                      icon={closeSharp}
                      color={'secondary'}
                      size="default"
                    />
                  </IonButton>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {_error.state && (
        <div className={classes.error}>
          <div className={classes.label}>Error!</div>
          <span className={classes.cont}>
            <div className={classes.msg}> {_error.msg}</div>
            <div className={classes.btn}>
              <IonButton
                fill={'clear'}
                onClick={() => {
                  setError(() => ({ state: false, msg: '' }));
                }}
                color="secondary"
              >
                <IonIcon slot="icon-only" icon={closeSharp}></IonIcon>
              </IonButton>
            </div>
          </span>
        </div>
      )}
    </div>
  );
};
