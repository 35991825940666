import React, { useEffect, useState } from 'react';
import classes from './styles.module.scss';
import { IonRippleEffect } from '@ionic/react';

const Option = ({
  handleChange,
  item,
  status,

}: {
  handleChange:(e:number)=>void;
    item: { name: string; key: number };
    status: boolean,
}) => {
  const [_item, setItem] = useState<{ name: string; key: number }>({
    name: '',
    key: 0,
  });
  useEffect(() => {
    setItem(item);
  }, [item]);
  return (
    <div
      className={classes.option + ' ion-activatable ripple-parent circle'}
      onClick={() => {
        handleChange(_item.key);
      }}
      style={{ color: status ? '#A94064' : '#0f324d' }}
    >
      <IonRippleEffect></IonRippleEffect>
      {_item!.name}
    </div>
  );
};

export default Option;
