import { addToLocalStorage } from '.';

export const getCurrentStore = () => {
  const storeid = localStorage.getItem('currentStore');
  return storeid;
};

export const setCurrentStore = (store: {
  id: number;
  name: string;
  default?: boolean;
}) => {
  if (store) {
    addToLocalStorage({key:'currentStore', value:store.id.toString()});
    return true;
  } else return false;
};
