import { useContext, useEffect, useRef, useState } from 'react';
import classes from './styles.module.scss';
import {
  IonButton,
  IonIcon,
  IonInput,
  IonSelect,
  IonSelectOption,
} from '@ionic/react';
import { DeviceContext } from '../../../../deviceType';
import { searchSharp } from 'ionicons/icons';
import { GlobalContext } from '../../../../context/provider';
import { getProductTypes } from '../../../../context/actions/products';
import { useHistory, useLocation } from 'react-router';
import ErrorToast from '../../../error_toast';

const SearchBar = () => {
  const device = useContext(DeviceContext);
  const { productTypes, productTypesDispatch } = useContext<any>(GlobalContext);
  const [text, setText] = useState<string>();
  const [category, setCat] = useState<string>();
  const [categories, setCategories] = useState<string[]>([]);
  const history = useHistory();
  const location = useLocation();
  const inputRef = useRef<any>(null);
  const [error, setError] = useState<boolean>(true);
  const [isErrorOpen, setIsErrorOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  useEffect(() => {
    async function fetchCategories() {
      if (productTypes.productTypes) {
        return setCategories(productTypes.productTypes.data);
      }
      await getProductTypes()(productTypesDispatch);
    }
    fetchCategories();
  }, []);
  useEffect(() => {
    if (productTypes.productTypes) {
      setCategories(productTypes.productTypes.data);
    }
    if (productTypes?.productTypesError != null) {
      setIsErrorOpen(true);
      setErrorMessage(
        productTypes?.productTypesError!?.response!?.data!?.message
          ? productTypes?.productTypesError!?.response!?.data!
              ?.message
          : productTypes?.productTypesError!?.message
      );
   }
  }, [productTypes.length]);

  const searchQuery = {
    categories: category === undefined ? [] : [category],
    text,
  };
  const search = async () => {
    history.push({
      pathname: '/search',
      state: searchQuery,
    });
  };
  return (
    <div
      className={classes.searchbar}
      style={
        device.width > 769
          ? { padding: location.pathname !== '/' ? '1%' : '0.5%' }
          : { padding: '1.2%' }
      }
    >
      <ErrorToast
        message={errorMessage}
        type={'error'}
        isOpen={isErrorOpen}
        onClose={() => {
          setIsErrorOpen(false);
        }}
      />
      <IonSelect
        value={category}
        className={classes.cat}
        placeholder="Select Category"
        onIonChange={(e) => setCat(e.detail.value)}
      >
        {categories.map((item: any, index: any) => {
          return (
            <IonSelectOption value={item} key={index}>
              {item}
            </IonSelectOption>
          );
        })}
      </IonSelect>
      <IonInput
        placeholder="Search"
        className={classes.input}
        value={text}
        clearInput
        required
        onIonFocus={(e) => {
          e.target?.addEventListener('keypress', (param: any) => {
            if (param.code === 'Enter') {
              return inputRef.current.click();
            }
          });
        }}
        type="search"
        mode="ios"
        onIonChange={(e) => {
          setText(e.detail.value!);
          if (e.detail.value! === undefined || e.detail.value!.length <= 0) {
            setError(true);
          } else {
            setError(false);
          }
        }}
      ></IonInput>
      <IonButton
        color="secondary"
        fill="solid"
        className={classes.btn}
        onClick={search}
        ref={inputRef}
        mode="ios"
        disabled={error}
      >
        {device.width > 769 ? (
          'Search'
        ) : (
          <IonIcon slot="icon-only" color="light" icon={searchSharp}></IonIcon>
        )}
      </IonButton>
    </div>
  );
};

export default SearchBar;
