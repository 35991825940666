import React, { useContext, useEffect, useState } from 'react';
import classes from './styles.module.scss';
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
} from '@ionic/react';
import { DeviceContext } from '../../../deviceType';
import WishlistIcon from '../../../pages/user/wish-list/wish-list-icon';
import { getCurrentUser } from '../../../utils/getClientInfo';
import { useHistory } from 'react-router';

const WishListCard: React.FC<{ wishlistItems: any }> = (props) => {
  const size = useContext(DeviceContext);
  const client = getCurrentUser();
  const [path, setPath] = useState<string>('');
  const history = useHistory();

  function dimensions() {
    if (size.width > 1050) {
      return { width: '20vw', height: '25vw', margin: '0.5em' };
    } else if (size.width > 769) {
      return { width: '33vw', height: '35vw', margin: '0.5em' };
    } else if (size.width > 470) {
      return { width: '50vw', height: '55vw', margin: '0.5em' };
    } else {
      return { width: '80vw', height: '85vw', margin: '0.5em' };
    }
  }

  useEffect(() => {
    setPath(
      `/items/detail?q=${
        props.wishlistItems.product.id
      }&i=${props.wishlistItems.product.name.slice(0, 30)}`
    );
  }, []);

  return (
    <IonCard
      className={classes.wishlistCard}
      style={{
        width: dimensions().width,
        height: dimensions().height,
        margin: dimensions().margin,
      }}
      onClick={() => {
        history.push({
          pathname: path,
          state: { id: props.wishlistItems.product.id },
        });
      }}
    >
      <div className={classes.holder}>
        <div className={classes.img}>
          <img alt="Silhouette of mountains" src={props.wishlistItems.image} />
        </div>
        <div className={classes.highline}></div>
        <div>
          <IonCardHeader className={classes.title}>
            <IonCardTitle>{props.wishlistItems.product.name}</IonCardTitle>
            <div>
              <WishlistIcon productId={props.wishlistItems.product.id} />
            </div>
          </IonCardHeader>

          <IonCardContent className={classes.content}>
            <div className={classes.price}>
              UGX {props.wishlistItems.product.price}
            </div>
            <div className={classes.seller}>
              {props.wishlistItems.product.store.name}
            </div>
            <div className={classes.address}>
              {props.wishlistItems.product.store.location}
            </div>
          </IonCardContent>
        </div>
      </div>
    </IonCard>
  );
};

export default WishListCard;
function productDispatch(value: { type: string; payload: any }): void {
  throw new Error('Function not implemented.');
}
