import { IonToast } from '@ionic/react';
import { useState, useEffect } from 'react';
import styles from './styles.module.scss';
import { closeSharp, informationCircle } from 'ionicons/icons';

interface ToastProps {
  message: string;
  type: 'error' | 'warning';
  duration?: number;
  isOpen: boolean;
  onClose: () => void;
}

const ErrorToast = ({
  message,
  type,
  duration,
  isOpen,
  onClose,
}: ToastProps) => {
  const [cssClass, setCssClass] = useState('');
  const [head, setHead] = useState<string>('');

  useEffect(() => {
    if (type === 'error') {
      setCssClass(styles.errorToast);
    } else if (type === 'warning') {
      setCssClass(styles.warningToast);
    }
  }, [type]);

  return (
    <IonToast
      header={`${type.toLocaleUpperCase()}!! ${head}.`}
      isOpen={isOpen}
      onDidDismiss={onClose}
      message={message}
      icon={informationCircle}
      color={type === 'error' ? 'danger' : 'warning'}
      cssClass={styles.error}
      buttons={[
        {
          role: 'cancel',
          icon: closeSharp,
          handler: () => {
            console.log('Dismiss clicked');
          },
        },
      ]}
    />
  );
};

export default ErrorToast;
