import { IonSkeletonText } from '@ionic/react';
import React, { useContext } from 'react';
import { DeviceContext } from '../../../deviceType';
import classes from './style.module.scss';

const CardSkeleton = (props: { type: 'flex' | 'grid' }) => {
  const size = useContext(DeviceContext);
  function dimension() {
    if (size.width >= 769) {
      return {
        flex_width: '30vw',
        flex_height: '10vw',
        grid_width: '8vw',
        grid_height: '10vw',
      };
    } else {
      if (size.width >= 460) {
        return {
          flex_width: '30vw',
          flex_height: '10vw',
          grid_width: '8vw',
          grid_height: '10vw',
        };
      } else {
        return {
          flex_width: '90vw',
          flex_height: '50vw',
          grid_width: '40vw',
          grid_height: '60vw',
        };
      }
    }
  }
  return (
    <div
      className={props.type === 'flex' ? classes.flex : classes.grid}
      style={{
        width:
          props.type === 'flex'
            ? dimension()?.flex_width
            : dimension()?.grid_width,
        height:  props.type === 'flex'
            ? dimension()?.flex_height
            : dimension()?.grid_height,
      }}
    >
      <div className={classes.img}>
        <IonSkeletonText
          animated={true}
          style={{ width: '100%', height: '100%' }}
        ></IonSkeletonText>
      </div>
      <div className={classes.text}>
        <IonSkeletonText
          animated={true}
          style={{ width: '70%' }}
        ></IonSkeletonText>
        <IonSkeletonText
          animated={true}
          style={{ width: '100%' }}
        ></IonSkeletonText>
        <IonSkeletonText
          animated={true}
          style={{ width: '50%' }}
        ></IonSkeletonText>
        <IonSkeletonText
          animated={true}
          style={{ width: '60%' }}
        ></IonSkeletonText>
      </div>
    </div>
  );
};

export default CardSkeleton;
