import { IonCol, IonGrid, IonRow } from '@ionic/react';
import { useContext} from 'react';
import Layout from '../../../components/Layout/user/Layout';
import classes from './styles.module.scss';
import { DeviceContext } from '../../../deviceType';
import ContactUsForm from '../../../components/contactusForm/contact';

const AboutUs = () => {

  const size = useContext(DeviceContext);
  const _mobile = {
    height: (96 * 9.18) / (16 / 10) / (size.height * (1 / 100)),
    width: (96 * 6.53) / (16 / 10) / (size.width * (1 / 100)),
  };

  return (
    <Layout>
      <section className={classes.Desktop}>
        <IonGrid className={classes.grid} fixed={true}>
          <IonRow>
            <IonCol size="12" size-md="6">
              <h1>Bazaar</h1>
              <h3 className={classes.cheap}>Cheap</h3>
            </IonCol>
            <IonCol
              className={classes.image}
              size="12"
              size-md="4"
              style={{
                width: `${_mobile.width}vw`,
                height: `${_mobile.height}vh`,
              }}
            ></IonCol>
          </IonRow>
        </IonGrid>
        <IonRow>
          <IonCol className={classes.about}>
            <p>
              Bazaar Cheap is an online window shopping platform that connects
              buyers and sellers across Uganda. Our platform is designed to
              provide a seamless shopping experience for customers while
              allowing sellers to reach a wider audience.
            </p>
            <p>
              We understand that shopping can be time-consuming and frustrating,
              especially when you have to visit multiple stores to find what you
              need. With Bazaar Cheap, you can browse products from various
              sellers in one place, saving you time and effort.
            </p>
          </IonCol>
        </IonRow>
        <IonGrid className={classes.grid} fixed={true}>
          <IonRow>
            <IonCol size="12" size-md="6">
              <div className={classes.divflex}>
                <div className={classes.search}></div>
                <h6>Search Products</h6>
              </div>
              <p className={classes.proparagraph}>
                Our platform allows you to search for products based on
                category, price range, and location. You can also view seller
                profiles to learn more about their products and services. We
                strive to make your shopping experience as convenient and
                enjoyable as possible.
              </p>
            </IonCol>
            <IonCol size="12" size-md="6">
              <div className={classes.divflex}>
                <div className={classes.search}></div>
                <h6>Seller Dashboard</h6>
              </div>
              <p className={classes.proparagraph}>
                Our platform allows you to search for products based on
                category, price range, and location. You can also view seller
                profiles to learn more about their products and services. We
                strive to make your shopping experience as convenient and
                enjoyable as possible.
              </p>
            </IonCol>
            <IonCol size="12" size-md="6">
              <div className={classes.divflex}>
                <div className={classes.search}></div>
                <h6>Whislist </h6>
              </div>
              <p className={classes.proparagraph}>
                Our platform empowers you to curate and organize your own
                personalized collection of items that catch your eye. Simply
                click the whishlist icon and add it to your collection for
                future reference.
              </p>
            </IonCol>
          </IonRow>
        </IonGrid>
        <IonGrid className={classes.grid} fixed={true}>
          <IonRow>
            <IonCol>
              <h6>Support</h6>
              <p className={classes.paragraph}>
                At Bazaar Cheap, we value transparency, trust, and customer
                satisfaction. We work hard to ensure that our platform is safe
                and secure for all users. We also provide support to help users
                resolve any issues that may arise.
              </p>
            </IonCol>
          </IonRow>
        </IonGrid>
        <IonGrid className={classes.grid} fixed={true}>
          <div className={classes.formdiv}>
          <div className={classes.form}>
          <h5>Contact Us</h5>
            <ContactUsForm/>
            </div>
          </div>
        </IonGrid>
      </section>
    </Layout>
  );
};

export default AboutUs;
