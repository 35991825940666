import { GET_STORE_TYPES, STORE_TYPES_ERROR } from '../actions/types';

export interface storeTypesState {
  [name: string]: {
    [name: string]: any | undefined;
  } | null;
}
export const storeTypeInitialState: storeTypesState = {
  storeTypes: null,
  storeTypesError: null,
};
const storeTypesReducer = (
  state: storeTypesState,
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case GET_STORE_TYPES:
      return { ...state, storeTypesError: null, storeTypes: action.payload };
    case STORE_TYPES_ERROR:
      return { ...state, storeTypesError: action.payload, storeTypes: null };
    default:
      return state;
  }
};

export default storeTypesReducer;
