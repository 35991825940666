import { IonSpinner } from '@ionic/react';
import React from 'react';
import styles from './styles.module.scss';

const Spinner = ({type='dots'}:{type?:'dots' | 'bubbles' | 'circles' | 'circular' | 'crescent' | 'lines' | 'lines-small' | 'lines-sharp' | 'lines-sharp-small'}) => {
  return (
    <div className={styles.wrapper}>
      {/* https://ionicframework.com/docs/api/spinner */}
      <IonSpinner className={styles.spinner} name={type}></IonSpinner>
    </div>
  );
};

export default Spinner;