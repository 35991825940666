import {
  logoFacebook,
  logoInstagram,
  logoTwitter,
  logoTiktok,
  homeSharp,
  listSharp,
  helpCircleSharp,
} from 'ionicons/icons';

import {
  storefrontSharp,
  pricetagsSharp,
  cartSharp,
  informationCircleSharp,
} from 'ionicons/icons';
import {
  facebookLink,
  instagramLink,
  tiktokLink,
  twitterLink,
} from './SocialsLinks';

// export const userRoutes = [
//   { path: '/home', component: Home },
//   { path: '/stores', component: Error },
// ];

export const Routes = [
  { name: 'Categories', route: '/categories', disabled: true },
  // { name: 'Offers', route: '/#' },
  { name: 'Shops', route: '/stores', disabled: false },
  { name: 'About Us', route: '/aboutus' },
  { name: 'Help', route: '/help' },
  { name: 'Favourites', isloggedIn: true, route: '/wishlist' },
];

export const Socials = [
  { route: facebookLink, icon: logoFacebook },
  { route: instagramLink, icon: logoInstagram },
  { route: tiktokLink, icon: logoTiktok },
  // { route: '#', icon: logoLinkedin },
  { route: twitterLink, icon: logoTwitter },
  // { route: '#', icon: logoYoutube },
];

export const AboutRoutes = [
  { name: 'About Us', route: '#' },
  // { name: 'Privacy & Cookies', route: '#' },
  { name: 'Terms Of Use', route: '#' },
  { name: 'Help', route: '/help' },
  // { name: 'Contact Us', route: '#' },
  // { name: 'Customer Service', route: '#' },
];

export const JoinRoutes = [
  { name: 'Sell On Bazaar Cheap', route: '#' },
  { name: 'Careers', route: '#' },
  { name: 'Become An Agent', route: '#' },
  { name: 'Become A Logistics Partner', route: '#' },
  { name: 'For Enterprises', route: '#' },
];

// Mobile Menu Routes

const category = [
  { name: 'Top Categories', route: 'top' },
  { name: 'Kitchen', route: 'kitchen' },
  { name: 'Furniture & Decore', route: 'furniture' },
  { name: 'Video & Television', route: 'Video' },
];
const offer = [
  { name: 'Popular Offers', route: 'popular' },
  { name: 'Limited Offers', route: 'limited' },
  { name: 'Video & Television', route: 'video' },
  { name: 'Supermarket', route: 'sup' },
];

export const otherRoutes = [
  { name: 'Home', route: '/', icon: homeSharp },
  {
    name: 'Favourites',
    route: '/wishlist',
    icon: listSharp,
    isloggedIn: true,
  },
  {
    name: 'Categories',
    route: 'categories',
    icon: cartSharp,
    disabled: true,
    children: [
      ...category.map((item) => {
        return { name: item.name, route: `categories/#${item.route}` };
      }),
    ]!,
  },
  {
    name: 'Offers',
    route: 'offers',
    icon: pricetagsSharp,
    disabled: true,
    children: [
      ...offer.map((item) => {
        return { name: item.name, route: `offers/#${item.route}` };
      }),
    ],
  },

  {
    name: 'Shops',
    route: '/stores',
    icon: storefrontSharp,
  },
  {
    name: 'Help',
    route: '/help',
    icon: helpCircleSharp,
  },
  {
    name: 'About Bazaar Cheap',
    route: '/aboutus',
    icon: informationCircleSharp,
  },
];
