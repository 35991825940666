import React, { useContext, useEffect, useRef, useState } from 'react';
import classes from './styles.module.scss';
import { DeviceContext } from '../../../deviceType';
import {
  IonGrid,
  IonRow,
  IonCol,
  IonRippleEffect,
  IonIcon,
  IonButton,
} from '@ionic/react';
import { addOutline, addSharp, closeSharp, pulseOutline } from 'ionicons/icons';
import { closeSync } from 'fs';
import SmoothLineChart from '../../../components/LineChart';

const ProductMetrics = ({data}:{data:any[]}) => {
  const size = useContext(DeviceContext);
  const [productTagContext, setProductTagContext] = useState<boolean>(false);
  const [suggestedTags, setSuggestedTags] = useState<string[]>([]);
  const [productTag, setProductTag] = useState<string>('');
  const productTagInputRef = useRef<any>();
  const [tags, setTags] = useState<string[]>([]);
  const [engagements, setEngagements] = useState<any[]>([]);
  const [impressions, setImpressions] = useState<any[]>([]);

  useEffect(() => {
    // Using Mock Data Source
    const col_data = data
      .filter((item: { date: any; number: number }) => {
        const current = parseInt((new Date().getTime() / 1000).toFixed(0));
        const monthAgo = current - 3678400;
        return item.date / 1000 >= monthAgo;
      })
      .map((item) => {
        const date = new Date(Number(item.date));
        const options: any = {
          day: 'numeric',
          weekday: 'short',
          month: 'short',
        };
        return {
          y: item.number,
          x: date.toLocaleString('en-US', options),
        };
      });

    setEngagements(col_data);
    setImpressions(col_data);
  }, []);

  function dimensions() {
    if (size.width <= 768) {
      return { height: '100vh' };
    } else {
      return { height: '85vh' };
    }
  }
  return (
    <div style={{ height: dimensions().height }} className={classes.metrics}>
      <IonGrid className={classes.grid}>
        <IonRow className={classes.label}>
          <IonCol size="12">
            <div>Product Metrics</div>
          </IonCol>
        </IonRow>
        <IonRow className={classes.impressions}>
          <IonCol size="12">
            <div
              className={
                classes.heading + ' ion-activatable ripple-parent circle'
              }
            >
              <IonRippleEffect color={'secondary'}></IonRippleEffect>
              <div className={classes.name}>Impressions</div>
              <div className={classes.observations}>{200}</div>
            </div>
            <div className={classes.chart}>
              <SmoothLineChart
                x={'Date'}
                y={'Impressions'}
                data={impressions}
                showKey={false}
                smooth={false}
              />
            </div>
          </IonCol>
        </IonRow>
        <IonRow className={classes.engagements}>
          <IonCol size="12">
            <div
              className={
                classes.heading + ' ion-activatable ripple-parent circle'
              }
            >
              <IonRippleEffect color={'secondary'}></IonRippleEffect>
              <div className={classes.name}>Engagements</div>
              <div className={classes.observations}>{200}</div>
            </div>
            <div className={classes.chart}>
              <SmoothLineChart
                x={'Date'}
                y={'Engagements'}
                data={engagements}
                showKey={false}
                smooth={false}
              />
            </div>
          </IonCol>
        </IonRow>
        <IonRow className={classes.productTags}>
          <IonCol size="12">
            <div className={classes.heading}>
              <div className={classes.name}>Product Tags</div>
              <div
                className={
                  classes.action + ' ion-activatable ripple-parent circle'
                }
                onClick={() => {
                  setProductTagContext(true);
                }}
                style={{ display: tags.length >= 8 ? 'none' : 'block' }}
              >
                <IonRippleEffect></IonRippleEffect>
                <IonIcon icon={addSharp} color="primary" />
              </div>
            </div>
            <div className={classes.tags}>
              {tags.length > 0 && (
                <ul className={classes.list}>
                  {tags.slice(0, 8).map((item, index) => {
                    return (
                      <div className={classes.item} key={index}>
                        {item}
                      </div>
                    );
                  })}
                </ul>
              )}
              {tags.length === 0 && (
                <div
                  className={classes.add}
                  onClick={() => {
                    setProductTagContext(true);
                  }}
                  title="Product Tags are keywords which allow your Item be displayed in relevant results for similar products"
                >
                  <IonIcon icon={addOutline} color="primary" />
                  Add Product Tags
                </div>
              )}
            </div>
          </IonCol>
        </IonRow>
      </IonGrid>
      {/* Add product Tag Card */}
      <div
        className={classes.backdrop + ' ion-activatable ripple-parent circle'}
        onClick={() => {
          setProductTagContext(false);
        }}
        style={{ display: productTagContext ? 'block' : 'none' }}
      >
        <IonRippleEffect></IonRippleEffect>
        <div
          className={classes.addTag}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className={classes.close}>
            <div
              className={classes.btn + ' ion-activatable ripple-parent circle'}
              onClick={() => {
                setProductTag('');
                setProductTagContext(false);
              }}
            >
              <IonRippleEffect></IonRippleEffect>
              <IonIcon icon={closeSharp} color="primary" />
            </div>
          </div>
          <div className={classes.input}>
            <input
              className={classes.field}
              type="text"
              maxLength={24}
              placeholder={'Enter Tag'}
              ref={productTagInputRef}
              value={productTag}
              onChange={(e) => {
                setProductTag(e.target.value);
              }}
            />
            <div
              className={classes.suggestions}
              style={{ display: suggestedTags.length === 0 ? 'none' : 'flex' }}
            >
              {suggestedTags.map((item, index) => {
                return (
                  <div
                    className={
                      classes.item + ' ion-activatable ripple-parent circle'
                    }
                    key={index}
                    onClick={() => setProductTag(item)}
                  >
                    <IonRippleEffect></IonRippleEffect>
                    {item}
                  </div>
                );
              })}
            </div>
          </div>
          <div className={classes.actions}>
            <IonButton
              className={classes.save}
              fill="solid"
              color="secondary"
              expand="block"
              onClick={() => {}}
            >
              ADD
            </IonButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductMetrics;
