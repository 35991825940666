import { IonCard } from '@ionic/react';
import React, { useContext, useEffect, useState } from 'react';
import classes from './styles.module.scss';
import { format } from 'date-fns';
import { DeviceContext } from '../../../deviceType';
import { useHistory } from 'react-router';
import { getCurrentUser } from '../../../utils/getClientInfo';
import { ProductType } from '../../../types/product';

const DashProductCard = ({ data }: { data: ProductType }) => {
  const size = useContext<any>(DeviceContext);
  const history = useHistory();
  const client = getCurrentUser();
  const [path, setPath] = useState<string>('');
  const [_data, setData] = useState<ProductType>({createdAt:new Date().toISOString()} as ProductType);

  useEffect(() => {
    setData(data);

  }, [data]);
  useEffect(() => {
    if (client.role === 'seller') {
      setPath(
        `/dashboard/items/detail?q=${_data.id}&i=${_data?.name?.slice(0, 30)}`
      );
    } else {
      setPath(`/items/detail?q=${_data.id}&i=${_data?.name?.slice(0, 30)}`);
    }
  }, [_data]);
  const pushroute = () => {
    history.push({
      pathname: path,
      state: { id: _data.id },
    });
  };
  return (
    <IonCard className={classes.dashproductcard} button onClick={pushroute}>
      <div className={classes.holder}>
        <div
          className={
            size.width <= 768 ? classes.mobileHead : classes.desktopHead
          }
        >
          <img src={_data!?.images?.[0]} alt={'logo'} />
        </div>
        <div
          className={
            size.width <= 768 ? classes.mobileTitle : classes.desktopTitle
          }
        >
          <div className={classes.label}>Title</div>
          <div className={classes.text}>{_data!?.name}</div>
        </div>
        <div className={size.width <= 768 ? classes.mobile : classes.desktop}>
          <div className={classes.price}>
            <div className={classes.label}>Price</div>
            <div className={classes.text}>
              UGX{' '}
              {_data!?.price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            </div>
          </div>
          <div className={classes.createdAt}>
            <div className={classes.label}>Created On</div>
            <div className={classes.text}>
              {format(new Date(_data!?.createdAt!), 'EEEE do MMMM, yyyy')}
            </div>
          </div>
        </div>
      </div>
    </IonCard>
  );
};

export default DashProductCard;
