import React from 'react';
import AuthLayout from '../components/AuthLayout';
import { IonRow, IonCol, IonButton } from '@ionic/react';
import classes from './styles.module.scss';

const CreateAccount: React.FC = () => {

  return (
    <AuthLayout>
      <div className={classes.page}>
        <IonRow>
          <IonCol size="12" sizeSm="10" sizeMd="7" sizeLg="5">
            <p className={classes['welcome-text']}>Welcome to Bazaar Cheap!</p>
            <p>
              Whether you are looking to start selling your
              products or enjoy browsing and discovering great deals, we have the perfect
              place for you.
            </p>
          </IonCol>
        </IonRow>
        <IonRow className={classes['vendor-btn-row']}>
          <IonCol size="11" sizeSm="8" sizeMd="6" sizeLg="4">
            <IonButton
              className={classes['vendor-btn']}
              routerLink="/registervendor"
            >
              Join as a Vendor
            </IonButton>
          </IonCol>
        </IonRow>
        <IonRow className={classes['shopper-btn-row']}>
          <IonCol size="11" sizeSm="8" sizeMd="6" sizeLg="4">
            <IonButton
              className={classes['shopper-btn']}
              routerLink="/signup"
            >
              Join as a Shopper
            </IonButton>
          </IonCol>
        </IonRow>
      </div>
    </AuthLayout>
  );
};

export default CreateAccount;
