import { IonButton, IonIcon, IonInput, IonRippleEffect } from '@ionic/react';
import {
  pencilOutline,
  closeSharp,
  list,
  checkmarkCircle,
  checkmarkOutline,
} from 'ionicons/icons';
import React, { useEffect, useRef, useState } from 'react';
import classes from './styles.module.scss';

const ListItem = (props: {
  name: string;
  openCtx: (e: any) => void;
  id: number;
  type: 'loc' | 'tel';
  setList: (arg0: (form: any) => any) => void;
}) => {
  const [editing, setEditing] = useState<boolean>(false);
  const [name, setName] = useState<string>(props.name);
  const inputRef = useRef<any>(null);

  useEffect(() => {
    if (props.type === 'tel' && props.name.length === 0) {
      const m = inputRef.current;
      // m.setFocus();
    }
  }, [inputRef.current]);
  function edit(e: any) {
    if (props.type === 'loc') {
      return props.openCtx(e);
    }
    if (props.type === 'tel') {
      if (editing === false) {
        return setEditing(true);
      }
      if (editing === true) {
        props.setList((form) => {
          form[props.id] = name;
          return [...form];
        });
        setEditing(false);
      }
    }
  }

  function del(e: any) {
    props.setList((form) => {
      const _list = form.filter((i: any, index: number) => {
        if (index !== props.id) {
          return i
        }
      })
      return [..._list];
    });
  }
  return (
    <div className={classes.listItem}>
      <div className={classes.name}>
        {props.type === 'loc' ? (
          props.name
        ) : editing ? (
          <div className={classes.input}>
            <IonInput
              type="text"
              value={name}
              ref={inputRef}
              onIonChange={(e) => {
                setName(e.detail.value!);
              }}
            ></IonInput>
          </div>
        ) : (
          props.name
        )}
      </div>
      <div className={classes.icons}>
        <div
          className={classes.one + ' ion-activatable ripple-parent circle'}
          onClick={(e) => {
            edit(e);
          }}
        >
          <IonRippleEffect></IonRippleEffect>
          {editing ? (
            <IonIcon
              slot="icon-only"
              icon={checkmarkOutline}
              color="secondary"
            ></IonIcon>
          ) : (
            <IonIcon
              slot="icon-only"
              icon={pencilOutline}
              color="dark"
            ></IonIcon>
          )}
        </div>

        <div
          className={classes.two + ' ion-activatable ripple-parent circle'}
          onClick={(e) => {
            del(e);
          }}
        >
          <IonRippleEffect></IonRippleEffect>
          <IonIcon slot="icon-only" icon={closeSharp} color="dark"></IonIcon>
        </div>
      </div>
    </div>
  );
};

export default ListItem;
