import {
  IonAccordion,
  IonAccordionGroup,
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonRippleEffect,
  IonRow,
} from '@ionic/react';
import { addOutline, caretDownSharp, caretForwardSharp } from 'ionicons/icons';
import { useContext, useEffect, useState } from 'react';
import { DeviceContext } from '../../deviceType';
import DisplaySegment from '../displaySegment';
import classes from './styles.module.scss';
import ProductBlock from '../cards/Product_Block';
import ProductFlex from '../cards/Product_Flex';
import Option from './sortOption';
import { getProductTypes } from '../../context/actions/products';
import useGlobalContext  from '../../context/provider';
import { GET_PRODUCT_TYPES } from '../../context/actions/types';
import ErrorToast from '../error_toast';

const ItemList = ({
  data,
  plusAction,
}: {
  data: {
    title: string;
    listItems: any[];
  };
  plusAction: () => void;
}) => {
  const size = useContext(DeviceContext);
  const { productTypes, productTypesDispatch } = useGlobalContext();
  const [listStyle, setListStyle] = useState<'grid' | 'flex'>('grid');
  const [openContext, setOpenContext] = useState<boolean>(false);
  const [activeSortAction, setActiveSortAction] = useState<
    number | null | undefined
  >(null);
  const [activeFilterAction, setActiveFilterAction] = useState<{
    index: number;
    filter: string;
  }>();
  const [itemList, setItemList] = useState<{ head: string; items: any }[]>([]);
  const [filterOptions, setFilterOptions] = useState<
    { name: string; options: any[] }[]
  >([]);
  const [filteredList, setFilteredList] = useState<
    {
      head: string;
      items: any;
    }[]
  >([]);
  const [isErrorOpen, setIsErrorOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  // filter Functions
  const filterStatus = (criteriaIndex: number) => {
    const action = filterOptions
      .find((e) => {
        return e.name.toLowerCase() === 'status';
      })
      ?.options?.find((e, index) => index === criteriaIndex);
    let list: any[] = [];
    for (let i = 0; i < itemList.length; i++) {
      const items = itemList[i].items;
      const filtered = items.filter((prod: number) => {
        return data.listItems.some(
          (e) =>
            e.id === prod &&
            (action === 'Published'
              ? e.active
              : action === 'Draft' && !e.active)
        );
      });
      const filteredgroup = { head: itemList[i].head, items: filtered };
      if (filtered.length) list = [...list, filteredgroup];
    }
    setFilteredList(list);
  };

  const filterByCategory = (criteriaIndex: number) => {
    const action = filterOptions
      .find((e) => {
        return e.name.toLowerCase() === 'category';
      })
      ?.options?.find((e, index) => index === criteriaIndex);
    let list: any[] = [];
    for (let i = 0; i < itemList.length; i++) {
      const items = itemList[i].items;
      const filtered = items.filter((prod: number) => {
        return data.listItems.some((e) => e.id === prod && e.type === action);
      });
      const filteredgroup = { head: itemList[i].head, items: filtered };
      if (filtered.length) list = [...list, filteredgroup];
    }
    setFilteredList(list);
  };

  const sortByDate = () => {
    const items = data.listItems;

    const sortedItems: any[] = [];
    for (let i = 0; i < items?.length; i++) {
      const month = new Intl.DateTimeFormat('en-US', { month: 'long' }).format(
        new Date(items[i].createdAt)
      );
      const year = new Date(items[i].createdAt).getFullYear();

      if (sortedItems.some((e) => e.head === `${month}, ${year}`)) {
        const group = sortedItems.find((e) => e.head === `${month}, ${year}`);
        group.items.push(items[i].id);
      } else {
        const group = { head: `${month}, ${year}`, items: [items[i].id] };
        sortedItems.push(group);
      }
    }
    return sortedItems;
  };

  const sortNewestFirst = () => {
    const sortedItems = sortByDate();
    setItemList(sortedItems);
  };
  const sortOldestFirst = () => {
    const sortedItems = sortByDate().reverse();
    setItemList(sortedItems);
  };

  // options
  const sortActions: { name: string; action: () => void }[] = [
    { name: 'Newest First', action: sortNewestFirst },
    { name: 'Oldest First', action: sortOldestFirst },
  ];
  const filterActions: {
    name: string;
    action: (criteriaIndex: number) => void;
  }[] = [
    { name: 'Status', action: filterStatus },
    { name: 'Category', action: filterByCategory },
  ];

  useEffect(() => {
    for (let i = 0; i < sortActions.length; i++) {
      if (activeSortAction === i) {
        sortActions[i].action();
      }
    }
  }, [activeSortAction, data?.listItems]);

  useEffect(() => {
    for (let i = 0; i < filterActions.length; i++) {
      if (activeFilterAction?.filter === filterActions[i].name) {
        filterActions[i].action(activeFilterAction?.index);
      }
    }
  }, [activeFilterAction]);

  useEffect(() => {
    setActiveSortAction(0);
    setFilterOptions([
      ...filterOptions,
      { name: 'status', options: ['Published', 'Draft'] },
    ]);
    fetchCategories();
  }, []);

  useEffect(() => {
    if (productTypes.productTypes) {
      setFilterOptions([
        ...filterOptions,
        { name: 'category', options: [...productTypes.productTypes?.data] },
      ]);
    }
    if (productTypes.productTypesError) {
      setIsErrorOpen(true);
      setErrorMessage(
        productTypes?.productTypesError.response?.data
          ? productTypes?.productTypesError.response?.data.message
          : productTypes?.productTypesError.message
      );
      productTypes({ type: GET_PRODUCT_TYPES, payload: null });
    }
  }, [productTypes]);

  const mapProducts = (id: number) => {
    const items = data.listItems.map((product, productIndex) => {
      if (Number(product.id) === id) {
        return listStyle === 'grid' ? (
          <ProductBlock data={product} key={productIndex} />
        ) : (
          <ProductFlex key={productIndex} data={product} />
        );
      } else {
        return null;
      }
    });

    return items;
  };

  const addFilter = (filter: string, index: number) => {
    setActiveFilterAction({ index, filter });
  };
  const fetchCategories = async () => {
    if (productTypes.productTypes?.data?.length) {
      return setFilterOptions([
        ...filterOptions!,
        { name: 'category', options: [...productTypes.productTypes?.data] },
      ]);
    }
    await getProductTypes()(productTypesDispatch);
  };
  const addSort = (e: number) => {
    setActiveFilterAction(undefined);
    setFilteredList([]);
    setActiveSortAction(e);
  };

  function dimensions() {
    if (size.width > 760) {
      return { filter: '10.5', display: '1.5' };
    } else {
      if (size.width > 450) {
        return { filter: '9', display: '3' };
      } else {
        return { filter: '8', display: '4' };
      }
    }
  }

  return (
    <div className={classes.itemListCard}>
      <ErrorToast
        message={errorMessage}
        type={'error'}
        isOpen={isErrorOpen}
        onClose={() => {
          setIsErrorOpen(false);
        }}
      />
      <div className={classes.itemContent}>
        <IonGrid className={classes.itemGrid}>
          <IonRow className={classes.head}>
            <IonCol size="9" className={classes.title}>
              {data.title}
            </IonCol>
            <IonCol size="3" className={classes.icn}>
              <IonButton
                onClick={() => {
                  plusAction();
                }}
                fill="clear"
              >
                <IonIcon
                  slot="icon-only"
                  icon={addOutline}
                  color="primary"
                ></IonIcon>
              </IonButton>
            </IonCol>
          </IonRow>
          <IonRow className={classes.actions}>
            <IonCol size={dimensions().filter} className={classes.sort}>
              <div
                className={
                  classes.sortHolder + ' ion-activatable ripple-parent circle'
                }
                onClick={() => {
                  setOpenContext(!openContext);
                }}
              >
                <IonRippleEffect></IonRippleEffect>
                <div className={classes.label}>Options:</div>
                <div className={classes.btn}>
                  <IonIcon
                    icon={openContext ? caretDownSharp : caretForwardSharp}
                    color="primary"
                  ></IonIcon>
                </div>
              </div>
            </IonCol>
            <IonCol size={dimensions().display} className={classes.display}>
              <DisplaySegment action={setListStyle} />
            </IonCol>
            {openContext && (
              <div className={classes.sortMenu}>
                <div
                  className={classes.sorting}
                  style={{ width: size.width >= 760 ? '23%' : '50%' }}
                >
                  <div className={classes.label}>Sort By</div>
                  <div className={classes.options}>
                    {sortActions.map((item, index) => {
                      return (
                        <div className={classes.item} key={index}>
                          <Option
                            handleChange={addSort}
                            item={{ name: item.name, key: index }}
                            status={index === activeSortAction}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div
                  className={classes.filter}
                  style={{ width: size.width >= 760 ? '23%' : '50%' }}
                >
                  <div className={classes.label}>Filter By</div>
                  <div className={classes.options}>
                    <IonAccordionGroup
                      expand="inset"
                      className={classes.accordGroup}
                    >
                      {filterActions.map((item, ind) => {
                        return (
                          <IonAccordion
                            value={item.name}
                            mode="md"
                            className={classes.accord}
                            key={ind}
                          >
                            <div slot="header" className={classes.accordName}>
                              {item.name}
                            </div>
                            <div
                              className={classes.accordContent + ' content'}
                              slot="content"
                            >
                              {filterOptions
                                .filter(
                                  (e) => e.name === item.name.toLowerCase()
                                )[0]?.options?.map((option, index) => {
                                  return (
                                    <div
                                      className={
                                        classes.item +
                                        ' ion-activatable ripple-parent circle'
                                      }
                                      key={index}
                                      style={{
                                        color: activeFilterAction
                                          ? activeFilterAction.filter ===
                                            item.name
                                            ? activeFilterAction.index === index
                                              ? '#A94064'
                                              : '#0f324d'
                                            : '#0f324d'
                                          : '#0f324d',
                                      }}
                                      onClick={() => {
                                        addFilter(item.name, index);
                                      }}
                                    >
                                      <IonRippleEffect></IonRippleEffect>
                                      {option.toString()}
                                    </div>
                                  );
                                })}
                            </div>
                          </IonAccordion>
                        );
                      })}
                    </IonAccordionGroup>
                  </div>
                </div>
              </div>
            )}
          </IonRow>
          <IonRow className={classes._list}>
            <IonCol size="12">
              <div className={classes.list_container}>
                {activeFilterAction
                  ? filteredList.map((item, index) => {
                      return (
                        <div className={classes.itemGroup} key={index}>
                          <div className={classes.groupLabel}>{item.head}</div>
                          <div className={classes.items}>
                            {item.items.map((id: any) => mapProducts(id))}
                          </div>
                        </div>
                      );
                    })
                  : itemList.map((item, index) => {
                      return (
                        <div className={classes.itemGroup} key={index}>
                          <div className={classes.groupLabel}>{item.head}</div>
                          <div className={classes.items}>
                            {item.items.map((id: any) => mapProducts(id))}
                          </div>
                        </div>
                      );
                    })}
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
    </div>
  );
};

export default ItemList;
