// USER
export const SIGNUP = 'SIGNUP';
export const SIGNUP_ERROR = 'SIGNUP_ERROR';
export const LOGIN = 'LOGIN';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const GETUSER = 'GETUSER';
export const GETUSERERROR = 'GETUSERERROR';
export const FORGOTPASSWORD = 'FORGOTPASSWORD';
export const FORGOTPASSWORD_ERROR = 'FORGOTPASSWORD_ERROR';
export const VERIFYPASSWORD = 'VERIFYPASSWORD';
export const VERIFYPASSWORD_ERROR = 'VERIFYPASSWORD_ERROR';
export const RESETPASSWORD = 'RESETPASSWORD';
export const RESETPASSWORD_ERROR = 'RESETPASSWORD_ERROR';
export const ADMINLOGIN = 'ADMINLOGIN';
export const ADMINLOGIN_ERROR = 'ADMINLOGIN_ERROR';
export const BECOMESELLER = 'BECOMESELLER';
export const BECOMESELLER_ERROR = 'BECOMESELLER_ERROR';
export const UPDATE_USER = 'UPADTE_USER';
export const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR';

// ROLES
export const USER_ROLE_ERROR = 'USER_ROLE_ERROR';
export const USER_ROLE = 'USER_ROLE';

// Store
export const CREATE_STORE = 'CREATE_STORE';
export const CREATE_STORE_ERROR = 'CREATE_STORE_ERROR';
export const GET_STORE_TYPES = 'GET_STORE_TYPES';
export const STORE_TYPES_ERROR = 'STORE_TYPES_ERROR';
export const GET_STORE = 'GET_STORE';
export const GET_STORE_ERROR = 'GET_STORE_ERROR';
export const GET_STORES = 'GET_STORES';
export const GET_STORES_ERROR = 'GET_STORES_ERROR';
export const UPDATE_STORE = 'UPADTE_STORE';
export const UPDATE_STORE_ERROR = 'UPDATE_STORE_ERROR';
export const DELETE_STORE = 'DELETE_STORE';
export const DELETE_STORE_ERROR = 'DELETE_STORE_ERROR';

// Products
export const GET_PRODUCTS = 'GET_PRODUCTS';
export const GET_PRODUCTS_ERROR = 'GET_PRODUCTS_ERROR';
export const DELETE_PRODUCT = 'DELETE_PRODUCT';
export const DELETE_PRODUCT_ERROR = 'DELETE_PRODUCT_ERROR';
export const SEARCH_PRODUCTS = 'SEARCH_PRODUCTS';
export const SEARCH_PRODUCTS_ERROR = 'SEARCH_PRODUCTS_ERROR';
export const CREATE_PRODUCT = 'CREATE_PRODUCT';
export const CREATE_PRODUCT_ERROR = 'CREATE_PRODUCT_ERROR';
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT';
export const UPDATE_PRODUCT_ERROR = 'UPDATE_PRODUCT_ERROR';
export const GET_PRODUCT_TYPES = 'GET_PRODUCT_TYPES';
export const GET_PRODUCT_TYPES_ERROR = 'GET_PRODUCT_TYPES_ERROR';
export const GET_SINGLE_PRODUCT = 'GET_SINGLE_PRODUCT';
export const GET_SINGLE_PRODUCT_ERROR = 'GET_SINGLE_PRODUCT_ERROR';
export const ADD_PRODUCT_VIEW = 'ADD_PRODUCT_VIEW';
export const ADD_PRODUCT_VIEW_ERROR = 'ADD_PRODUCT_VIEW_ERROR';
export const ADD_BULK_PRODUCTS = 'ADD_BULK_PRODUCTS'
export const ADD_BULK_PRODUCTS_ERROR = 'ADD_BULK_PRODUCTS_ERROR'

// top categories
export const GET_TOP_CATEGORIES = 'GET_TOP_CATEGORIES';
export const GET_TOP_CATEGORIES_ERROR = 'GET_TOP_CATEGORIES_ERROR';

// trending products
export const GET_TRENDING_PRODUCTS = 'GET_TOP_CATEGORIES';
export const GET_TRENDING_PRODUCTS_ERROR = 'GET_TOP_CATEGORIES_ERROR';

// wishlist
export const ADD_TO_WISHLIST = 'CREATE_WISHLIST';
export const ADD_TO_WISHLIST_ERROR = 'CREATE_WISHLIST_ERROR';
export const GET_WISHLIST = 'GET_WISHLIST';
export const GET_WISHLIST_ERROR = 'GET_WISHLIST_ERROR';

// contact us
export const CONTACT_US = 'CONTACT_US';
export const CONTACT_US_ERROR = 'CONTACT_US_ERROR';

// admin
export const GET_ADMIN_STORES = 'GET_ADMIN_STORES';
export const GET_ADMIN_STORE_ERRORS = 'GET_ADMIN_STORE_ERRORS';

