import { useContext, useEffect, useState } from 'react';
import classes from './styles.module.scss';
import { IonButton, IonIcon, IonModal } from '@ionic/react';
import { DeviceContext } from '../../deviceType';
import { ProductType } from '../../types/product';
import { getDateInfo } from '../../utils/dates';
import { closeSharp, expandSharp } from 'ionicons/icons';
import { startOfToday, subDays } from 'date-fns';
import {
  ResponsiveContainer,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
} from 'recharts';

const BChart = ({
  data,
  YAxisLabel,
  XAxisLabel,
  errorMessage,
}: {
  data: { id: number | string; product: ProductType; createdAt: string }[];
  YAxisLabel?: string;
  XAxisLabel?: string;
  errorMessage: string;
}) => {
  const [daily, setDaily] = useState<{ day: string; list: number[] }[]>([]);
  const [_data, setData] = useState<{ name: string; value: number }[]>([]);
  const [raw, setRaw] = useState<
    { id: number | string; product: ProductType; createdAt: string }[]
  >([]);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [fullHistory, setFullHistory] = useState<
    { name: string; value: number }[]
  >([]);

  useEffect(() => {
    const _data = data;
    if (data.length) setRaw(data);
  }, [data]);

  useEffect(() => {
    createDateRange(raw);
    showHistory(raw);
  }, [raw]);
  useEffect(() => {
    const extracted_list = daily.map((e) => {
      return { name: e.day, value: e.list.length };
    });
    setData(extracted_list);
  }, [daily]);

  const createDateRange = (
    data: { id: number | string; product: ProductType; createdAt: string }[]
  ) => {
    const today = startOfToday();
    const viewsByDate: any = Array.from({ length: 7 }, (_, i) =>
      getDateInfo(subDays(today, i).toISOString())
    )
      .slice(0, 7)
      .reverse()
      .reduce(
        (prev, curr) => ({
          ...prev,
          [`${curr.day}, ${curr.date} ${curr.month} ${curr.year}`]: 0,
        }),
        {}
      );

    for (const item of data) {
      const dateInfo = getDateInfo(item.createdAt);
      const date = `${dateInfo.day}, ${dateInfo.date} ${dateInfo.month} ${dateInfo.year}`;
      if (viewsByDate.hasOwnProperty(date)) {
        viewsByDate[date]++;
      }
    }
    const list = Object.keys(viewsByDate).map((key) => {
      return { name: key, value: viewsByDate[key] };
    });
    setData(list);
  };
  const closeModal = (): void => {
    setIsModalOpen(false);
  };
  const showHistory = (
    data: { id: number | string; product: ProductType; createdAt: string }[]
  ) => {
    const history: { name: string; value: number }[] = [];
    for (const item of data) {
      const dateInfo = getDateInfo(item.createdAt);
      const date = `${dateInfo.day}, ${dateInfo.date} ${dateInfo.month} ${dateInfo.year}`;
      const existingItem = history.find(
        (historyItem) => historyItem.name === date
      );

      if (existingItem) {
        existingItem!.value++;
      } else {
        history.push({ name: date, value: 1 });
      }
    }
    setFullHistory(history);
  };
  const calculateBarchartWidth = (numberOfItem = 9) => {
    if (numberOfItem > 9) return numberOfItem * 100;
    return '100%';
  };
  return (
    <div className={classes.main}>
      <IonModal
        className={classes.graphModal}
        isOpen={isModalOpen}
        onDidDismiss={closeModal}
      >
        <div className={classes.header}>
          <p>Product Views History </p>
          <IonButton
            slot="end"
            onClick={closeModal}
            color="primary"
            fill="clear"
          >
            <IonIcon
              slot="icon-only"
              color="primary"
              icon={closeSharp}
            ></IonIcon>
          </IonButton>
        </div>
        <div className={classes.container}>
          <div className={classes.graph}>
            {fullHistory.filter((e) => e.value > 0).length ? (
              <ResponsiveContainer
                width={calculateBarchartWidth(fullHistory?.length)}
                height={'100%'}
              >
                <BarChart data={fullHistory}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey={'name'} />
                  <YAxis interval={1} />
                  <Tooltip />
                  {/* <Legend /> */}
                  <Bar dataKey={'value'} fill="#A94064" />
                </BarChart>
              </ResponsiveContainer>
            ) : (
              <div className={classes.message}>No Data To Show</div>
            )}
          </div>
        </div>
      </IonModal>
      <div className={classes.options}>
        <IonButton
          className={classes.modal}
          color="primary"
          fill="clear"
          onClick={() => {
            setIsModalOpen(true);
          }}
        >
          <IonIcon icon={expandSharp} slot="start"></IonIcon>
          History
        </IonButton>
      </div>
      <div className={classes.bgraph}>
        {_data.filter((e) => e.value > 0).length ? (
          <ResponsiveContainer
            width={calculateBarchartWidth(_data?.length)}
            height={'100%'}
          >
            <BarChart data={_data}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey={'name'} />
              <YAxis interval={1} />
              <Tooltip />
              {/* <Legend /> */}
              <Bar dataKey={'value'} fill="#A94064" />
            </BarChart>
          </ResponsiveContainer>
        ) : (
          <div className={classes.message}>{errorMessage}</div>
        )}
      </div>
    </div>
  );
};

export default BChart;
