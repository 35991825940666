import { USER_ROLE, USER_ROLE_ERROR } from '../actions/types';

export const roleInitialState = {
  role: {},
  roleError: null,
};
const roleReducer = (state: any, action: { type: string; payload: any }) => {
  switch (action.type) {
    case USER_ROLE:
      return { ...state, role: action.payload, roleError: null };
    case USER_ROLE_ERROR:
      return { ...state, role: null, roleError: action.payload };
    default:
      return state;
  }
};

export default roleReducer;
