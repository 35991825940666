import { IonButton, IonContent } from '@ionic/react';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { gsap } from 'gsap';
import classes from './styles.module.scss';
import { DeviceContext } from '../../../deviceType';
import { Info, Contacts, Location } from './forms';
import { useHistory } from 'react-router';
import { Store } from '../../../types/store';
import Spinner from '../../../components/spinner';
import { createStore } from '../../../context/actions/store';
import { GlobalContext } from '../../../context/provider';
import { uploadFile, FolderEnum } from '../../../utils/fileUpload';
import AuthLayout from '../components/AuthLayout';

interface form {
  name: string;
  form: JSX.Element;
  skipable?: boolean;
}

const StoreForm: React.FC = ({ location }: any) => {
  const size = useContext<any>(DeviceContext);
  const { storeState, storeDispatch } = useContext<any>(GlobalContext);
  const history = useHistory();
  const nav_progress = useRef<any>(null);
  const [openForm, setOpenForm] = useState<form>({
    name: 'info',
    form: (<></>) as JSX.Element,
  });
  const [store, setStore] = useState<Store>({} as Store);
  const [allowNext, setAllowNext] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<{
    state: boolean;
    msg: string;
  }>({
    state: false,
    msg: '',
  });

  const forms = {
    one: {
      name: 'info',
      form: (
        <Info
          storeUpdate={setStore}
          store={store}
          authNext={setAllowNext}
          loading={setLoading}
        />
      ),
    },
    two: {
      name: 'contact',
      form: (
        <Contacts
          storeUpdate={setStore}
          store={store}
          authNext={setAllowNext}
          loading={setLoading}
        />
      ),
    },
    three: {
      name: 'location',
      form: (
        <Location storeUpdate={setStore} store={store} loading={setLoading} />
      ),
    },
  };

  useEffect(() => {
    if (storeState.store) {
      history.push('/dashboard');
      setLoading(false);
    }
  }, [storeState]);

  useEffect(() => {
    setLoading(true);
    handleFormChange('one');
  }, []);

  const handleFormChange = (breakpoint: 'one' | 'two' | 'three') => {
    const progressbar = nav_progress.current;
    if (size.width >= 768) {
      gsap.to(progressbar, {
        width:
          breakpoint === 'one' ? '0%' : breakpoint === 'two' ? '35%' : '70%',
        rotation: 0,
        yoyo: false,
        ease: 'sine.inOut',
        duration: 0.5,
      });
    } else {
      gsap.to(progressbar, {
        width:
          breakpoint === 'one' ? '0%' : breakpoint === 'two' ? '50%' : '100%',
        rotation: 0,
        yoyo: false,
        ease: 'sine.inOut',
        duration: 0.5,
      });
    }
    setOpenForm(forms[breakpoint]);
    setAllowNext(false);
  };
  const handlePrev = () => {
    if (openForm.name === 'contact') {
      handleFormChange('one');
    } else {
      handleFormChange('two');
    }
  };
  const handleNext = () => {
    if (openForm.name === 'info') {
      handleFormChange('two');
    } else if (openForm.name === 'contact') {
      handleFormChange('three');
    } else {
    }
  };
  const handleSubmit = async () => {
    setLoading(true);
    const { location, contacts, logo, banner, ...rest } = store;
    const _logo: string[] =
      logo && (await handleImageUpload([logo] as File[], 'logo'));
    const _banner: string[] =
      banner && (await handleImageUpload([banner] as File[], 'banner'));
    const locs = (location as string[]).join(',');
    const cons = (contacts as string[]).join(',');

    return await createStore({
      location: locs,
      phoneNumber: cons,
      logo: _logo && _logo[0],
      banner: banner && _banner[0],
      ...rest,
    })(storeDispatch);
  };
  const handleImageUpload = async (
    images: File[],
    folder: 'logo' | 'banner'
  ) => {
    const uploadImages = await uploadFile({
      files: images,
      folder: folder === 'logo' ? FolderEnum.Logos : FolderEnum.Banners,
    });
    if (uploadImages?.error) {
      setError({
        state: true,
        msg: uploadImages.error,
      });
      setLoading(false);
      return;
    }
    return uploadImages;
  };

  return (
    <AuthLayout>
      <section className={classes.container}>
        {loading && (
          <div className={classes.loading}>
            <Spinner type="crescent"></Spinner>
          </div>
        )}
        <div className={classes.actions}>
          <IonButton
            color={'grey'}
            fill={'clear'}
            onClick={() => {
              history.push('/');
            }}
          >
            Cancel
          </IonButton>
        </div>
        <div
          className={classes.content}
          style={{ padding: size.width >= 768 ? '2% 15%' : '2% 2%' }}
        >
          <div
            className={classes.head}
            style={{ height: size.width <= 768 ? '15%' : '12%' }}
          >
            <div className={classes.title}>Create Store</div>
            <div className={classes.navigator}>
              <div
                className={classes.background}
                style={{ width: size.width >= 768 ? '70%' : '100%' }}
              ></div>
              <div
                className={classes.progress}
                ref={nav_progress}
                style={{ left: size.width >= 768 ? '15%' : '0%' }}
              ></div>
              <div
                className={classes.breakpoint + ' ' + classes.one}
                style={{ left: size.width >= 768 ? '15%' : '0%' }}
                onClick={() => {
                  handleFormChange('one');
                }}
              ></div>
              <div
                className={classes.breakpoint + ' ' + classes.two}
                style={{ left: '50%' }}
                onClick={() => {
                  allowNext && handleFormChange('two');
                }}
              ></div>
              <div
                className={classes.breakpoint + ' ' + classes.three}
                style={{
                  left: size.width >= 768 ? '85%' : '',
                  right: size.width >= 768 ? '' : '0%',
                }}
                onClick={() => {
                  allowNext && handleFormChange('three');
                }}
              ></div>
            </div>
          </div>
          <div
            className={classes.form}
            style={{ height: size.width <= 768 ? '70%' : '73%' }}
          >
            {openForm.form}
          </div>
          <div className={classes.controls}>
            {openForm.name !== 'info' && (
              <IonButton
                color="secondary"
                fill="clear"
                onClick={() => {
                  handlePrev();
                }}
              >
                Previous
              </IonButton>
            )}
            {openForm.name !== 'location' && (
              <IonButton
                color="secondary"
                fill="solid"
                onClick={() => {
                  handleNext();
                }}
                disabled={!allowNext}
              >
                Next
              </IonButton>
            )}
            {openForm.name === 'location' && (
              <IonButton color="secondary" fill="solid" onClick={handleSubmit}>
                Submit
              </IonButton>
            )}
          </div>
        </div>
      </section>
    </AuthLayout>
  );
};

export default StoreForm;
