import { useEffect, useMemo } from 'react';
import { Route, Redirect, useLocation } from 'react-router';
import { useHistory } from 'react-router';
import { DecodedToken, authService } from '../utils/authService';

interface ProtectedRouteProps{
  roles?: string[];
  component:any;
  path:string;
  exact: boolean
}

const ProtectedRoute = ({roles, component: Component, ...props}:ProtectedRouteProps) => {
  const location = useLocation();
  const history = useHistory();
  const loggedInUser = useMemo (()=>authService.getCurrentUser()  as DecodedToken,[])
  const _isloggedIn = loggedInUser.id

  useEffect(() => {
    if (location.pathname.includes('admin') && !_isloggedIn) {
      history.push('/admin/login');
    }
  }, [location.pathname, _isloggedIn]);

  if (_isloggedIn) {
    const isAuthorized = roles!?.includes(loggedInUser.role);

    if(!isAuthorized){
      return <Redirect to="/"/>
    }
  }

  return (
    <Route
        {...props}
        render={  props =>{
          return authService.isLoggedIn()
            ? <Component {...props} /> :authService.redirectUser()}
        }
      />
  );
};

export default ProtectedRoute;
