import React, { useContext, useEffect, useState } from 'react';
import classes from './styles.module.scss';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { DeviceContext } from '../../deviceType';

const RichTextInput = ({
  value,
  handleChange,
}: {
  value: string;
  handleChange: (value: string, name: string) => void;
}) => {
  const size = useContext(DeviceContext);
  const desktopModules = [
    [{ font: [] }],
    [{ header: [1, 2, 3, false] }],
    ['bold', 'italic', 'underline', 'blockquote'],
    [{ script: 'sub' }, { script: 'super' }],
    [{ align: [] }],
    [
      {
        color: [
          '#0f324d',
          '#A94064',
          '#ffff',
          '#00000',
          'red',
          'yellow',
          '#2f3d7e',
          '#101920',
          '#990012',
          '#9933ff',
        ],
      },
      {
        background: [
          '#0f324d',
          '#A94064',
          '#ffff',
          '#00000',
          'red',
          'yellow',
          '#2f3d7e',
          '#101920',
          '#990012',
          '#9933ff',
        ],
      },
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' },
    ],
    ['link'],
    ['clean'],
  ];
  const mobileModules = [
    [{ header: [1, 2, 3, false] }],
    ['bold', 'italic', 'underline', 'blockquote'],
    [{ align: [] }],
    [
      {
        color: [
          '#0f324d',
          '#A94064',
          '#ffff',
          '#00000',
          'red',
          'yellow',
          '#2f3d7e',
          '#101920',
          '#990012',
          '#9933ff',
        ],
      },
      {
        background: [
          '#0f324d',
          '#A94064',
          '#ffff',
          '#00000',
          'red',
          'yellow',
          '#2f3d7e',
          '#101920',
          '#990012',
          '#9933ff',
        ],
      },
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' },
    ],
    ['link'],
    ['clean'],
  ];

  function _handleChange(content: any, delta: any, source: any, editor: any) {
    const data = editor.getHTML();
    handleChange(data, 'details');
  }
  return (
    <div className={classes.input}>
      <ReactQuill
        className={classes.content}
        theme="snow"
        value={value}
        modules={{
          toolbar: {
            container: size.width >= 768 ? desktopModules : mobileModules,
            handlers: {},
            history: {
              delay: 2000,
              maxStack: 500,
              userOnly: true,
            },
          },
        }}
        onChange={_handleChange}
      ></ReactQuill>
    </div>
  );
};

export default RichTextInput;
