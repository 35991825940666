import { IonGrid, IonRow, IonCol, IonSpinner } from '@ionic/react';
import React, { useContext, useEffect, useState } from 'react';
import Layout from '../../../components/Layout/dashboard';
import StoreInfoCard from '../../../components/storeInfoCard';
import classes from './styles.module.scss';
import { DeviceContext } from '../../../deviceType';
import { GlobalContext } from '../../../context/provider';
import { getStore } from '../../../context/actions/store';
import { GET_STORE_ERROR } from '../../../context/actions/types';
import ItemList from '../../../components/itemList';
import { ProductType } from '../../../types/product';
import StoreBar from '../../../components/storeBar';
import { storeSchema } from '../../../types/store';
import CurrentStoreCard from '../../../components/currentstorecard';
import { getCurrentStore } from '../../../utils/currentStore';
import CreateProductsModal from './modal';
import ErrorToast from '../../../components/error_toast';

const Store = () => {
  const { storeState, storeDispatch } = useContext<any>(GlobalContext);
  const [loading, setLoading] = useState<boolean>(true);
  const size = useContext(DeviceContext);
  // Commented out for now
  // const offers = { title: 'Offers', listItems: [] };
  const [store, setStore] = useState<storeSchema>({} as storeSchema);
  const [products, setProducts] = useState<ProductType[]>([]);
  const [openEditor, setOpenEditor] = useState<boolean>(false);
  const [isErrorOpen, setIsErrorOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  useEffect(() => {
    setLoading(true);
    getStoreInfo();
  }, []);

  useEffect(() => {
    if (storeState!?.store) {
      const store = storeState.store;
      setStore(store);
      const prods = store?.products;
      setProducts(prods);
      setLoading(false);
    }
    if (storeState!?.storeError) {
      setIsErrorOpen(true);
      setErrorMessage(
        storeState?.storeError!?.response!?.data!?.message
          ? storeState?.storeError!?.response!?.data!?.message
          : storeState?.storeError!?.message
      );
      storeDispatch({ type: GET_STORE_ERROR, payload: null });
      setLoading(false);
    }
  }, [storeState!?.store?.id, storeState!?.storeError]);

  async function getStoreInfo() {
    const currentStore = getCurrentStore();
    await getStore(Number(currentStore))(storeDispatch);
  }
  const handleOpenEditor = () => {
    setOpenEditor(true);
  };
  return (
    <Layout>
      <ErrorToast
        message={errorMessage}
        type={'error'}
        isOpen={isErrorOpen}
        onClose={() => {
          setIsErrorOpen(false);
        }}
      />
      {openEditor && (
        <CreateProductsModal open={openEditor} openCtx={setOpenEditor} />
      )}
      {loading && (
        <div className={classes.loading}>
          <IonSpinner color="secondary"></IonSpinner>
        </div>
      )}
      {!loading &&
        (size.width > 769 ? (
          <IonGrid className={classes.main}>
            <IonRow className={classes.content}>
              <IonCol size="8.5" className={classes.container}>
                <div className={classes.holder}>
                  {/* Store Information*/}
                  <div
                    className={classes._storeCard}
                    style={{ padding: '1em 2em 0 2em', height: 'auto' }}
                  >
                    <StoreInfoCard
                      data={store!}
                      action={() => {
                        handleOpenEditor();
                      }}
                    />
                  </div>

                  {/* Product List */}
                  <div
                    className={classes.productList}
                    style={{ padding: '1em 2em 0.5em 2em' }}
                  >
                    <ItemList
                      data={{ title: 'Products', listItems: products }}
                      plusAction={() => {
                        handleOpenEditor();
                      }}
                    />
                  </div>

                  {/* Offers List */}
                  {/* <div
                    className={classes.offerList}
                    style={{ padding: '1em 2em 0.5em 2em' }}
                  >
                    <ItemList
                      data={offers}
                      plusAction={() => {
                        openeditor(true);
                      }}
                    />
                  </div> */}
                </div>
              </IonCol>
              <IonCol size="3.5" className={classes.storeBar}>
                <StoreBar />
              </IonCol>
            </IonRow>
          </IonGrid>
        ) : (
          <IonGrid className={classes.main}>
            <IonRow className={classes.content}>
              <IonCol size="12" className={classes.container}>
                <div className={classes.holder}>
                  {/**Content Goes Here */}
                  <div className={classes.currentStore}>
                    <CurrentStoreCard />
                  </div>
                  <div
                    className={classes._storeCard}
                    style={{ padding: '0.6em', height: '95vh' }}
                  >
                    <StoreInfoCard
                      data={store!}
                      action={() => {
                        handleOpenEditor();
                      }}
                    />
                  </div>

                  {/* Product List */}
                  <div
                    className={classes.productList}
                    style={{ padding: '0.8em', marginBottom: '2em' }}
                  >
                    <ItemList
                      data={{ title: 'Products', listItems: products }}
                      plusAction={() => {
                        handleOpenEditor();
                      }}
                    />
                  </div>
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
        ))}
    </Layout>
  );
};

export default Store;
