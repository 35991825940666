import { CreateProduct, ProductType } from '../../types/product';
import {
  ADD_BULK_PRODUCTS,
  ADD_BULK_PRODUCTS_ERROR,
  ADD_PRODUCT_VIEW,
  ADD_PRODUCT_VIEW_ERROR,
  ADD_TO_WISHLIST,
  ADD_TO_WISHLIST_ERROR,
  CREATE_PRODUCT,
  CREATE_PRODUCT_ERROR,
  DELETE_PRODUCT,
  DELETE_PRODUCT_ERROR,
  GET_PRODUCTS,
  GET_PRODUCTS_ERROR,
  GET_SINGLE_PRODUCT,
  GET_SINGLE_PRODUCT_ERROR,
  GET_TRENDING_PRODUCTS,
  GET_TRENDING_PRODUCTS_ERROR,
  GET_WISHLIST,
  SEARCH_PRODUCTS,
  SEARCH_PRODUCTS_ERROR,
  UPDATE_PRODUCT,
  UPDATE_PRODUCT_ERROR,
} from '../actions/types';

export interface productState {
  createProductError: any
  deleteProduct: any
  deleteProductError: any
  createProduct: ProductType | null,
  getSingleProduct: ProductType | null
  getSingleProductError: any,
  updateProduct: {data: ProductType} | null,
  updateProductError: any
}
export const productInitialState: productState = {
  createProductError: null,
  deleteProduct: null,
  deleteProductError: null,
  createProduct: null,
  getSingleProduct: null,
  getSingleProductError: null,
  updateProduct: null,
  updateProductError: null
};

const productReducer = (
  state: productState,
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case CREATE_PRODUCT:
      return {
        ...state,
        createProduct: action.payload,
        createProductError: null,
      };
    case CREATE_PRODUCT_ERROR:
      return {
        ...state,
        createProduct: null,
        createProductError: action.payload,
      };
    case UPDATE_PRODUCT:
      return {
        ...state,
        updateProduct: action.payload,
        updateProductError: null,
      };
    case UPDATE_PRODUCT_ERROR:
      return {
        ...state,
        updateProduct: null,
        updateProductError: action.payload,
      };
    case GET_PRODUCTS:
      return {
        ...state,
        getProducts: action.payload,
        getProductsError: null,
      };
    case GET_PRODUCTS_ERROR:
      return {
        ...state,
        getProducts: null,
        getProductsError: action.payload,
      };
    case SEARCH_PRODUCTS:
      return {
        ...state,
        searchProducts: action.payload,
        searchProductsError: null,
      };
    case SEARCH_PRODUCTS_ERROR:
      return {
        ...state,
        searchProducts: null,
        searchProductsError: action.payload,
      };
    case GET_SINGLE_PRODUCT:
      return {
        ...state,
        getSingleProduct: action.payload,
        getSingleProductError: null,
      };
    case GET_SINGLE_PRODUCT_ERROR:
      return {
        ...state,
        getSingleProduct: null,
        getSingleProductError: action.payload,
      };
    case DELETE_PRODUCT:
      return {
        ...state,
        deleteProduct: action.payload,
        deleteProductError: null,
      };
    case DELETE_PRODUCT_ERROR:
      return {
        ...state,
        deleteProduct: null,
        deleteProductError: action.payload,
      };
    case GET_TRENDING_PRODUCTS:
      return {
        ...state,
        trendingProducts: action.payload,
        trendingProductsError: null,
      };
    case GET_TRENDING_PRODUCTS_ERROR:
      return {
        ...state,
        trendingProducts: null,
        trendingProductsError: action.payload,
      };
    case ADD_TO_WISHLIST:
      return {
        ...state,
        wishlistProducts: action.payload,
        wishlistProductsError: null,
      };
    case ADD_TO_WISHLIST_ERROR:
      return {
        ...state,
        wishlistProducts: null,
        wishlistProductsError: action.payload,
      };
    case GET_WISHLIST:
      return {
        ...state,
        wishlist: action.payload,
        wishlistError: null,
      };
    case ADD_PRODUCT_VIEW:
      return {
        ...state,
        addProductView: action.payload,
        addProductViewError: null,
      };
    case ADD_PRODUCT_VIEW_ERROR:
      return {
        ...state,
        addProductView: null,
        addProductViewError: action.payload,
      };
    case ADD_BULK_PRODUCTS:
      return {
        ...state,
        addBulkProducts: action.payload,
        addBulkProductsError: null,
      };
    case ADD_BULK_PRODUCTS_ERROR:
      return {
        ...state,
        addBulkProducts: null,
        addBulkProductsError: action.payload,
      };
    default:
      return state;
  }
};

export default productReducer;
