import React, { useContext, useEffect, useState } from 'react';
import classes from './styles.module.scss';
import {
  IonCard,
  IonCol,
  IonGrid,
  IonRippleEffect,
  IonRow,
} from '@ionic/react';
import { DeviceContext } from '../../../deviceType';
import { Link } from 'react-router-dom';
import WishlistIcon from '../wish-list/wish-list-icon';
import ImageCarousel from '../../../components/ImageCarousel';
import { ProductType } from '../../../types/product';

const ProductCard = (props: { data: ProductType }) => {
  const size = useContext(DeviceContext);

  const [item, setItem] = useState<ProductType>({} as ProductType);
  const [locations, setLocations] = useState<string>('');
  const [price, setPrice] = useState<string>('');

  useEffect(() => {
    setItem(props.data);
  }, [props.data]);

  useEffect(() => {
    if (item?.store) {
      const locs = item?.store.location!.split(',');
      const locations = locs?.slice(0, 2).join(',');
      setLocations(locations!);
    }
    if (item?.price) {
      const itemPrice = item.price
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      setPrice(itemPrice);
    }
  }, [item]);

  function dimensions() {
    if (size.width <= 768) {
      return {};
    } else {
      return { height: '40vw' };
    }
  }

  return (
    <IonCard
      className={classes.productCard}
      style={{
        minHeight: dimensions()!.height,
      }}
    >
      {size.width > 768 && (
        <IonGrid className={classes.desktopGrid}>
          <IonRow className={classes.actions}>
            <IonCol size="12">
              <div
                className={
                  classes.bookmark + ' ion-activatable ripple-parent circle'
                }
              >
                <IonRippleEffect color="secodary"></IonRippleEffect>
                <WishlistIcon productId={props.data.id} />
              </div>
            </IonCol>
          </IonRow>
          <IonRow className={classes.info}>
            <IonCol className={classes.img} size={'5'}>
              <div className={classes.imgHolder}>
                <ImageCarousel
                  data={props.data.images}
                  name={props.data.name}
                />
              </div>
            </IonCol>
            <IonCol className={classes.typography} size={'7'}>
              <div className={classes.title}>{item?.name}</div>
              <div className={classes.price}>UGX {price}</div>
              <div className={classes.store}>
                <Link
                  to={{
                    pathname: `/${item?.store?.slug}`,
                    state: { id: item?.store?.id },
                  }}
                >
                  {item?.store?.name}
                </Link>
              </div>
              <div className={classes.locations}>{locations}</div>
            </IonCol>
          </IonRow>
          <wbr></wbr>
          <IonRow className={classes.productDetails}>
            <IonCol>
              <div className={classes.heading}>Product Details</div>
              <div
                className={classes.details}
                dangerouslySetInnerHTML={{ __html: item?.details! }}
              ></div>
            </IonCol>
          </IonRow>
        </IonGrid>
      )}

      {size.width <= 768 && (
        <IonGrid className={classes.mobileGrid}>
          <IonRow className={classes.actions}>
            <IonCol size="12">
              <div
                className={
                  classes.bookmark + ' ion-activatable ripple-parent circle'
                }
              >
                <IonRippleEffect color="secodary"></IonRippleEffect>
                <WishlistIcon productId={props.data.id} />
              </div>
            </IonCol>
          </IonRow>
          <IonRow className={classes.img}>
            <IonCol size={'12'}>
              <div className={classes.imgHolder}>
                <ImageCarousel
                  data={props.data.images}
                  name={props.data.name}
                />
              </div>
            </IonCol>
          </IonRow>
          <IonRow className={classes.info}>
            <IonCol size={'12'}>
              <div className={classes.title}>{item?.name}</div>
            </IonCol>
          </IonRow>
          <IonRow className={classes.price}>
            <IonCol size={'8'} className={classes.one}>
              <div>UGX {price}</div>
            </IonCol>
            <IonCol size={'4'} className={classes.two}></IonCol>
          </IonRow>
          <IonRow className={classes.store}>
            <IonCol size={'6'} className={classes.one}>
              <div className={classes.name}>
                <Link
                  to={{
                    pathname: `/${item?.store?.slug}`,
                    state: { id: item?.store?.id },
                  }}
                >
                  {item?.store?.name}
                </Link>
              </div>
            </IonCol>
            <IonCol size={'6'} className={classes.two}>
              <div className={classes.location}>{locations.split(',')[0]}</div>
            </IonCol>
          </IonRow>
          <IonRow className={classes.productDetails}>
            <IonCol>
              <div className={classes.heading}>Product Details</div>
              <div
                className={classes.details}
                dangerouslySetInnerHTML={{ __html: item?.details! }}
              ></div>
            </IonCol>
          </IonRow>
        </IonGrid>
      )}
    </IonCard>
  );
};

export default ProductCard;
