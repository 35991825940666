import { getDay, getHours } from 'date-fns';

const { parse, getYear, getMonth, getDate, getTime } = require('date-fns');

export const getDateInfo = (dateString: string) => {
  const parsedDate = new Date(dateString);
  const year = getYear(parsedDate);
  const month = new Intl.DateTimeFormat('en-UK', {
    month: 'short',
  }).format(parsedDate);
  const date = getDate(parsedDate);
  const day = new Intl.DateTimeFormat('en-UK', {
    weekday: 'short',
  }).format(parsedDate);
  const hours = getHours(parsedDate);

  return {
    year,
    month,
    date,
    hours,
    day,
  };
};
