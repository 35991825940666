import jwt_decode from 'jwt-decode';
import { isAfter, fromUnixTime } from 'date-fns';
import { authService } from './authService';

export const isLoggedIn = () => {
  const token = localStorage.getItem('token');
  if (token) {
    const decoded = jwt_decode<any>(token!);
    const expired = isAfter(Date.now(), fromUnixTime(decoded!?.exp));
    if (expired) {
      authService.logoutUser();
      return false;
    } else {
      return true;
    }
  } else {
    return false;
  }
};
