import { IonRippleEffect, IonIcon } from '@ionic/react';
import { searchSharp } from 'ionicons/icons';
import React, {  useRef } from 'react';
import classes from './styles.module.scss';

const SearchFieldLocal = ({setSearchText, text, handleSearch}: {
  setSearchText:(params: any) => void;
  text:string
  handleSearch: () => void;
}) => {
  const inputRef = useRef<any>();
  const btnRef = useRef<any>();


  return (
    <div className={classes.searchField}>
      <input
        ref={inputRef}
        type="text"
        className={classes.field}
        inputMode={'search'}
        value={text}
        onFocus={() => {
          inputRef.current.addEventListener('keypress', (e: any) => {
            if (e.code === 'Enter' && text.length > 0) {
              btnRef.current.click();
            }
          });
        }}
        onChange={(e) => {
          setSearchText(e.target.value);
        }}
        placeholder={'Search'}
      />
      <div
        className={classes.searchbtn + ' ion-activatable ripple-parent circle'}
        onClick={handleSearch}
        ref={btnRef}
      >
        <IonRippleEffect></IonRippleEffect>
        <IonIcon icon={searchSharp} color={'light'} size="small" slot="start" />
      </div>
    </div>
  );
};

export default SearchFieldLocal;
