import { IonChip, IonIcon, IonLabel, IonLoading } from '@ionic/react';
import {
  caretDownOutline,
  caretForwardOutline,
  personSharp,
} from 'ionicons/icons';
import { useContext } from 'react';
import { DeviceContext } from '../../deviceType';
import classes from './styles.module.scss';
import { User } from '../../types/user';

const StoreMenu = (props: {
  open: boolean;
  userDetails?: User;
  openCntx: (e: any) => void;
}) => {
  const size = useContext(DeviceContext);
  return size.width < 769 ? (
    <IonChip className={classes.main} onClick={props.openCntx} id="store-menu-trigger">
      <div className={classes.store}>
        <IonIcon icon={personSharp} color="light" size="normal" />
      </div>
      <IonLabel>
        {props.userDetails?.firstName} {props.userDetails?.lastName}
      </IonLabel>
      <IonIcon
        icon={props.open ? caretDownOutline : caretForwardOutline}
        color="primary"
      />
    </IonChip>
  ) : (
    <IonChip className={classes.main} onClick={props.openCntx} id="store-menu-trigger">
      <div className={classes.store}>
        <IonIcon icon={personSharp} color="light" size="normal" />
      </div>
      <IonLabel>
        {!props.userDetails ? (
          <IonLoading isOpen message="loading..." duration={3000}></IonLoading>
        ) : (
          <>
            {props.userDetails.firstName.charAt(0).toUpperCase() +
              props.userDetails.firstName.slice(1)}
          </>
        )}
      </IonLabel>
      <IonIcon
        icon={props.open ? caretDownOutline : caretForwardOutline}
        color="primary"
      />
    </IonChip>
  );
};

export default StoreMenu;
