import { useContext, useEffect, useState } from 'react';
import Layout from '../../../components/Layout/dashboard';
import classes from './styles.module.scss';
import { DeviceContext } from '../../../deviceType';
import { IonGrid, IonRow, IonCol } from '@ionic/react';
import ProductMetrics from './metricsCard';
import ItemDetailsCard from '../../../components/productDetailsCard';
import { useLocation } from 'react-router';
import useGlobalContext from '../../../context/provider';
import { getSingleProduct } from '../../../context/actions/products';
import { GET_SINGLE_PRODUCT_ERROR } from '../../../context/actions/types';
import { ProductType } from '../../../types/product';
import Spinner from '../../../components/spinner';
import ErrorToast from '../../../components/error_toast';

const ProductSeller = () => {
  const size = useContext(DeviceContext);
  const location = useLocation();
  const [id, setId] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);
  const [item, setItem] = useState<ProductType>({} as ProductType);
  const { productState, productDispatch } = useGlobalContext();
  const [isErrorOpen, setIsErrorOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  useEffect(() => {
    setLoading(true);
    const _id2 = location.pathname
      .split('?')
      .slice(-1)[0]
      .split('&')[0]
      .split('=')
      .slice(-1)[0];
    const _id: any = location.state ? location.state : null;
    if (_id) {
      setId(`${_id.id}`);
      fetchItem(_id.id);
    } else if (_id2) {
      setId(_id2);
      fetchItem(Number(_id2));
    }
  }, []);

  useEffect(() => {
    if (productState.getSingleProduct) {
      const product = productState.getSingleProduct;
      if (product.id === Number(id)) {
        setItem(product);
        setLoading(false);
      }
    }
    if (productState?.getSingleProductError) {
      setIsErrorOpen(true);
      setErrorMessage(
        productState?.getSingleProductError!?.response!?.data!
          ?.message
          ? productState?.getSingleProductError!?.response!?.data!
              ?.message
          : productState?.getSingleProductError!?.message
      );
      productDispatch({ type: GET_SINGLE_PRODUCT_ERROR, payload: null });
      setLoading(false);
    }
  }, [productState?.getSingleProduct]);

  useEffect(() => {
    if (item.id) setLoading(false);
  }, [item]);

  async function fetchItem(id: number) {
    if (productState?.getSingleProduct?.id === id) {
      const product = productState.getSingleProduct;
      setItem(product);
      return;
    }
    await getSingleProduct(id)(productDispatch);
  }
  const destroy = () => {
    setItem({} as ProductType);
    setId('');
  };
  return (
    <Layout>
      <ErrorToast
        message={errorMessage}
        type={'error'}
        isOpen={isErrorOpen}
        onClose={() => {
          setIsErrorOpen(false);
        }}
      />
      <div id="pageContainer" key={id}>
        {loading ? (
          <div className={classes.loading}>
            <Spinner type="crescent"></Spinner>
          </div>
        ) : size.width >= 768 ? (
          <div className={classes.desktop}>
            <IonGrid className={classes.desktopGrid}>
              <IonRow className={classes.holder}>
                <IonCol
                  size="12"
                  className={classes.leftbar}
                  style={{ padding: '0 15%' }}
                >
                  <div className={classes.product}>
                    <ItemDetailsCard
                      data={item}
                      productId={`${id}`}
                      onDestroy={destroy}
                    />
                  </div>
                </IonCol>
                <IonCol
                  size="4"
                  className={classes.rightbar}
                  style={{ display: 'none' }}
                >
                  <div className={classes.metricsHolder}>
                    <ProductMetrics data={[]} />
                  </div>
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
        ) : (
          <div className={classes.mobile}>
            <IonGrid className={classes.mobileGrid}>
              <IonRow className={classes.product}>
                <IonCol size="12">
                  <div className={classes.productHolder}>
                    <ItemDetailsCard
                      data={item}
                      productId={`${id}`}
                      onDestroy={destroy}
                    />
                  </div>
                </IonCol>
              </IonRow>
              <IonRow
                className={classes.metricsCard}
                style={{ display: 'none' }}
              >
                <IonCol size="12">
                  <div className={classes.metricsHolder}>
                    <ProductMetrics data={[]} />
                  </div>
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default ProductSeller;
