import { IonInput, IonButton, IonIcon } from '@ionic/react';
import { searchSharp } from 'ionicons/icons';
import React, { useContext, useRef, useState } from 'react';
import { DeviceContext } from '../../deviceType';
import classes from './styles.module.scss';

const SearchField = (props: {
  value: string;
  setValue: React.Dispatch<any>;
  action: () => void;
}) => {
  const size = useContext<any>(DeviceContext);
  const [query, setQuery] = useState<string>(props.value);
  const btn = useRef<any>();
  function updateQuery() {
    props.setValue((params:any) => {
      return {
        ...params,
        text: query,
      };
    });

    props.action();
  }
  return (
    <div className={classes.searchInput}>
      <IonInput
        placeholder="Search"
        className={classes.input}
        value={query}
        clearInput
        color="primary"
        style={{
          width: size.width >= 768 && '70%',
        }}
        onIonFocus={(e) => {
          e.target?.addEventListener('keypress', (e: any) => {
            if (e.code === 'Enter') {
              btn.current.click();
            }
          });
        }}
        type="search"
        mode="ios"
        onIonChange={(e) => {
          setQuery(e.detail!.value!);
        }}
      ></IonInput>
      <IonButton
        color="secondary"
        fill="solid"
        ref={btn}
        style={{
          width: size.width >= 768 && '15%',
        }}
        className={classes.btn}
        onClick={updateQuery}
        mode="ios"
      >
        {size.width > 769 ? (
          'Search'
        ) : (
          <IonIcon slot="icon-only" color="light" icon={searchSharp}></IonIcon>
        )}
      </IonButton>
    </div>
  );
};

export default SearchField;
