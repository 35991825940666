import { IonPage, IonContent } from '@ionic/react';
import classes from './styles.module.scss';
import Nav from '../../../components/Layout/user/nav';
import Footer from '../../../components/Layout/user/footer';

const AuthLayout = ({ children }: any) => {
  return (
    <IonPage>
      {/* header */}
      <div className={classes['authlayout-nav']} id={'nav'}>
        <Nav />
      </div>

      {/* Content */}
      <IonContent className={classes['auth-layout']}>
        <div className={classes['page-wrapper']}>
          <div className={classes['content-wrapper']}>{children}</div>

          {/* Footer */}
          <div id="auth-footer" className={classes['auth-footer']}>
            <Footer />
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};
export default AuthLayout;
