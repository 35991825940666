import { IonCol, IonGrid, IonRow } from '@ionic/react';
import React, { useContext } from 'react';
import SellerNav from '../nav';
import classes from './styles.module.scss';
import { GlobalContext } from '../../../../context/provider';

const Mobile = ({ children }: any) => {
  const { userState } = useContext<any>(GlobalContext);
  const userDetails = userState.getUser;

  return (
    <IonGrid className={classes.mobile}>
      <IonRow className={classes.topBar}>
        <IonCol size="12">
          <SellerNav userDetails={userDetails} />
        </IonCol>
      </IonRow>
      <IonRow className={classes.container}>
        <IonCol size="12" className={classes.content}>
          {children}
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default Mobile;
