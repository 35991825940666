import { IonMenu } from '@ionic/react';
import { useContext, useRef } from 'react';
import Seller from './seller';
import User from './user';
import classes from './styles.module.scss';
import { GlobalContext } from '../../context/provider';

const Menu = () => {
  const { userState } = useContext<any>(GlobalContext);
  const user = userState.getUser;
  const slideMenuRef = useRef<any>(null);

  return (
    <IonMenu
      side="end"
      swipe-gesture
      contentId="main"
      className={classes.cont}
      menuId="menu"
      ref={slideMenuRef}
    >
      {user && user.role === 'seller' ? (
        <Seller user={user} action={slideMenuRef.current} />
      ) : (
        <User action={slideMenuRef.current} />
      )}
    </IonMenu>
  );
};

export default Menu;
