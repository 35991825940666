import React, { useContext, useEffect, useState } from 'react';
import classes from './styles.module.scss';
import { DeviceContext } from '../../../deviceType';
import {
  IonCard,
  IonCol,
  IonGrid,
  IonIcon,
  IonRippleEffect,
  IonRow,
} from '@ionic/react';
import { storefrontSharp } from 'ionicons/icons';
import { useHistory } from 'react-router';
import { storeSchema } from '../../../types/store';

const StoreCard = (props: { data: storeSchema }) => {
  const size = useContext(DeviceContext);
  const [data, setData] = useState<storeSchema>({} as storeSchema);
  const history = useHistory();

  useEffect(() => {
    if (props.data?.id) {
      setData(props.data);
    }
  }, [props.data]);

  function dimensions() {
    if (size.width <= 768) {
      return {};
    } else {
      return { height: '35vw' };
    }
  }
  return (
    <IonCard
      className={classes.storeCard + ' ion-activatable ripple-parent circle'}
      style={{
        height: dimensions()!.height,
      }}
      onClick={() => {
        history.push({
          pathname: `/stores/detail?q=${props.data.id}&s=${props.data.name}`,
          state: { id: props.data.id },
        });
      }}
    >
      <IonRippleEffect></IonRippleEffect>
      <div className={classes.profile}>
        <div className={classes.banner}>
          <div className={classes.overlay}></div>
          <img alt="" src={''}></img>
        </div>
        <div className={classes.profileImg}>
          <div className={classes.overlay}></div>
          <img alt="" src={data?.logo!}></img>
        </div>
      </div>
      <div className={classes.info}>
        <IonGrid className={classes.infogrid}>
          <IonRow className={classes.row1}>
            <IonCol className={classes.name} size={'8'}>
              <div className={classes.title}>{data?.name}</div>
              <div className={classes.type}>
                <IonIcon icon={storefrontSharp} color="secondary" />
                {data?.type}
              </div>
            </IonCol>
            <IonCol className={classes.rank} size={'4'}></IonCol>
          </IonRow>
          <IonRow className={classes.row2}>
            <IonCol className={classes.locations} size={'6'}>
              <div className={classes.label}>Locations</div>
              <div className={classes.list}>
                {data
                  ?.location!?.split(',')!
                  ?.map((item: string, index: number) => {
                    return (
                      <div key={index} className={classes.location}>
                        {item}
                      </div>
                    );
                  })}
              </div>
            </IonCol>
            <IonCol className={classes.contacts} size={'6'}>
              <div className={classes.label}>Contacts</div>
              <div className={classes.list}>
                {data
                  ?.phoneNumber!?.split(',')!
                  ?.map((item: string, index: number) => {
                    return (
                      <div key={index} className={classes.contact}>
                        {item}
                      </div>
                    );
                  })}
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
    </IonCard>
  );
};

export default StoreCard;
