import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { IonApp, IonRouterOutlet, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './theme/global.scss';

/* Swiper */
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

/* State Managers */
import { DeviceContext } from './deviceType';
import React, { useContext, useEffect, useMemo } from 'react';
import Menu from './components/slideMenu';
import { main } from './utils/routes';
import ProtectedRoute from './guard/ProtectedRoute';
import { authService } from './utils/authService';
import { DecodedToken } from './utils/getClientInfo';
import { getWishList } from './context/actions/products';
import { getUserInfo } from './context/actions/user';
import AdminSlideMenu from './components/Layout/admin/nav/menu';
import { setCurrentStore } from './utils/currentStore';
import useGlobalContext from './context/provider';

setupIonicReact();

const App: React.FC = () => {
  const size = useContext(DeviceContext);
  const {productDispatch,userDispatch} = useGlobalContext();
  const loggedInUser = useMemo(
    () => authService.getCurrentUser() as DecodedToken,
    []
  );
  const _isloggedIn = loggedInUser.id;
  const isAdminLoggedIn = loggedInUser.role === 'admin' ? true : false
  const fetchWishList = async () => {
    await getWishList()(productDispatch);
  };

  const getLoggedInUserDetails = async (id: number) => {
    await getUserInfo(id)(userDispatch);
  };
  useEffect(() => {
    if (_isloggedIn) {
      getLoggedInUserDetails(loggedInUser?.id);
      fetchWishList();
      setCurrentStore(loggedInUser.stores!?.[0]);
    }
  }, [_isloggedIn]);
  return (
    <IonApp>
      <BrowserRouter>
        <IonReactRouter>
          {size.width < 768 && (
            isAdminLoggedIn ? <AdminSlideMenu /> : <Menu />
          )}
          <IonRouterOutlet id="main">
            <Switch>
              
              {main.map((e) => {
                return e.roles!?.length > 0 ? (
                  <ProtectedRoute
                    exact
                    path={e.path}
                    component={e.component}
                    roles={e.roles}
                    key={e.path}
                  />
                ) : (
                  <Route
                    exact
                    path={e.path}
                    component={e.component}
                    key={e.path}
                  />
                );
              })}
              <Redirect
                path="*"
                strict
                exact
                push
                to={{
                  pathname: '/err',
                  state: {
                    err: `This Page Was Not Found`,
                    code: 404,
                    from: window.location.pathname,
                  },
                }}
              />
            </Switch>
          </IonRouterOutlet>
        </IonReactRouter>
      </BrowserRouter>
    </IonApp>
  );
};

export default App;
