import { Store } from '../../types/store';
import {
  CREATE_STORE,
  CREATE_STORE_ERROR,
  DELETE_STORE,
  DELETE_STORE_ERROR,
  GET_STORE,
  GET_STORES,
  GET_STORES_ERROR,
  GET_STORE_ERROR,
  UPDATE_STORE,
  UPDATE_STORE_ERROR,
} from '../actions/types';

export interface storeState  {
  store: Store | null,
  updateStore: Store | null
  [name: string]: {
    [name: string]: any | undefined;
  } | null;
}
export const storeInitialState:storeState = {
  store: null,
  storeError: null,
  updateStore: null
};

const storeReducer = (state: storeState, action: { type: string; payload: any }) => {
  switch (action.type) {
    case CREATE_STORE:
      return { ...state, storeError: null, store: action.payload };
    case CREATE_STORE_ERROR:
      return { ...state, storeError: action.payload, store: null };
    case GET_STORE:
      return { ...state, storeError: null, store: action.payload };
    case GET_STORE_ERROR:
      return { ...state, storeError: action.payload, store: null };
    case GET_STORES:
      return { ...state, bulkStoresError: null, bulkStores: action.payload };
    case GET_STORES_ERROR:
      return { ...state, bulkStoresError: action.payload, bulkStores: null };
    case UPDATE_STORE:
      return { ...state, storeError: null, updateStore: action.payload };
    case UPDATE_STORE_ERROR:
      return { ...state, storeError: action.payload, store: null };
    case DELETE_STORE:
      return { ...state, storeError: null, store: action.payload };
    case DELETE_STORE_ERROR:
      return { ...state, storeError: action.payload, store: null };
    default:
      return state;
  }
};

export default storeReducer;
