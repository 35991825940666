import React, { useContext, useEffect, useRef } from 'react';
import classes from './styles.module.scss';
import { DeviceContext } from '../../../deviceType';
import Desktop from './desktop';
import Mobile from './mobile';
import { RiEditBoxLine } from 'react-icons/ri';
import { IoCreate } from 'react-icons/io5';
import { FaCartShopping } from 'react-icons/fa6';
import { FaShareAlt } from 'react-icons/fa';
import { person, storefrontOutline } from 'ionicons/icons';
import testVIDEO from '../../../Assets/video/testvideo.mp4';
import Hls from 'hls.js';

const Landing = () => {
  const size = useContext(DeviceContext);
  const videoRef = useRef<any>(null);
  // this is a mock up object it just gives the structure the landing ppage should have
  const landing_page_data = {
    showcase: {
      primary: ` Create Your shop, Share, Sell! `,
      secondary:
        'Create your online shop in minutes and reach thousands of customers effortlessly',
      showcase_stat: [
        {
          label: 'Shops',
          icon: storefrontOutline,
          statistic: 233,
          description: 'Join our vibrant marketplace.',
        },
        {
          label: 'Shoppers',
          icon: person,
          statistic: 233,
          description: 'Transform window shoppers  into loyal customers',
        },
      ],
    },
    becomevendor: {
      label: 'Become A Vendor',
      sub: 'Ready to start your online shop? Watch this quick guide to learn how easy it is to create your shop and start reaching customers on Bazaar Cheap',
      video: testVIDEO,
      steps: [
        {
          path: '/registervendor',
          icon: <RiEditBoxLine className={classes.icon_data} />,
          label: 'Register As Vendor',
          text: 'Lorem ipsum dolor sit amet consectetur. Ut ut ultrices ornare ut vestibulum parturient. Auctor nulla et sem ornare. Quisque iaculis condiment',
        },
        {
          path: '/dashboard/create-store',
          icon: <IoCreate className={classes.icon_data} />,
          label: 'Register Your Shop',
          text: 'Lorem ipsum dolor sit amet consectetur. Ut ut ultrices ornare ut vestibulum parturient. Auctor nulla et sem ornare. Quisque iaculis condiment',
        },
        {
          path: '/dashboard/store',
          icon: <FaCartShopping className={classes.icon_data} />,
          label: 'Create Products',
          text: 'Lorem ipsum dolor sit amet consectetur. Ut ut ultrices ornare ut vestibulum parturient. Auctor nulla et sem ornare. Quisque iaculis condiment',
        },
        {
          path: '/dashboard/store#share',
          icon: <FaShareAlt className={classes.icon_data} />,
          label: 'Share Shop Catalog',
          text: 'Lorem ipsum dolor sit amet consectetur. Ut ut ultrices ornare ut vestibulum parturient. Auctor nulla et sem ornare. Quisque iaculis condiment',
        },
      ],
    },
    whyus: {
      label: 'Why Bazaar Cheap?',
      sub: 'We empower vendors to create and manage online shops effortlessly. Explore the key features that make our platform stand out:',
      list: [
        'Easy shop Creation',
        'Detailed Analytics Dashboard',
        'Easily share your  products catalogue',
        'Manage multiple shops',
        'Product Listings',
        'Customer Wish Lists',
      ],
    },
    contact: {
      label: 'Contact Us',
      sub: 'If you have any questions or need support, please fill out the form below and we will get back to you as soon as possible.',
      contacts: [
        '00000000',
        'email@provider.com',
        '11111111111',
        'anotheremail@provider.com',
      ],

    },
    faqs: {
      label: 'FAQS',
      faq: [
        {
          title: 'Reset Account Password',
          content:
            'Sed ut perspiciatis, unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem Eaque ipsa, quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt, explicabo. Nemo enim ipsam voluptatem, quia voluptas sit, aspernatur aut odit aut fugit,  Eed quia consequuntur magni dolores eos, qui ratione voluptatem sequi nesciunt, neque porro quisquam est, qui dolorem ipsum, quia dolor sit amet consectetur adipisci velit, sed quia non numquam eius modi Tempora inci[di]dunt, ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis laboriosam, nisi ut aliquid ex ea commodi consequatur? [D]Quis autem vel eum i[r]ure reprehenderit, qui in ea voluptate velit esse, ',
        },
        {
          title: 'Reset Account Password',
          content:
            'Sed ut perspiciatis, unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem Eaque ipsa, quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt, explicabo. Nemo enim ipsam voluptatem, quia voluptas sit, aspernatur aut odit aut fugit,  Eed quia consequuntur magni dolores eos, qui ratione voluptatem sequi nesciunt, neque porro quisquam est, qui dolorem ipsum, quia dolor sit amet consectetur adipisci velit, sed quia non numquam eius modi Tempora inci[di]dunt, ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis laboriosam, nisi ut aliquid ex ea commodi consequatur? [D]Quis autem vel eum i[r]ure reprehenderit, qui in ea voluptate velit esse, ',
        },
        {
          title: 'Reset Account Password',
          content:
            'Sed ut perspiciatis, unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem Eaque ipsa, quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt, explicabo. Nemo enim ipsam voluptatem, quia voluptas sit, aspernatur aut odit aut fugit,  Eed quia consequuntur magni dolores eos, qui ratione voluptatem sequi nesciunt, neque porro quisquam est, qui dolorem ipsum, quia dolor sit amet consectetur adipisci velit, sed quia non numquam eius modi Tempora inci[di]dunt, ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis laboriosam, nisi ut aliquid ex ea commodi consequatur? [D]Quis autem vel eum i[r]ure reprehenderit, qui in ea voluptate velit esse, ',
        },
        {
          title: 'Reset Account Password',
          content:
            'Sed ut perspiciatis, unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem Eaque ipsa, quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt, explicabo. Nemo enim ipsam voluptatem, quia voluptas sit, aspernatur aut odit aut fugit,  Eed quia consequuntur magni dolores eos, qui ratione voluptatem sequi nesciunt, neque porro quisquam est, qui dolorem ipsum, quia dolor sit amet consectetur adipisci velit, sed quia non numquam eius modi Tempora inci[di]dunt, ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis laboriosam, nisi ut aliquid ex ea commodi consequatur? [D]Quis autem vel eum i[r]ure reprehenderit, qui in ea voluptate velit esse, ',
        },
      ],
    },
  };

  useEffect(() => {
    const video = videoRef.current;
    const hls = new Hls({
      debug: true,
      enableWorker: true,
      lowLatencyMode: true,
      backBufferLength: 90,
    });
    const url = landing_page_data.becomevendor.video;

    if (Hls.isSupported()) {
      hls.loadSource(url);
      hls.attachMedia(video);
      hls.on(Hls.Events.ERROR, (err, data) => {
        console.log(err);
        console.warn('error data', data)
      });
      hls.on(Hls.Events.MANIFEST_PARSED, function () {
        video.play();
      });
    } else {
      console.log('hls not supported');
    }
  }, []);

  return size.width < 769 ? (
    <Mobile data={landing_page_data} video={videoRef} />
  ) : (
    <Desktop data={landing_page_data} video={videoRef} />
  );
};

export default Landing;
