import { axiosInstance } from '../../utils/axios';
import { Dispatch } from 'react';
import {
  CREATE_STORE,
  CREATE_STORE_ERROR,
  GET_STORE_TYPES,
  STORE_TYPES_ERROR,
  GET_STORE,
  GET_STORE_ERROR,
  GET_STORES,
  GET_STORES_ERROR,
  UPDATE_STORE_ERROR,
  UPDATE_STORE,
  DELETE_STORE,
  DELETE_STORE_ERROR,

} from './types';
import { Store } from '../../types/store';
import { setCurrentStore } from '../../utils/currentStore';
import { addToLocalStorage } from '../../utils';

export const createStore =
  (payload: any) =>
  async (dispatch: Dispatch<{ type: string; payload: any }>) => {
    try {
      const res = await axiosInstance.post('/v1/stores', payload);
      addToLocalStorage({key:'token', value:res.data.data?.token});
      setCurrentStore(res.data?.data)
      dispatch({
        type: CREATE_STORE,
        payload: res.data,
      });
    } catch (e) {
      dispatch({
        type: CREATE_STORE_ERROR,
        payload: e,
      });
    }
  };

export const getStoreTypes =
  () => async (dispatch: Dispatch<{ type: string; payload: any }>) => {
    try {
      const res = await axiosInstance.get('/v1/stores/types');
      dispatch({
        type: GET_STORE_TYPES,
        payload: res.data.data,
      });
    } catch (e: any) {
      dispatch({
        type: STORE_TYPES_ERROR,
        payload: e,
      });
    }
  };

export const getStore =
  (id: number | string) =>
  async (dispatch: Dispatch<{ type: string; payload: any }>) => {
    try {
      const res = await axiosInstance.get(`/v1/stores/${id}`);
      const store = {
        ...res.data.data,
      };
      dispatch({
        type: GET_STORE,
        payload: store,
      });
    } catch (error: any) {
      dispatch({
        type: GET_STORE_ERROR,
        payload: error,
      });
    }
  };

export const updateStore =
  (payload: any) =>
  async (dispatch: Dispatch<{ type: string; payload: any }>) => {
    try {
      const res = await axiosInstance.put(
        `/v1/stores/${payload.id}`,
        payload.store
      );

      dispatch({
        type: UPDATE_STORE,
        payload: res.data.data,
      });
    } catch (error: any) {
      dispatch({
        type: UPDATE_STORE_ERROR,
        payload: error,
      });
    }
  };

export const getStores =
  () => async (dispatch: Dispatch<{ type: string; payload: any }>) => {
    try {
      const res = await axiosInstance.get(`/v1/stores`);
      dispatch({
        type: GET_STORES,
        payload: res.data.data,
      });
    } catch (error: any) {
      dispatch({
        type: GET_STORES_ERROR,
        payload: error,
      });
    }
  };

export const deleteStore =
  (payload: { id: number | string }) =>
  async (dispatch: Dispatch<{ type: string; payload: any }>) => {
    try {
      const res = await axiosInstance.delete(`/v1/stores/${payload.id}`);
       dispatch({
         type: DELETE_STORE,
         payload: res.data.data,
       });
    } catch (error: any) {
       dispatch({
         type: DELETE_STORE_ERROR,
         payload: error,
       });
    }
  };

