import { IonSkeletonText } from '@ionic/react';
import React, { useContext } from 'react';
import CardSkeleton from '../../../components/skeletons/card-skeleton';
import { DeviceContext } from '../../../deviceType';
import classes from './styles.module.scss';

const Loading = (props: { cards: number }) => {
  const size = useContext(DeviceContext);
  return size.width >= 768 ? (
    <div className={classes.loadingBody}>
      <div className={classes.sidebar}>
        {Array.from(Array(3).keys()).map((item, ind) => (
          <div key={ind}>
            <IonSkeletonText
              animated={true}
              style={{ width: '100%', height: '20px' }}
            ></IonSkeletonText>
            <>
              {Array.from(Array(3).keys()).map((e, i) => (
                <div key={i}>
                  <IonSkeletonText
                    animated={true}
                    style={{ width: '40%', height: '15px' }}
                  ></IonSkeletonText>
                  <IonSkeletonText
                    animated={true}
                    style={{ width: '60%', height: '15px' }}
                  ></IonSkeletonText>
                </div>
              ))}
            </>
          </div>
        ))}
      </div>
      <div className={classes.main}>
        {Array.from(Array(props.cards).keys()).map((item, index) => (
          <div key={index} className={classes.holder}>
            <CardSkeleton type={'flex'} />
          </div>
        ))}
      </div>
    </div>
  ) : (
    <div className={classes.mobileLoadingBody}>
      <IonSkeletonText
        animated={true}
        style={{ width: '100%', height: '30px' }}
      ></IonSkeletonText>
      <div className={classes.filters}>
        <IonSkeletonText
          animated={true}
          style={{ width: '60%', height: '25px' }}
        ></IonSkeletonText>
        <IonSkeletonText
          animated={true}
          style={{ width: '35%', height: '25px' }}
        ></IonSkeletonText>
      </div>
      <div className={classes.cards}>
        {Array.from(Array(props.cards).keys()).map((item, index) => (
          <div key={index} className={classes.holder}>
            <CardSkeleton type={'grid'} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Loading;
