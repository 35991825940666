import classes from './styles.module.scss'
import { IonButton } from '@ionic/react'
import { useHistory } from 'react-router'

const PasswordResetSuccess = ()=>{
    const history = useHistory()
    const handleClick = ()=>{
    history.push('login')
    }

    return(
        <section className={classes.desktop}>
            <div>
            <h1>Password Reset</h1>
            <p>Your password has been successfully reset</p>
            <p>Click below to log in</p>
            <IonButton
              className={classes.Btn}
              fill="solid"
              color="secondary"
              onClick={handleClick}
            >
                Log In
            </IonButton>
            </div>
        </section>
    )
}

export default PasswordResetSuccess;