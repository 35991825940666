import { GET_PRODUCT_TYPES, GET_PRODUCT_TYPES_ERROR } from '../actions/types';

export const productTypeInitialState = {};

const productTypesReducer = (
  state = productTypeInitialState,
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case GET_PRODUCT_TYPES:
      return { productTypesError: null, productTypes: action.payload };
    case GET_PRODUCT_TYPES_ERROR:
      return { productTypesError: action.payload, productTypes: null };
    default:
      return state;
  }
};

export default productTypesReducer;