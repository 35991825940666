import { useContext, useEffect, useState } from 'react';
import classes from './styles.module.scss';
import {
  IonCol,
  IonGrid,
  IonIcon,
  IonRippleEffect,
  IonRow,
  useIonActionSheet,
} from '@ionic/react';
import { DeviceContext } from '../../deviceType';
import { ellipsisVertical } from 'ionicons/icons';
import useGlobalContext from '../../context/provider';
import { deleteProducts } from '../../context/actions/products';
import {
  DELETE_PRODUCT,
  DELETE_PRODUCT_ERROR,
} from '../../context/actions/types';
import {  useHistory } from 'react-router';
import ImageCarousel from '../ImageCarousel';
import WishlistIcon from '../../pages/user/wish-list/wish-list-icon';
import { ProductType } from '../../types/product';
import ErrorToast from '../error_toast';

const ItemDetailsCard = ({
  data,
  productId,
  onDestroy,
}: {
  data: ProductType;
  productId: string;
  onDestroy: () => any;
}) => {
  const size = useContext(DeviceContext);
  const { productState, productDispatch } = useGlobalContext();
  const history = useHistory();
  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const [present] = useIonActionSheet();
  const { updatedAt, createdAt, id, ...formData } = data;
  const [isErrorOpen, setIsErrorOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const options = [
    {
      name: 'Edit',
      action: () => {
        setOpenMenu(!openMenu);
        history.push({
          pathname: `/dashboard/edit-product/detail?q=${id}&i=${data.name.slice(
            0,
            30
          )}`,
          state: { id },
        });
        onDestroy();
      },
    },
    {
      name: 'Delete',
      action: () => {
        setOpenMenu(!openMenu);
        presentDeletePrompt();
      },
    },
  ];

  useEffect(() => {
    if (productState.deleteProduct != null) {
      // product deleted successfully
      history.push('/dashboard/store');
      productDispatch({ type: DELETE_PRODUCT, payload: null });
    }
    if (productState?.deleteProductError) {
      setIsErrorOpen(true);
      setErrorMessage(
        productState?.deleteProductError!?.response!?.data!?.message
          ? productState?.deleteProductError!?.response!?.data!
              ?.message
          : productState?.deleteProductError!?.message
      );

      productDispatch({ type: DELETE_PRODUCT_ERROR, payload: null });
    }
  }, [productState]);

  function dimensions() {
    if (size.width > 768) {
      return { width: '10vw', height: '20vh' };
    } else if (size.width > 460) {
      return { width: '65vw', height: '30vh' };
    } else {
      return { width: '30vw', height: '20vh' };
    }
  }
  async function deletedata(id: string) {
    await deleteProducts({ id })(productDispatch);
  }
  function presentDeletePrompt() {
    present({
      header: 'Are You Sure You Want To Delete This Product?',
      subHeader: 'This action cannot be reversed.',
      buttons: [
        {
          text: 'Delete',
          role: 'destructive',
          handler: () => {
            deletedata(`${id}`);
          },
          data: {
            action: 'delete',
          },
        },
        {
          text: 'Cancel',
          role: 'cancel',
          data: {
            action: 'cancel',
          },
        },
      ],
      cssClass: 'deletePrompt',
      backdropDismiss: true,
    });
  }
  return (
    <div className={classes.card}>
      <ErrorToast
        message={errorMessage}
        type={'error'}
        isOpen={isErrorOpen}
        onClose={() => {
          setIsErrorOpen(false);
        }}
      />
      {size.width > 768 && (
        <IonGrid className={classes.desktopGrid}>
          <IonRow className={classes.actions}>
            <IonCol>
              <div
                className={
                  classes.btn + ' ion-activatable ripple-parent circle'
                }
                onClick={() => {
                  setOpenMenu(!openMenu);
                }}
              >
                <IonRippleEffect></IonRippleEffect>
                <IonIcon icon={ellipsisVertical} color="primary"></IonIcon>
              </div>
            </IonCol>
          </IonRow>
          <IonRow className={classes.info}>
            <IonCol size={'4.5'} className={classes.images}>
              <div className={classes.img}>
                <ImageCarousel
                  data={data.images as string[]}
                  name={data.name}
                />
              </div>
            </IonCol>
            <IonCol size={'7.5'} className={classes.typography}>
              <div className={classes.title}>
                <div>{data.name}</div>
                <div>
                  <WishlistIcon productId={productId} />
                </div>
              </div>
              <div className={classes.description}>{data.description}</div>
              <div className={classes.price}>
                UGX {`${data.price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              </div>
            </IonCol>
          </IonRow>

          <IonRow className={classes.details}>
            <IonCol size="12">
              <div className={classes.label}>Details</div>
              <div
                className={classes.rtfHolder + ' ql-container'}
                dangerouslySetInnerHTML={{ __html: data.details }}
              ></div>
            </IonCol>
          </IonRow>
        </IonGrid>
      )}
      {size.width <= 768 && (
        <IonGrid className={classes.mobileGrid}>
          <IonRow className={classes.actions}>
            <IonCol>
              <div
                className={
                  classes.btn + ' ion-activatable ripple-parent circle'
                }
                onClick={() => {
                  setOpenMenu(!openMenu);
                }}
              >
                <IonRippleEffect></IonRippleEffect>
                <IonIcon icon={ellipsisVertical} color="primary"></IonIcon>
              </div>
            </IonCol>
          </IonRow>
          <IonRow className={classes.images}>
            <IonCol size={'12'}>
              <div className={classes.img}>
                <ImageCarousel
                  data={data.images as string[]}
                  name={data?.name}
                />
              </div>
            </IonCol>
          </IonRow>
          <IonRow className={classes.info}>
            <IonCol size={'12'}>
              <div className={classes.title}>
                <div>{data.name}</div>
                <div>
                  <WishlistIcon productId={productId} />
                </div>
              </div>
              <div className={classes.description}>{data.description}</div>
              <div className={classes.price}>
                UGX {`${data.price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              </div>
            </IonCol>
          </IonRow>
          <IonRow className={classes.details}>
            <IonCol size="12">
              <div className={classes.label}>Details</div>
              <div
                className={classes.rtfHolder + ' ql-container'}
                dangerouslySetInnerHTML={{ __html: data.details }}
              ></div>
            </IonCol>
          </IonRow>
        </IonGrid>
      )}

      {openMenu && (
        <div
          className={classes.backdrop}
          onClick={() => {
            setOpenMenu(false);
          }}
        >
          <div
            className={classes.optionsMenu}
            onClick={(e) => {
              e.stopPropagation();
            }}
            style={{ width: dimensions().width, height: 'auto' }}
          >
            <ul>
              {options.map((item, index) => {
                return (
                  <div
                    className={
                      classes.listItem + ' ion-activatable ripple-parent circle'
                    }
                    key={index}
                    onClick={() => {
                      item.action();
                    }}
                  >
                    <IonRippleEffect></IonRippleEffect>
                    {item.name}
                  </div>
                );
              })}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default ItemDetailsCard;
