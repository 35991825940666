import {
  IonBreadcrumb,
  IonBreadcrumbs,
  IonContent,
  IonPage,
} from '@ionic/react';
import Nav from './nav/index';
import Footer from './footer/index';
import classes from './Layout.module.scss';

const Layout = ({ children, history }: any) => {
  const locations: any[] = [];
  return (
    <IonPage>
      <IonContent className={classes.AppCarrier}>
        <div className={classes.nav} id={'nav'}>
          <Nav />
        </div>
        <div className={classes.bodyContent}>
          <div className={classes.breadCrumbs}>
            <IonBreadcrumbs>
              {locations.map((item: any, index: any) => {
                return (
                  <IonBreadcrumb color="primary" href={`/${item}`} key={index}>
                    {item}
                  </IonBreadcrumb>
                );
              })}
            </IonBreadcrumbs>
          </div>
          <div className={classes.children}>{children}</div>
        </div>
        <div className={classes.footer}>
          <Footer />
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Layout;
