import { IonGrid, IonRow, IonCol } from '@ionic/react';
import FaqsItem from '../../../components/Faqs';
import Layout from '../../../components/Layout/user/Layout';
import { FaqsType } from '../../../types/faqs';
import classes from './styles.module.scss';
import whatsappIcon from '../../../Assets/whatsappIcon.svg';
import callIcon from '../../../Assets/callIcon.svg';
import CopyIcon from '../../../components/copyIcon';
import {faqs} from '../../../data/faqs';
import { Link } from 'react-router-dom';
import {useState} from 'react';
import Btn from '../../../components/Btn';
import ContactUsForm from '../../../components/contactusForm/contact';

const HelpCenter = () => {
  const [loading, setLoading] = useState<boolean>(false);

  const filterQuestions = faqs.filter((item: FaqsType, index: number) => {
    return index <= 4;
  });

  const numbers = ['0784331902', '0788536892', '0781590902'];

  const filter = numbers.filter((number) => {
    return number === '0784331902';
  });
  const filterWhatsapp = numbers.filter((number) => {
    return number === '0781590902';
  });
  const filterCall = numbers.filter((number) => {
    return number === '0788536892';
  });

  return (
    <Layout>
      <section className={classes.Desktop}>
        <IonRow className={classes.help}>
          <IonCol>
            <div className={classes.divhelp}>
              <h1>Help Center</h1>
              <div className={classes.searchflex}>
                <input value="Enter keyword" />
                <div className={classes.button}>
                  <Btn match={true} name="search" loading={loading} />
                </div>
              </div>
            </div>
          </IonCol>
        </IonRow>
        <IonGrid className={classes.grid} fixed={true}>
          <IonRow className={classes.divsection}>
            <IonCol>
              {/* <div className={classes.divdisplay}>
                                <div className={classes.divs}></div>
                                <div className={classes.divs}></div>
                                <div className={classes.divs}></div>
                                <div className={classes.divs}></div>
                            </div> */}
              <h2>Frequently Asked Questions</h2>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <div>
                {filterQuestions.map((item: FaqsType, index: number) => (
                  <FaqsItem
                    position={index}
                    title={item.title}
                    content={item.content}
                  />
                ))}
              </div>
              <div className={classes.adiv}>
                <Link to="/faqs">view all</Link>
              </div>
            </IonCol>
          </IonRow>
          <h3 className={classes.conth3}>Contact Support</h3>

          <IonRow className={classes.contact}>
            <IonCol>
              <div className={classes.message}>
                <div>
                  <div className={classes.svgdisplay}>
                    <div className={classes.svg}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="56"
                        height="56"
                        viewBox="0 0 56 56"
                        fill="none"
                      >
                        <g clip-path="url(#clip0_1916_854)">
                          <path
                            d="M45.9844 5.14844H9.32987C6.80987 5.14844 4.77096 7.21026 4.77096 9.73026L4.74805 50.9666L13.9117 41.803H45.9844C48.5044 41.803 50.5662 39.7412 50.5662 37.2212V9.73026C50.5662 7.21026 48.5044 5.14844 45.9844 5.14844ZM13.9117 21.1848H41.4026V25.7666H13.9117V21.1848ZM32.239 32.6393H13.9117V28.0575H32.239V32.6393ZM41.4026 18.8939H13.9117V14.3121H41.4026V18.8939Z"
                            fill="#EB474F"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_1916_854">
                            <rect
                              width="54.9818"
                              height="54.9818"
                              fill="white"
                              transform="translate(0.164062 0.564453)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                    <div>
                      <div>
                        <h6>Message</h6>
                        <p className={classes.paragraph}>
                          Write to Support with detailed description of the
                          issue. We will get back to you.{' '}
                        </p>
                      </div>
                    </div>
                  </div>
                  <ContactUsForm/>
                </div>
              </div>
            </IonCol>
            <IonCol>
              <div className={classes.whatsapp}>
                <div className={classes.svgdisplay}>
                  <div className={classes.svg}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="72"
                      height="72"
                      viewBox="0 0 72 72"
                      fill="none"
                    >
                      <g clip-path="url(#clip0_1916_863)">
                        <path
                          d="M19.678 32.4611C23.9544 40.8653 30.8441 47.7253 39.2483 52.0314L45.7817 45.4981C46.5835 44.6962 47.7714 44.429 48.8108 44.7853C52.1368 45.8841 55.7302 46.4781 59.4126 46.4781C61.0459 46.4781 62.3823 47.8144 62.3823 49.4478V59.812C62.3823 61.4453 61.0459 62.7817 59.4126 62.7817C31.5271 62.7817 8.92773 40.1823 8.92773 12.2968C8.92773 10.6635 10.2641 9.32715 11.8974 9.32715H22.2914C23.9247 9.32715 25.2611 10.6635 25.2611 12.2968C25.2611 16.009 25.855 19.5726 26.9538 22.8987C27.2805 23.9381 27.0429 25.0962 26.2114 25.9278L19.678 32.4611Z"
                          fill="#EB474F"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1916_863">
                          <rect
                            width="71.2727"
                            height="71.2727"
                            fill="white"
                            transform="translate(0.0185547 0.417969)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <div>
                    <h6>Call/WhatsApp</h6>
                    <p>
                      Support is available on weekdays from Monday to Friday.
                    </p>
                  </div>
                </div>
                <div>
                  <div className={classes.more}>
                    {filter.map((number) => (
                      <p>{number}</p>
                    ))}
                    <div className={classes.icons}>
                      <a href="tel:+256784331902">
                        <img src={callIcon} />
                      </a>
                      <a
                        href="https://wa.me/256784331902"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={whatsappIcon} />
                      </a>
                      <CopyIcon phonenum="0784331902" />
                    </div>
                  </div>
                  <hr className={classes.contlines} />
                  <div className={classes.more}>
                    {filterWhatsapp.map((number) => (
                      <p>{number}</p>
                    ))}
                    <div className={classes.icons}>
                      <a
                        href="https://wa.me/256781590902"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={whatsappIcon} />
                      </a>
                      <CopyIcon phonenum="0781590902" />
                    </div>
                  </div>
                  <hr className={classes.contlines} />
                  <div className={classes.more}>
                    {filterCall.map((number) => (
                      <p>{number}</p>
                    ))}
                    <div className={classes.icons}>
                      <a href="tel:+256788536892">
                        <img src={callIcon} />
                      </a>
                      <CopyIcon phonenum="0788536892" />
                    </div>
                  </div>
                  <hr className={classes.contlines} />
                </div>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </section>
    </Layout>
  );
};
export default HelpCenter;
