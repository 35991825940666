import { IonButton } from '@ionic/react';
import { useContext, useEffect, useState } from 'react';
import SwitchStoreModal from '../currentStoreModal';
import classes from './styles.module.scss';
import { getCurrentStore, setCurrentStore } from '../../utils/currentStore';
import { GlobalContext } from '../../context/provider';
import { getStore } from '../../context/actions/store';
import { storeSchema } from '../../types/store';
import { GET_STORE_ERROR} from '../../context/actions/types';

import ErrorToast from '../error_toast';

const CurrentStoreCard = () => {
  const { userState, storeState, storeDispatch } =
    useContext<any>(GlobalContext);
  const currentStore = getCurrentStore();
  const user = userState.getUser;
  const [stores, setStores] = useState<storeSchema[]>([]);
  const [store, setStore] = useState<storeSchema>({} as storeSchema);
  const [openctx, setOpenctx] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [hasOneStore, setHasOneStore] = useState<boolean>(false);
  const [isErrorOpen, setIsErrorOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  useEffect(() => {
    const user_stores = user.stores;
    if (user_stores.length > 0 && user_stores.length === 1) {
      setHasOneStore(true);
    } else {
      setHasOneStore(false);
    }
  }, []);
  useEffect(() => {
    const user_stores = user.stores;
    if (user_stores) {
      setStores(user_stores);
    }
  }, [currentStore]);

  useEffect(() => {
    if (store?.id) {
      setCurrentStore(store as { id: number; name: string });
      fetchStore(store.id);
    }
  }, [store]);

  useEffect(() => {
    if (storeState.store) {
      setOpenctx(false);
      setLoading(false);
    }
    if (storeState?.storeError) {
      setIsErrorOpen(true);
      setErrorMessage(
        storeState?.storeError.response?.data
          ? storeState!?.storeError.response.data.message
          : storeState!?.storeError.message
      );
      storeDispatch({ type: GET_STORE_ERROR, payload: null });
    }
  }, [storeState]);

  const fetchStore = async (store: number) => {
    setLoading(true);
    await getStore(store)(storeDispatch);
  };

  return (
    <div className={classes.main}>
      <ErrorToast
        message={errorMessage}
        type={'error'}
        isOpen={isErrorOpen}
        onClose={() => {
          setIsErrorOpen(false);
        }}
      />
      <SwitchStoreModal
        stores={stores}
        setCurrent={setStore}
        open={openctx}
        loading={loading}
      />
      <div className={classes.card}>
        <div className={classes.label}>Current Store</div>
        <div className={classes.info}>
          <div className={classes.profile}>
            <img src={store.logo!} alt="Store Logo" />
          </div>
          <div className={classes.name}>{store?.name}</div>
          <div className={classes.btn}>
            <IonButton
              id={'open-current-store-modal'}
              onClick={() => {
                setOpenctx(true);
              }}
              color="secondary"
              fill="solid"
              disabled={hasOneStore}
            >
              Switch Store
            </IonButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CurrentStoreCard;
