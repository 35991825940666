import React, { useContext, useState } from 'react';
import classes from './styles.module.scss';
import {
  IonToolbar,
  IonGrid,
  IonRow,
  IonCol,
  IonButtons,
  IonMenuButton,
  IonTitle,
  IonHeader,
  IonIcon,
} from '@ionic/react';
import StoreMenu from '../../../storeMenu/storeMenu';
import AdminSlideMenu from './menu';
import { User } from '../../../../types/user';
import { menuController } from '@ionic/core';
import { useHistory } from 'react-router-dom';
import logo from '../../../../Assets/logo/logo_white_background_96.svg';
import { DeviceContext } from '../../../../deviceType';

const Nav = ({ adminDetails }: { adminDetails: User }) => {
  const size = useContext(DeviceContext);
  const history = useHistory();
  const [open, setOpen] = useState<any>();
  return (
    <IonHeader>
      <div className={classes.admin_root_holder}>
        <IonGrid>
          <IonRow>
            <IonCol
              size="7"
              className={classes.mobile_logo}
              onClick={() => {
                history.push('/admin/dashboard/home');
              }}
            >
              <IonIcon
                src={logo}
                style={{ width: size.width >= 768 ? '' : '' }}
              ></IonIcon>
              <span className={classes.span1}>Bazaar</span>
              <span className={classes.span2}>Cheap</span>
              <span className={classes.span3}>Admin</span>
            </IonCol>

            <IonCol size="2" className={classes.menu}>
              <IonButtons>
                <IonMenuButton
                  color={'primary'}
                  onClick={() => {
                    setOpen(menuController.isOpen('admin-menu'));
                  }}
                />
              </IonButtons>
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
    </IonHeader>
  );
};

export default Nav;
