import React from 'react'
import { Swiper, SwiperSlide} from 'swiper/react';
import { IonContent } from '@ionic/react';
import { Pagination } from 'swiper/modules';
import styles from './styles.module.scss';
import { cutString, toTitleCase } from '../../utils';

const ImageCarousel = ({data, name}:{data?:string[],  name:string})=>{
  const _name = name?.length > 20 ? `${cutString(name,20)}...`: name

  if(data?.length){
    return  <IonContent  className={styles.container} scrollX>
       { /* https://swiperjs.com/demos , https://www.npmjs.com/package/swiper      */}
        <Swiper className={styles.swiper} pagination={true} modules={[Pagination]}  >
          {data?.map((url)=>(
              <SwiperSlide key={url} >
                <img src={url} alt=""/>
              </SwiperSlide>
          ))}
        </Swiper>
      </IonContent>
    }

    return <div className={styles.imageWrapper}>
           <p>{toTitleCase(_name)}</p>
        </div>
}

export default ImageCarousel
