import {
  IonIcon,
  IonRippleEffect,
} from '@ionic/react';
import {
  callSharp,
  informationCircle,
  locationSharp,
  searchSharp,
  storefrontSharp,
} from 'ionicons/icons';
import React, { useContext, useEffect, useState } from 'react';
import { DeviceContext } from '../../../deviceType';
import SearchFieldLocal from './searchfield';
import classes from './styles.module.scss';
import { storeSchema } from '../../../types/store';

const StorePageHead = ({store, setSearchText, searchText, handleSearch} :{
  store: storeSchema;
  setSearchText: (params: any) => void;
  handleSearch: ()=>void
  searchText: string
}) => {
  const size = useContext(DeviceContext);
  const [openSearch, setOpenSearch] = useState<boolean>(false);
  const [locations, setLocations] = useState<string[]>([]);
  const [tel, setTel] = useState<string[]>([]);

  useEffect(() => {
    const locs = store!?.location!?.split(',');
    const tel = store!?.phoneNumber?.split(',');

    setLocations(locs);
    setTel(tel!);
  }, [store]);

  return (
    <div
      className={classes.storeHead}
      style={{ height: size.width > 768 ? '70vh' : '95vh' }}
    >
      <div
        className={classes.infoBar}
        style={{ height: size.width > 768 ? '85%' : '90%' }}
      >
        <div
          className={classes.headImg}
          style={{ height: size.width > 768 ? '65%' : '40%' }}
        >
          <div className={classes.overlay}></div>
          <div className={classes.img}>
            <img src={store!?.banner} alt="" />
          </div>
        </div>
        <div className={classes.profile}>
          <div className={classes.infogrid}>
            <div className={classes.profileInfo}>
              <div className={classes.profileImg}>
                <div>
                  <img src={store!?.logo!} alt="" />
                </div>
              </div>
              <div className={classes.storeInfo}>
                <div className={classes.name}>{store!?.name}</div>
                <div className={classes.type}>
                  <IonIcon
                    icon={storefrontSharp}
                    color="secondary"
                    slot="start"
                    size="small"
                  />
                  <p>{store!?.type}</p>
                </div>
              </div>
            </div>
            <div className={classes.contacts}>
              <div className={classes.locs}>
                <div className={classes.icon}>
                  <IonIcon
                    icon={locationSharp}
                    color="secondary"
                    size="small"
                  />
                </div>
                <div className={classes.locations}>
                  {locations?.map((item, index) => {
                    return (
                      <div className={classes.item} key={index}>
                        {item}
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className={classes.tel}>
                <div className={classes.icon}>
                  <IonIcon icon={callSharp} color="secondary" size="small" />
                </div>
                <div className={classes.phoneNumber}>
                  {tel?.map((item, index) => {
                    return (
                      <div className={classes.item} key={index}>
                        {item}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            {size.width < 768 && (
              <div className={classes.bio}>
                <div className={classes.label}>
                  <IonIcon
                    icon={informationCircle}
                    color="secondary"
                    size="small"
                  />
                </div>
                <div className={classes.about}>
                  {/** Description Goes here */}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div
        className={classes.drawer}
        style={{ height: size.width > 768 ? '15%' : '10%' }}
      >
        <div className={classes.holder}>
            <div className={classes.search}>
              {size.width < 768 ? (
                <div
                  className={
                    classes.searchbtn +
                    ' ion-activatable ripple-parent rectangle'
                  }
                  onClick={() => {
                    setOpenSearch(!openSearch);
                  }}
                >
                  <IonRippleEffect></IonRippleEffect>
                  <IonIcon
                    icon={searchSharp}
                    color={'secondary'}
                    size="small"
                    slot="start"
                  />
                </div>
              ) : (
                <div className={classes.searchfieldDesktop}>
                  <SearchFieldLocal
                  setSearchText={setSearchText}
                  text={searchText}
                  handleSearch={handleSearch}
                  />
                </div>
              )}

              {openSearch && (
                <div className={classes.searchfieldMobile}>
                  <SearchFieldLocal
                      setSearchText={setSearchText}
                      text={searchText}
                      handleSearch={handleSearch}
                  />
                </div>
              )}
            </div>
        </div>
      </div>
    </div>
  );
};

export default StorePageHead;
