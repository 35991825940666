import { useContext } from 'react';
import { DeviceContext } from '../../../../deviceType';
import Desktop from './desktop';
import Mobile from './mobile';
import { GlobalContext } from '../../../../context/provider';

const Nav = ({
  backgroundColor,
}: {
  backgroundColor?: 'primary' | 'white';
}) => {
  const { userState } = useContext<any>(GlobalContext);
  const userDetails = userState.getUser;
  const size = useContext(DeviceContext);

  return size.width < 769 ? (
    <Mobile user={userDetails} backgroundColor={backgroundColor!} />
  ) : (
    <Desktop user={userDetails} backgroundColor={backgroundColor!} />
  );
};
export default Nav;
