import {
  IonCard,
  IonCardContent,
  IonCol,
  IonGrid,
  IonImg,
  IonRow,
  IonSearchbar,
} from '@ionic/react';
import React, { useContext, useEffect, useState } from 'react';
import Layout from '../../../components/Layout/dashboard';
import classes from './styles.module.scss';
import help_chat from '../../../Assets/help/help_chat.svg';
import call_helpline from '../../../Assets/help/call_helpline.svg';
import faqs from '../../../Assets/help/faqs.svg';
import { DeviceContext } from '../../../deviceType';
import StoreBar from '../../../components/storeBar';

const quickLinks = [
  { name: 'Chat Live With Us', img: help_chat, event: 'event' },
  {
    name: 'Talk To a Customer Care Advisor',
    img: call_helpline,
    event: 'event',
  },
  { name: 'FAQs', img: faqs, event: 'event' },
];

const Help = () => {
  const size = useContext(DeviceContext);
  const [searchText, setSearch] = useState<string>('');
  useEffect(() => {
    console.log(searchText);
  }, [searchText]);
  return (
    <Layout>
      {size.width > 769 ? (
        <IonGrid className={classes.main}>
          <IonRow>
            <IonCol size="8.5" className={classes.container}>
              <IonGrid className={classes.holder}>
                <IonRow className={classes.heading}>
                  <IonCol size="12">Customer Support Center</IonCol>
                </IonRow>
                <IonRow className={classes.search}>
                  <IonCol size="12">
                    <IonSearchbar
                      animated
                      placeholder="Describe Your Issue"
                      value={searchText}
                      onIonChange={(e) => setSearch(e.detail.value!)}
                    ></IonSearchbar>
                  </IonCol>
                </IonRow>
                <IonRow className={classes.quickLinks}>
                  <IonCol>
                    <div>
                      {quickLinks.map((item, index) => {
                        return (
                          <IonCard
                            key={index}
                            className={classes.linkCard}
                            button
                          >
                            <div>
                              <IonGrid>
                                <IonRow className={classes.img}>
                                  <IonCol size="10">
                                    <IonImg src={item.img} />
                                  </IonCol>
                                </IonRow>
                                <IonRow className={classes.name}>
                                  <IonCol>{item.name}</IonCol>
                                </IonRow>
                              </IonGrid>
                            </div>
                          </IonCard>
                        );
                      })}
                    </div>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonCol>
            <IonCol size="3.5" className={classes.storeBar}>
              <StoreBar/>
            </IonCol>
          </IonRow>
        </IonGrid>
      ) : (
        <IonGrid className={classes.mobile}>
          <IonRow className={classes.head}>
            <IonCol size="12">Customer Support Center</IonCol>
          </IonRow>
          <IonRow className={classes.search}>
            <IonCol size="12">
              <IonSearchbar
                animated
                placeholder="Describe Your Issue"
                value={searchText}
                onIonChange={(e) => setSearch(e.detail.value!)}
              ></IonSearchbar>
            </IonCol>
          </IonRow>
          <IonRow className={classes.quickLinks}>
            <IonCol size="12">
              <div>
                {quickLinks.map((item, index) => {
                  return (
                    <IonCard key={index} className={classes.linkCard} button>
                      <div>
                        <IonGrid>
                          <IonRow className={classes.img}>
                            <IonCol size="10">
                              <IonImg src={item.img} />
                            </IonCol>
                          </IonRow>
                          <IonRow className={classes.name}>
                            <IonCol>{item.name}</IonCol>
                          </IonRow>
                        </IonGrid>
                      </div>
                    </IonCard>
                  );
                })}
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      )}
    </Layout>
  );
};

export default Help;
