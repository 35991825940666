import React, { useContext } from 'react';
import classes from './styles.module.scss';
import { IonPage, IonContent, IonGrid, IonCol, IonRow } from '@ionic/react';
import { DeviceContext } from '../../../deviceType';
import Spinner from '../../spinner';
import Nav from './nav';
import SideNav from './sidenav';
import useGlobalContext from '../../../context/provider';

const Layout = ({ children }: any) => {
  const size = useContext(DeviceContext);
  const {userState} = useGlobalContext();
  const user = userState!.getUser;
  return (
    <IonPage style={{ height: '100%', overflow: 'hidden !important' }}>
      <IonContent>
        {user?.id ? (
          size.width >= 768 ? (
            <div className={classes.root_container}>
              <div className={classes.root_content}>
                <IonGrid className={classes.root_grid}>
                  <IonRow className={classes.root_row}>
                    <IonCol size="3">
                      <SideNav adminDetails={user} />
                    </IonCol>
                    <IonCol size="9" className={classes.root_children}>
                      {children}
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </div>
            </div>
          ) : (
            <>
              <Nav adminDetails={user} />
              <div className={classes.root_content_mobile}>{children}</div>
            </>
          )
        ) : (
          <div
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Spinner />
          </div>
        )}
      </IonContent>
    </IonPage>
  );
};

export default Layout;
