import { IonCol, IonRow } from '@ionic/react'
import { Link, useHistory } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react'
import Btn from '../../../components/Btn'
import { GlobalContext } from '../../../context/provider'
import InputCard from '../components/inputCard';
import classes from './styles.module.scss'
import { RESETPASSWORD, RESETPASSWORD_ERROR } from '../../../context/actions/types';
import { resetPassword } from '../../../context/actions/user';
import { eye } from 'ionicons/icons';
import AuthLayout from '../components/AuthLayout';

const ResetPassword: React.FC = (props: any) => {
    const history = useHistory()
    const { email, code } = (props.location && props.location.state)
    const { userState, userDispatch } = useContext<any>(GlobalContext)
    const [loading, setLoading] = useState<boolean>(false);
    const [match, setMatch] = useState<boolean | undefined>(false)
    const [error, setError] = useState<{ state: boolean; message: string | null }>({
        state: false,
        message: null
    });
    const [validationError, setValidationError] = useState<{state: boolean; message: string | null}>({
        state: false,
        message: null
    })
    const [form, setForm] = useState<{
        password: string,
        confirm: string
    }>({
        password: '',
        confirm: ''
    })

    useEffect(() => {
        if (userState?.resetPassword) {
            const form = userState.resetPassword?.form
            setForm(form)
            userDispatch({ type: RESETPASSWORD, payload: null })
            history.push('/reset-password-success')
        }
        if (userState.resetPasswordError) {
            setError({
                state: true,
                message: userState?.resetPasswordError?.response?.data.message
            })
            userDispatch({ type: RESETPASSWORD_ERROR, payload: null })
        }
    }, [userState.resetPassword, userState.resetPasswordError])

    useEffect(() => {
        if (form?.password.trim().length > 0 || form?.confirm.trim().length > 0) {
            setMatch(true)
        }
        else {
            setMatch(false)
        }
    }, [form?.password, form?.confirm])

    const submitHandler = async (e: any) => {
        e.preventDefault();
        setError({
            state: false,
            message: null
        })
        if (form?.confirm !== form?.password) {
            setError({
                state: true,
                message: 'Passwords do not match'
            })
            return;
        }
        if(form.password.length < 6 || form.confirm.length < 6){
            setValidationError({
                state: true,
                message: 'Password should be 6 characters or more'
            })
            return;
        }
        setLoading(true)
        const user = {
            password: form.password,
            code,
            email
        }
        await resetPassword(user)(userDispatch)
        setLoading(false)
    }
    return (
        <AuthLayout>
            <section className={classes.desktop}>
                <form onSubmit={submitHandler}>
                    <h1>Set New Password</h1>
                    <p>Your new password should be diffrent from previously used passwords </p>
                    {error && (<p className={classes.error}>{error.message}</p>)}
                    {validationError && (
                        <p className={classes.error}>{validationError.message}</p>
                    )}
                    <IonRow>
                        <IonCol>
                            <InputCard
                                title={'New password'}
                                type={'password'}
                                name={'password'}
                                minLength={undefined}
                                maxLength={undefined}
                                setForm={setForm}
                                icon={eye}
                            />
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <InputCard
                                title={'Confirm password'}
                                type={'password'}
                                name={'confirm'}
                                minLength={undefined}
                                maxLength={undefined}
                                setForm={setForm}
                                icon={eye}
                            />
                        </IonCol>
                    </IonRow>
                    <IonRow className={classes.Btn}>
                        <IonCol>
                            <Btn
                                name="Submit"
                                onClick={e => e}
                                loading={loading}
                                match={match}
                            />
                        </IonCol>
                    </IonRow>
                    <Link to="login"><p>Back To Login</p></Link>
                    </form>
            </section>
        </AuthLayout>
    )

}

export default ResetPassword;