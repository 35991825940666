import {
  IonModal,
  IonButton,
  IonRadio,
  IonRadioGroup,
  IonIcon,
} from '@ionic/react';
import { useEffect, useRef, useState } from 'react';
import classes from './styles.module.scss';
import { closeOutline } from 'ionicons/icons';
import { getCurrentStore } from '../../utils/currentStore';
import { storeSchema } from '../../types/store';
import Spinner from '../spinner';

const SwitchStoreModal = ({
  stores,
  setCurrent,
  open,
  loading,
}: {
  stores: storeSchema[];
  setCurrent: (arg0: (form: any) => any) => void;
  open: boolean;
  loading: boolean;
}) => {
  const modal = useRef<HTMLIonModalElement>(null);
  const currentStoreId = getCurrentStore();
  const [currentStore, setCurrentStore] = useState<string>('');

  useEffect(() => {
    setCurrentStore(currentStoreId!);
  }, []);

  const updateCurrent = () => {
    const current = stores.find((store) => store.id === Number(currentStore));
    setCurrent(() => current);
  };

  return (
    <IonModal ref={modal} trigger="open-current-store-modal" isOpen={open}>
      {loading ? (
        <div className={classes.loading}>
          <Spinner type={'crescent'}></Spinner>
        </div>
      ) : (
        <div className={classes.content}>
          <div className={classes.close}>
            <IonButton
              id="close-current-store-modal"
              fill="clear"
              color="primary"
              onClick={() => {
                modal.current!?.dismiss();
              }}
            >
              <IonIcon slot="icon-only" icon={closeOutline}></IonIcon>
            </IonButton>
          </div>
          <div className={classes.head}>Change Current Store</div>
          <div className={classes.body}>
            <div className={classes.list}>
              {currentStore &&
                stores.map((item, index) => {
                  return (
                    <div key={index} className={classes.listItem}>
                      <div className={classes.input}>
                        <IonRadioGroup
                          onIonChange={(e) => {
                            setCurrentStore(e.target.value);
                          }}
                          value={currentStore}
                        >
                          <IonRadio
                            value={item.id}
                            color="secondary"
                            mode="md"
                          ></IonRadio>
                        </IonRadioGroup>
                      </div>
                      <div className={classes.name}>{item.name}</div>
                      <div className={classes.options}></div>
                    </div>
                  );
                })}
            </div>
          </div>
          <div className={classes.btns}>
            <IonButton color="secondary" fill="solid" onClick={updateCurrent}>
              Apply
            </IonButton>
          </div>
        </div>
      )}
    </IonModal>
  );
};

export default SwitchStoreModal;
