import React, { useContext } from 'react';
import { DeviceContext } from '../../../../deviceType';
import Desktop from './desktop';
import Mobile from './mobile';

const Footer = () => {
  const size = useContext(DeviceContext);
    return size.width < 769 ? <Mobile/>: <Desktop/>
};

export default Footer;
