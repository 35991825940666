import { IonCol, IonRow } from '@ionic/react'
import {Link, useHistory} from 'react-router-dom';
import { useContext, useEffect, useState } from 'react'
import InputCard from '../components/inputCard'
import { forgotPassword } from '../../../context/actions/user'
import { GlobalContext } from '../../../context/provider'
import Btn from '../../../components/Btn'
import { FORGOTPASSWORD, FORGOTPASSWORD_ERROR } from '../../../context/actions/types'
import classes from './styles.module.scss'
import AuthLayout from '../components/AuthLayout';
import { DeviceContext } from '../../../deviceType';

const ForgotPassword:React.FC = ()=>{
    const size = useContext<any>(DeviceContext);

    const {userState, userDispatch} = useContext<any>(GlobalContext)
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [active, setActive] = useState<boolean | undefined>(false);
    const [error, setError] = useState<{ state: boolean; message: string |null }>({
        state: false,
        message: null
      });
    const [form, setForm] =useState<{email: string}>({email: ''})

    useEffect(()=>{
      if(userState?.forgotPassword){
        const email = userState.forgotPassword?.email
        setForm(email)
        userDispatch({type: FORGOTPASSWORD, payload: null})
        history.push({
          pathname:'/verifypassword',
          state: form
        })
      }
      if(userState?.forgotPasswordError){
        setError({
            state: true,
            message: userState?.forgotPasswordError?.response?.data.message
        })
        userDispatch({type: FORGOTPASSWORD_ERROR, payload: null})
      }
    },[userState?.forgotPassword, userState?.forgotPasswordError])

    useEffect(()=>{
      if(form?.email.trim().length>0){
        setActive(true)
      }
    },[form])
    const submitHandlerForgot = async (e: any)=>{
       e.preventDefault()
       setLoading(true)
       await forgotPassword(form)(userDispatch)
       setLoading(false)
    }

    return(
      <AuthLayout>
        <section className={size.width >= 768 ? classes.desktop : classes.mobile}>
          <form onSubmit = {submitHandlerForgot}>
           <div>
           <h2>Forgot Password</h2>
           <p >No! worries, we'll send you reset instructions</p>
           </div>
           {error && (
            <p className={classes.error} >{error.message}</p>
            )}
           <IonRow>
            <IonCol>
               <InputCard
                title={'Enter Your Email'}
                setForm={setForm}
                name={'email'}
                type={'email'}
                />
          </IonCol>
          </IonRow>
        <IonRow className={classes.Btn}>
         <IonCol size="12">
            <Btn onClick={(e)=> e}
                name="Submit"
                loading ={loading}
                match ={active}
                />
        </IonCol>
        </IonRow>
        <Link to="login">Back To Login</Link>
        </form>
        </section>
      </AuthLayout>
    )
}
export default ForgotPassword