import { GET_ADMIN_STORES, GET_ADMIN_STORE_ERRORS } from "../../actions/types";

export interface adminStoreState {}
export const adminStoreInitialState: adminStoreState = {
  adminStores: null,
  getAdminStoreError: null,
};

const adminStoreReducer = (
  state: adminStoreState,
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case GET_ADMIN_STORES:
      return {
        ...state,
        getAdminStoreError: null,
        adminStores: action.payload,
      };
    case GET_ADMIN_STORE_ERRORS:
      return {
        ...state,
        adminStores: null,
        getAdminStoreError: action.payload,
      };
    default:
      return state;
  }
};

export default adminStoreReducer;
