import React from 'react';
import classes from './styles.module.scss';

const SmoothLineChart = (
  props: {
    x: string;
    y: string;
    data: { x: string; y: string }[];
    showKey: boolean;
    smooth?: boolean;
  } = { x: '', y: '', data: [], showKey: true, smooth: false }
) => {
  const col_data = props.data.map((item) => {
    return {
      name: props.x,
      [props.x]: item.x,
      [props.y]: item.y,
    };
  });
  return <div className={classes.chart}></div>;
};

export default SmoothLineChart;
