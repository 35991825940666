import React from 'react';
import SignUp from '../signup';

const RegisterVendor: React.FC = () => {

  return (
    <SignUp role="seller"></SignUp>
  );
};

export default RegisterVendor;
