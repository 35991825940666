import { IonCol, IonRow } from '@ionic/react';
import { useState, useContext, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Btn from '../../../components/Btn';
import InputCard from '../components/inputCard';
import classes from './styles.module.scss';
import { GlobalContext } from '../../../context/provider';
import { forgotPassword, verifyPassword } from '../../../context/actions/user';
import { VERIFYPASSWORD, VERIFYPASSWORD_ERROR } from '../../../context/actions/types';
import AuthLayout from '../components/AuthLayout';

const VerifyPassword: React.FC = (props: any) => {
  const history = useHistory()
  const { email } = (props.location && props.location.state)
  const [loading, setLoading] = useState(false);
  const [match, setMatch] = useState<boolean | undefined>(false);
  const { userState, userDispatch } = useContext<any>(GlobalContext)
  const [counter, setCounter] = useState(60);
  const [error, setError] = useState<{ state: boolean; message: string | null }>({
    state: false,
    message: null
  });

  const [form, setForm] = useState<{ code: string }>({
    code: ''
  })
  const [newForm, setNewForm] = useState<{ email: string }>({
    email
  })

  useEffect(() => {
    if (userState?.verifyPassword) {
      const code = userState.verifyPassword?.code
      setForm(code)
      userDispatch({ type: VERIFYPASSWORD, payload: null })
      const info = { code: form.code, email }
      history.push({
        pathname: '/resetpassword',
        state: info
      })
    }
    if (userState?.verifyPasswordError) {
      setError({
        state: true,
        message: userState?.verifyPasswordError?.response?.data.message
      })
      userDispatch({ type: VERIFYPASSWORD_ERROR, payload: null })
    }
  }, [userState?.verifyPassword, userState?.verifyPasswordError])

  useEffect(() => {
    if (form?.code.trim().length > 0) {
      setMatch(true)
    }
  }, [form?.code])

  useEffect(() => {
    if(counter > 0){
      setTimeout(() =>setCounter(counter - 1), 1000)
    }
  }, [counter])

  const submitHandler = async (event: any) => {
    event.preventDefault()
    setLoading(true)
    await verifyPassword({ code: form.code, email })(userDispatch)
    setLoading(false)
  }

  const resendCode = async (e: any) => {
    e.preventDefault()
    setCounter(60)
    await forgotPassword(newForm)(userDispatch)
  }

  return (
    <AuthLayout>
      <section className={classes.desktop}>
        <form onSubmit={submitHandler}>
          <h1>Check Your Email</h1>
          <p>We've sent a verification code to ({email})</p>
          {error && (<p className={classes.error}>{error.message}</p> )}

          <h5>Verification Code</h5>
          <IonRow>
            <IonCol>
              <InputCard
                title={'Enter code'}
                name={'code'}
                type={'type'}
                setForm={setForm}
              />
            </IonCol>
          </IonRow>
          <IonRow className={classes.Btn}>
            <IonCol>
              <Btn
                loading={loading}
                name={'Submit'}
                match={match}
                onClick={(e) => e}
              />
            </IonCol>
          </IonRow>
          <p>Didn't receive the code? {counter}s : <button onClick={resendCode} disabled={counter > 0}
            style={{ color: counter > 0 ? 'grey' : 'blue' }}
            className={classes.resend}>Resend</button></p>
          <Link to="login"><p>Back to Login</p></Link>
        </form>
      </section>
    </AuthLayout>
  )

}

export default VerifyPassword;