import { useContext, useEffect, useState } from 'react';
import classes from './styles.module.scss';
import Layout from '../../../components/Layout/user/Layout';
import { GlobalContext, GlobalStore } from '../../../context/provider';
import { DeviceContext } from '../../../deviceType';
import { IonCol, IonGrid, IonRow } from '@ionic/react';
import StoreCard from './storeCard';
import ProductCard from './productCard';
import {
  addProductView,
  getSingleProduct,
} from '../../../context/actions/products';
import { GET_SINGLE_PRODUCT_ERROR } from '../../../context/actions/types';
import RecoCard from './reccomendations';
import ProductDetailSkeleton from '../../../components/skeletons/product-details-page';
import { storeSchema } from '../../../types/store';
import { ProductType } from '../../../types/product';
import ErrorToast from '../../../components/error_toast';
import { useLocation } from 'react-router';

const ProductDetails = () => {
  const query = window.location.search.split('&')[0].split('=').slice(-1)[0];
  const size = useContext(DeviceContext);
  const location = useLocation();
  const { productState, productDispatch } =
    useContext<GlobalStore>(GlobalContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [product, setProduct] = useState<ProductType>({} as ProductType);
  const [store, setStore] = useState<storeSchema>({} as storeSchema);
  const [isErrorOpen, setIsErrorOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  useEffect(() => {
    setLoading(true);
    const id = location.state as { id: string | number };
    fetchProduct(id);
  }, [query]);

  useEffect(() => {
    const id = location.state as { id: string | number };
    if (
      productState.getSingleProduct &&
      productState.getSingleProduct?.id === id.id
    ) {
      const res = productState.getSingleProduct;
      const { store } = res;
      setProduct(res);
      setStore(store!);
      addView(res?.id!);
      setLoading(false);
    }
    if (productState?.getSingleProductError) {
      setIsErrorOpen(true);
      setErrorMessage(
        productState.getSingleProductError!?.response!?.data!?.message
          ? productState.getSingleProductError!?.response!?.data!?.message
          : productState.getSingleProductError!?.message
      );
      productDispatch({ type: GET_SINGLE_PRODUCT_ERROR, payload: null });
    }
  }, [productState.getSingleProduct, productState.getSingleProductError]);

  async function fetchProduct(e: { id: string | number }) {
    const state = productState.getSingleProduct;
    if (state && state.id === e.id) {
      setStore(state?.store!);
      return setProduct(state);
    }
    await getSingleProduct(e.id)(productDispatch);
  }
  async function addView(productId: number) {
    await addProductView({ productId })(productDispatch);
  }

  return (
    <Layout>
      <ErrorToast
        message={errorMessage}
        type={'error'}
        isOpen={isErrorOpen}
        onClose={() => {
          setIsErrorOpen(false);
        }}
      />
      {loading === false ? (
        <div className={classes.holder}>
          {size.width > 768 && (
            <IonGrid className={classes.desktop}>
              <IonRow className={classes.container}>
                <IonCol className={classes.leftBar} size={'9'}>
                  <div className={classes.productCardHolder}>
                    <ProductCard data={product} />
                  </div>
                  <div className={classes.recomHolder}>
                    <RecoCard ref_product={product} />
                  </div>
                </IonCol>
                <IonCol className={classes.rightBar} size={'3'}>
                  <div className={classes.storeCardHolder}>
                    <StoreCard data={store!} />
                  </div>
                </IonCol>
              </IonRow>
            </IonGrid>
          )}
          {size.width <= 768 && (
            <IonGrid className={classes.mobile}>
              <IonRow>
                <div className={classes.productCardHolder}>
                  <ProductCard data={product} />
                </div>
                <div className={classes.recom}>
                  <RecoCard ref_product={product} />
                </div>
              </IonRow>
            </IonGrid>
          )}
        </div>
      ) : (
        <div className={classes.loading}>
          <ProductDetailSkeleton />
        </div>
      )}
    </Layout>
  );
};

export default ProductDetails;
