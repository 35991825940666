import {
  IonCard,
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
  IonRippleEffect,
} from '@ionic/react';
import { caretDown, caretForward } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import classes from './styles.module.scss';
import { getStoreTypes } from '../../context/actions/store';
import ErrorToast from '../error_toast';
import useGlobalContext from '../../context/provider';

const Filterbar = (props: { action: (arg0: (form: any) => any) => void }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [types, setTypes] = useState<any[]>([]);
  const [active, setActive] = useState<any[]>([]);
  const {storeTypes, storeTypesDispatch} = useGlobalContext();
  const [isErrorOpen, setIsErrorOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  useEffect(() => {
    async function fetch() {
      if (storeTypes?.storeTypes) {
        return setTypes(storeTypes?.storeTypes as any[]);
      }
      await getStoreTypes()(storeTypesDispatch!);
    }
    fetch();
  }, []);
  useEffect(() => {
    props.action((item) => {
      return active;
    });
  }, [active]);
  useEffect(() => {
    if (storeTypes?.storeTypes) {
      setTypes(storeTypes?.storeTypes as any[]);
    }
    if (storeTypes?.storeTypesError) {
      setIsErrorOpen(true);
      setErrorMessage(
        storeTypes?.storeTypesError!?.response!?.data!?.message
          ? storeTypes?.storeTypesError!?.response!?.data!?.message
          : storeTypes?.storeTypesError!?.message
      );
    }
  }, [storeTypes]);
  return (
    <div
      className={classes.backdrop}
      onClick={() => {
        setOpen(false);
      }}
      style={{
        display: open ? 'block' : 'none',
      }}
    >
      <ErrorToast
        message={errorMessage}
        type={'error'}
        isOpen={isErrorOpen}
        onClose={() => {
          setIsErrorOpen(false);
        }}
      />
      <IonCard className={classes.filterby}>
        <IonGrid className={classes.content}>
          <IonRow>
            <IonCol>
              <div className={classes.title}>Filter By:</div>
              <div className={classes.selectedList}>
                {active.map((item, index) => {
                  return (
                    <div className={classes.selectedItem} key={index}>
                      {item}
                    </div>
                  );
                })}
              </div>
              <div
                className={classes.btn + ' ion-activatable ripple-parent'}
                onClick={() => {
                  setOpen(!open);
                }}
              >
                <IonRippleEffect></IonRippleEffect>
                <IonIcon
                  slot={'icon-only'}
                  icon={open ? caretDown : caretForward}
                  color="primary"
                ></IonIcon>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonCard>
      <div
        className={classes.dropdown}
        style={{ display: open ? 'block' : 'none' }}
      >
        {types.map((item, index) => {
          return (
            <div key={index} className={classes.dropdownItem}>
              <input
                type="checkbox"
                checked={active.includes(item)}
                onChange={(e) => {
                  if (e.target.checked) {
                    setActive([...active, item]);
                  }
                  if (!e.target.checked) {
                    const a = active.filter((i) => {
                      return i !== item;
                    });
                    setActive([...a]);
                  }
                }}
              />
              <div>{item}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Filterbar;
