import {
  IonCol,
  IonGrid,
  IonRow,
} from '@ionic/react';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { DeviceContext } from '../../../deviceType';
import classes from './styles.module.scss';
import { getCurrentUser } from '../../../utils/getClientInfo';
import WishlistIcon from '../../../pages/user/wish-list/wish-list-icon';
import { ProductType } from '../../../types/product';
import ImageCarousel from '../../ImageCarousel';

const ProductBlock = (props: { data: ProductType }) => {
  const { images, id, name, price, store } = props.data;
  const size = useContext(DeviceContext);
  const history = useHistory();
  const client = getCurrentUser();
  const [path, setPath] = useState<string>('');
  const location = useLocation();

  useEffect(() => {
    if (client.role === 'seller' && location.pathname.includes('dashboard')) {
      setPath(`/dashboard/items/detail?q=${id}&i=${name.slice(0, 30)}`);
    } else {
      setPath(`/${store?.name}/product/${name.slice(0, 30)}?id=${id}`);
    }
  }, []);

  function dimensions() {
    if (size.width > 769) {
      return { width: '15vw', height: '20vw', margin: '0.5em' };
    } else {
      if (size.width > 460) {
        return { width: '20vw', height: '25vw', margin: '' };
      } else {
        return { width: '40vw', height: '65vw', margin: '0.2em' };
      }
    }
  }

  return (
    <div
      className={classes._block}
      style={{
        width: dimensions().width,
        height: dimensions().height,
        margin: dimensions().margin,
      }}
      onClick={() => {
        history.push({
          pathname: path,
          state: { id },
        });
      }}
    >
      <div className={classes.productImg}>
        <ImageCarousel data={images} name={props.data.name} />
      </div>
      <div className={classes.productContent}>
        <IonGrid className={classes.info}>
          <IonRow className={classes.title}>
            <IonCol
              size="12"
              style={size.width < 460 ? { fontSize: '0.9em' } : {}}
            >
              <div>{props.data.name} </div>
              <div className={classes.icon}>
                <WishlistIcon productId={props.data.id} />
              </div>
            </IonCol>
          </IonRow>

          <IonRow className={classes.price}>
            <IonCol
              size="12"
              style={size.width < 460 ? { fontSize: '0.7em' } : {}}
            >
              UGX{' '}
              {price.toLocaleString('en-US', {
                maximumFractionDigits: 2,
              })}
            </IonCol>
          </IonRow>
          <IonRow className={classes.storeName}>
            <IonCol
              size="12"
              style={size.width < 460 ? { fontSize: '0.8em' } : {}}
            >
              {store?.name}
            </IonCol>
          </IonRow>

          <IonRow className={classes.location}>
            <IonCol size="12">{store?.location?.split(',')?.[0]}</IonCol>
          </IonRow>
        </IonGrid>
      </div>
    </div>
  );
};

export default ProductBlock;
