import { ReactElement, useContext, useEffect, useRef, useState } from 'react';
import Layout from '../../../components/Layout/dashboard';
import { DeviceContext } from '../../../deviceType';
import classes from './styles.module.scss';
import {
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
  IonButton,
  IonRippleEffect,
} from '@ionic/react';
import { gsap } from 'gsap';
import {
  arrowBackSharp,
  arrowForwardSharp,
  keySharp,
  settingsSharp,
  storefrontSharp,
} from 'ionicons/icons';
import { GlobalContext } from '../../../context/provider';
import { AccountTab, StoreTab } from './tabs';
import { storeSchema } from '../../../types/store';
import { getCurrentStore } from '../../../utils/currentStore';
import { User } from '../../../types/user';
import { useHistory } from 'react-router';

interface tab {
  tabid: number;
  tabname: string;
  tabicon: any;
  component: ReactElement | undefined;
}
const Settings = () => {
  const size = useContext<any>(DeviceContext);
  const [hasStore, setHasStore] = useState<boolean | null>(null);
  const [slideMenuOpen, setSlideMenuOpen] = useState<boolean>(false);
  const slideMenu = useRef<any>(null);
  const { userState } = useContext<any>(GlobalContext);
  const user: User = userState.getUser;
  const [store, setStore] = useState<storeSchema>({} as storeSchema);
  const currentStore = getCurrentStore();
  const [activeTab, setActiveTab] = useState<tab | null>(null);
  const history = useHistory();
  const tabs: tab[] = [
    {
      tabid: 1,
      tabname: 'Shops',
      tabicon: storefrontSharp,
      component: <StoreTab stores={user?.stores!} />,
    },
    {
      tabid: 2,
      tabname: 'Account',
      tabicon: settingsSharp,
      component: <AccountTab />,
    },
    { tabid: 3, tabname: 'Security', tabicon: keySharp, component: undefined },
  ];

  useEffect(() => {
    if (currentStore) {
      setHasStore(true);
      if (user?.stores?.length) {
        const current = user?.stores!?.find(
          (item) => item.id === Number(currentStore)
        );
        setStore(current!);
      }
    } else {
      setHasStore(false);
    }
    setActiveTab(tabs[0]);
    animateSlideMenu();
  }, []);

  useEffect(() => {
    animateSlideMenu();
  }, [slideMenuOpen]);

  // functions
  const swicthActiveTab = (e: tab) => {
    setActiveTab(e);
  };
  const animateSlideMenu = () => {
    const elem = slideMenu.current;
    if (!slideMenuOpen) {
      gsap.to(elem, {
        width: '15%',
        rotation: 0,
        yoyo: false,
        ease: 'sine.inOut',
        duration: 0.15,
      });
      return;
    } else
      gsap.to(elem, {
        width: '100%',
        rotation: 0,
        yoyo: false,
        ease: 'sine.inOut',
        duration: 0.15,
      });
  };

  return (
    <Layout>
      <div className={classes.mainContainer}>
        {size.width >= 768 && (
          <div className={classes.desktop}>
            <IonGrid className={classes.desktopgrid}>
              <IonRow className={classes.headbar}>
                <IonCol size={'12'}>
                  {hasStore && (
                    <div className={classes.hasStore}>
                      <div className={classes.profile}>
                        <img
                          src={
                            (store?.logo! as any) instanceof File
                              ? URL.createObjectURL(store?.logo! as any)
                              : store?.logo!
                          }
                          alt="profile"
                        />
                      </div>
                      <div className={classes.store}>
                        <div className={classes.name}>{store?.name}</div>
                        <div className={classes.type}>
                          <IonIcon
                            icon={storefrontSharp}
                            color="secondary"
                            slot="start"
                          ></IonIcon>
                          {store?.type}
                        </div>
                      </div>
                      <div className={classes.btn}>
                        <IonButton
                          fill="solid"
                          color="secondary"
                          expand="block"
                          onClick={() => {
                            history.push('store');
                          }}
                        >
                          Go To Store
                        </IonButton>
                      </div>
                    </div>
                  )}
                  {!hasStore && (
                    <div className={classes.noStore}>
                      <div className={classes.msg}>
                        You do not have a store registered. Please Register a
                        store to start selling on Bazaar Cheap
                      </div>
                      <div className={classes.btn}>
                        <IonButton
                          fill="solid"
                          color="secondary"
                          expand="block"
                          routerLink="/dashboard/create-store"
                        >
                          Create Store
                        </IonButton>
                      </div>
                    </div>
                  )}
                </IonCol>
              </IonRow>
              <IonRow className={classes.content}>
                <IonCol className={classes.sideMenu} size="3">
                  <div className={classes.holder}>
                    {tabs.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className={
                            classes.tab +
                            ' ion-activatable ripple-parent circle'
                          }
                          onClick={() => {
                            swicthActiveTab(item);
                          }}
                        >
                          <IonRippleEffect></IonRippleEffect>
                          <IonIcon
                            icon={item.tabicon}
                            color={
                              activeTab?.tabid === item.tabid
                                ? 'secondary'
                                : 'primary'
                            }
                          ></IonIcon>
                          <div
                            className={classes.label}
                            style={{
                              color:
                                activeTab?.tabid === item.tabid
                                  ? '#A94064'
                                  : '#0f324d',
                            }}
                          >
                            {item.tabname}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </IonCol>
                <IonCol className={classes.holder} size="9">
                  <div className={classes.tabs}>{activeTab?.component}</div>
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
        )}
        {size.width < 768 && (
          <div className={classes.mobile}>
            <IonGrid className={classes.mobileGrid}>
              <IonRow className={classes.headbar}>
                <IonCol size={'12'}>
                  {hasStore && (
                    <div className={classes.hasStore}>
                      <div className={classes.profile}>
                        <img
                          src={
                            (store?.logo! as any) instanceof File
                              ? URL.createObjectURL(store?.logo! as any)
                              : store?.logo!
                          }
                          alt="profile"
                        />
                      </div>
                      <div className={classes.store}>
                        <div className={classes.name}>{store?.name}</div>
                        <div className={classes.type}>
                          <IonIcon
                            icon={storefrontSharp}
                            color="secondary"
                            slot="start"
                            size="small"
                          ></IonIcon>
                          {store?.type}
                        </div>
                      </div>
                      <div className={classes.btn}>
                        <IonButton
                          fill="solid"
                          color="secondary"
                          expand="block"
                          routerLink="/dashboard/store"
                        >
                          Go To Store
                        </IonButton>
                      </div>
                    </div>
                  )}
                  {!hasStore && (
                    <div className={classes.noStore}>
                      <div className={classes.msg}>
                        You do not have a store registered. Please Register a
                        store to start selling on Bazaar Cheap
                      </div>
                      <div className={classes.btn}>
                        <IonButton
                          fill="solid"
                          color="secondary"
                          expand="block"
                          routerLink="/dashboard/create-store"
                        >
                          Create Store
                        </IonButton>
                      </div>
                    </div>
                  )}
                </IonCol>
              </IonRow>
              <IonRow className={classes.content}>
                <IonCol>
                  <div
                    className={classes.slideMenu}
                    ref={slideMenu}
                    style={{
                      background: slideMenuOpen ? 'rgba(0, 0, 0, 0.15)' : '',
                    }}
                  >
                    <div
                      className={classes.list}
                      style={{ width: slideMenuOpen ? '80%' : '100%' }}
                    >
                      {tabs.map((item, index) => {
                        return (
                          <div
                            key={index}
                            className={
                              classes.tab +
                              ' ion-activatable ripple-parent circle'
                            }
                            onClick={(e) => {
                              swicthActiveTab(item);
                            }}
                            style={{
                              justifyContent: slideMenuOpen
                                ? 'start'
                                : 'center',
                            }}
                          >
                            <IonRippleEffect></IonRippleEffect>
                            <IonIcon
                              icon={item.tabicon}
                              color={
                                activeTab?.tabid === item.tabid
                                  ? 'secondary'
                                  : 'primary'
                              }
                            ></IonIcon>
                            {slideMenuOpen && (
                              <div
                                className={classes.label}
                                style={{
                                  color:
                                    activeTab?.tabid === item.tabid
                                      ? '#A94064'
                                      : '#0f324d',
                                }}
                              >
                                {item.tabname}
                              </div>
                            )}
                          </div>
                        );
                      })}

                      <div
                        className={
                          classes.tab + ' ion-activatable ripple-parent circle'
                        }
                        onClick={(e: any) => {
                          setSlideMenuOpen(!slideMenuOpen);
                        }}
                        style={{
                          marginTop: 'auto',
                          justifyContent: slideMenuOpen ? 'start' : 'center',
                        }}
                      >
                        <IonRippleEffect></IonRippleEffect>
                        <IonIcon
                          icon={
                            slideMenuOpen ? arrowBackSharp : arrowForwardSharp
                          }
                          color={'primary'}
                        ></IonIcon>
                        {slideMenuOpen && (
                          <div
                            className={classes.label}
                            style={{
                              color: 'grey',
                            }}
                          >
                            Collapse Menu
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className={classes.tabHolder}>
                    <div className={classes.tab}>{activeTab?.component}</div>
                  </div>
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default Settings;
