import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCol,
  IonGrid,
  IonIcon,
  IonInput,
  IonLabel,
  IonRippleEffect,
  IonRow,
} from '@ionic/react';
import { locationOutline } from 'ionicons/icons';
import { useContext, useState } from 'react';
import { DeviceContext } from '../../deviceType';
import classes from './styles.module.scss';

const LocCard = (props: {
  id: number | undefined;
  data: any;
  isOpen: boolean;
  clickEvent: any;
  setList: (arg0: (form: any) => any) => void;
  openCtx: (e: any) => void;
}) => {
  const size = useContext(DeviceContext);

  const [location, setLocation] = useState<{
    name: string;
    coo: { lat: number; lng: number };
  }>(
    props.data === undefined
      ? {
          name: '',
          coo: { lat: 0, lng: 0 },
        }
      : {
          name: props.data.name,
          coo: { lat: props.data.coo.lat, lng: props.data.coo.lng },
        }
  );

  return (
    <div
      className={classes.backdrop}
      style={{ display: props.isOpen ? 'flex' : 'none' }}
      onClick={(e) => {
        e.stopPropagation();
        props.openCtx(e);
      }}
    >
      <IonCard
        className={classes.LocCard}
        onClick={(e) => {
          e.stopPropagation();
        }}
        style={
          size.width > 769
            ? {
                top: `${props.clickEvent.clientY - 200}px`,
                left: `${props.clickEvent.pageX - 607}px`,
                height: '25vh',
                width: '50vh',
              }
            : {
                top: `40%`,
                left: `10%`,
                height: '40vw',
                width: '70vw',
              }
        }
      >
        <IonCardContent>
          <IonGrid className={classes._container}>
            <IonRow className={classes._name}>
              <IonCol size="12">
                <div className={classes.text}>
                  <IonLabel position="floating" color="primary">
                    Name
                  </IonLabel>
                  <IonInput
                    type="text"
                    value={location!.name}
                    onIonChange={(e: any) => {
                      setLocation({
                        name: e.detail.value,
                        coo: { lat: location!.coo.lat, lng: location!.coo.lng },
                      });
                    }}
                  ></IonInput>
                </div>
                <div
                  onClick={(e) => {}}
                  className={
                    classes._icon + ' ion-activatable ripple-parent circle'
                  }
                >
                  <IonRippleEffect></IonRippleEffect>
                  <IonIcon color="secondary" icon={locationOutline}></IonIcon>
                </div>
              </IonCol>
            </IonRow>
            <IonRow className={classes._coordinates}>
              <IonCol size="12">
                <div>
                  <IonLabel position="floating" color="primary">
                    Lat
                  </IonLabel>
                  <IonInput
                    type="number"
                    placeholder="Lat"
                    value={location!.coo.lat}
                    onIonChange={(e: any) => {
                      setLocation({
                        name: location!.name,
                        coo: { lat: e.detail.value, lng: location!.coo.lng },
                      });
                    }}
                  ></IonInput>
                </div>
                <div>
                  <IonLabel position="floating" color="primary">
                    Lon
                  </IonLabel>
                  <IonInput
                    type="number"
                    placeholder="Lat"
                    value={location!.coo.lng}
                    onIonChange={(e: any) => {
                      setLocation({
                        name: location!.name,
                        coo: { lat: location!.coo.lat, lng: e.detail.value },
                      });
                    }}
                  ></IonInput>
                </div>
              </IonCol>
            </IonRow>
            <IonRow className={classes._btn}>
              <IonCol size="12">
                <div className={classes.one}>
                  <IonButton
                    color="dark"
                    onClick={(e) => {
                      props.openCtx(e);
                    }}
                    fill="clear"
                  >
                    Cancel
                  </IonButton>
                </div>
                <div className={classes.two}>
                  <IonButton
                    disabled={location.name === '' && true}
                    color="secondary"
                    onClick={(e) => {
                      props.setList((form) => {
                        if (props.id !== undefined) {
                          form[props.id!] = location;
                        } else {
                          form.push(location);
                        }
                        return [...form];
                      });
                      props.openCtx(e);
                    }}
                    fill="solid"
                  >
                    Save
                  </IonButton>
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonCardContent>
      </IonCard>
    </div>
  );
};

export default LocCard;
