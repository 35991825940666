import { useState } from 'react';
import { uploadFile, FileUploadType } from '../utils/fileUpload';

export type ImageUploadType = {
  files: File[];
  limit?: number;
  existingFilesLength?: number;
};

export const useFileUpload = () => {
  const [error, setError] = useState<string | null>(null);
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);

  const _handleImageUpload = ({
    files,
    limit = 1,
    existingFilesLength = 0,
  }: ImageUploadType) => {
    const remainder = limit - files?.length - existingFilesLength;
    if (files?.length) {
      if (remainder < 0) {
        setError(`You can only upload a maxmum of ${limit} images`);
        return;
      }

      for (const file of files) {
        if (file.size > Math.floor(6 * 1048576)) {
          setError('Image Size Limit is 6 Mbs.');
          return;
        }
      }
      setUploadedFiles((prev) => [...prev, ...files]);
    }
  };

  const _uploadFilesToApi = async ({ files, type, folder }: FileUploadType) => {
    if (!files?.length) return [];
    const result = await uploadFile({ files, type, folder });
    return result;
  };

  return {
    files: uploadedFiles,
    error,
    setUploadedFiles,
    handleImageUpload: ({
      files,
      limit,
      existingFilesLength,
    }: ImageUploadType) =>
      _handleImageUpload({ files, limit, existingFilesLength }),
    uploadFile: async ({ files, type, folder }: FileUploadType) =>
      await _uploadFilesToApi({ files, type, folder }),
  };
};
