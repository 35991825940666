import { GET_TOP_CATEGORIES, GET_TOP_CATEGORIES_ERROR } from '../actions/types';


export interface topCategoriesState {
  [name: string]: {
    [name: string]: any | undefined;
  } | null;
}

export const topCategoriesInitialState: topCategoriesState = {
  topCategories: null,
  topCategoriesError: null,
};

const topCategoriesReducer = (
  state: topCategoriesState,
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case GET_TOP_CATEGORIES:
      return {
        ...state,
        topCategories: action.payload,
        topCategoriesError: null,
      };
    case GET_TOP_CATEGORIES_ERROR:
      return {
        ...state,
        topCategories: null,
        topCategoriesError: action.payload,
      };
    default:
      return state;
  }
};

export default topCategoriesReducer;
