import { IonGrid, IonRow, IonCol, IonSkeletonText } from '@ionic/react';
import React, { useContext } from 'react';
import { DeviceContext } from '../../../deviceType';

const ProductDetailSkeleton = () => {
  const size = useContext(DeviceContext);
  return size.width > 768 ? (
    <IonGrid style={{ width: '100%', height: '100%', padding: ' 0 2%' }}>
      <IonRow>
        <IonCol size={'9'} style={{ display: 'flex' }}>
          {' '}
          <div style={{ width: '50%', height: '25vw', padding: '2%' }}>
            <IonSkeletonText
              animated={true}
              style={{ width: '100%', height: '100%' }}
            ></IonSkeletonText>
          </div>
          <div
            style={{
              width: '50%',
              height: '25vw',
              padding: '2%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <IonSkeletonText
              animated={true}
              style={{ width: '100%', height: '20%' }}
            ></IonSkeletonText>
            <IonSkeletonText
              animated={true}
              style={{ width: '80%', height: '30%' }}
            ></IonSkeletonText>
            <IonSkeletonText
              animated={true}
              style={{ width: '100%', height: '10%' }}
            ></IonSkeletonText>
            <IonSkeletonText
              animated={true}
              style={{ width: '100%', height: '10%' }}
            ></IonSkeletonText>
          </div>
        </IonCol>{' '}
        <IonCol size={'3'}>
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <IonSkeletonText
              animated={true}
              style={{
                width: '10vw',
                height: '10vw',
                borderRadius: '50%',
                top: '9%',
              }}
            ></IonSkeletonText>{' '}
            <div
              style={{
                width: '90%',
                height: '15vw',
                padding: '2%',
                display: 'flex',
                flexDirection: 'row',
                margin: '0 auto',
              }}
            >
              {[1, 2].map((item: number) => {
                return (
                  <div
                    key={item}
                    style={{
                      width: '50%',
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'space-evenly',
                    }}
                  >
                    {' '}
                    <IonSkeletonText
                      animated={true}
                      style={{
                        width: '80%',
                        height: '15%',
                      }}
                    ></IonSkeletonText>
                    <IonSkeletonText
                      animated={true}
                      style={{
                        width: '80%',
                        height: '10%',
                      }}
                    ></IonSkeletonText>
                    <IonSkeletonText
                      animated={true}
                      style={{
                        width: '80%',
                        height: '10%',
                      }}
                    ></IonSkeletonText>
                    <IonSkeletonText
                      animated={true}
                      style={{
                        width: '80%',
                        height: '0%',
                      }}
                    ></IonSkeletonText>
                  </div>
                );
              })}
            </div>
          </div>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol size={'9'}>
          <div style={{ width: '100%', height: '10vw', padding: '1%' }}>
            <IonSkeletonText
              animated={true}
              style={{ width: '80%', height: '60%' }}
            ></IonSkeletonText>
            <IonSkeletonText
              animated={true}
              style={{ width: '50%', height: '20%' }}
            ></IonSkeletonText>
          </div>
        </IonCol>
      </IonRow>
    </IonGrid>
  ) : (
    <IonGrid style={{ width: '100%', height: '100%', padding: ' 0 2%' }}>
      <IonRow style={{ display: 'flex', justifyContext: 'center' }}>
        <IonCol size={'11'}>
          <IonSkeletonText
            animated={true}
            style={{ width: '90%', height: '45vh', margin: '10px auto' }}
          ></IonSkeletonText>
          <IonSkeletonText
            animated={true}
            style={{ width: '90%', height: '6vh', margin: '10px auto' }}
          ></IonSkeletonText>
          <IonSkeletonText
            animated={true}
            style={{ width: '80%', height: '10vh', margin: '8px auto' }}
          ></IonSkeletonText>
          <IonSkeletonText
            animated={true}
            style={{
              width: '40%',
              height: '5vh',
              margin: '8px 0',
              marginLeft: '5%',
            }}
          ></IonSkeletonText>
          <IonSkeletonText
            animated={true}
            style={{ width: '90%', height: '4vh', margin: '8px auto' }}
          ></IonSkeletonText>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default ProductDetailSkeleton;
