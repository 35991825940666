import {
  IonCard,
  IonCardContent,
  IonIcon,
  IonInput,
  IonItem,
  IonTextarea,
  IonButton,
} from '@ionic/react';
import classes from './styles.module.scss';
import { useMemo, useState, } from 'react';
import { eyeOff } from 'ionicons/icons';

interface InputCardTypes {
  type: any;
  title: string;
  name: string;
  icon?: string;
  iconPosition?: 'start' | 'end';
  onIconClick?: () => void;
  maxLength?: number | undefined;
  minLength?: number | undefined;
  setForm: (form: any) => void;
  value?: any;
  inputType?: string;
}
const InputCard = ({
  type,
  title,
  name,
  icon,
  iconPosition = 'end',
  onIconClick,
  maxLength,
  minLength,
  setForm,
  value,
  inputType,
}: InputCardTypes) => {

  const extraProps = useMemo(()=>{
     const _extraProps:any = {
      value
     }

     for(const item of Object.keys(_extraProps)){ // remove undefiked keys from extra props
       if(!_extraProps[item]){
        delete _extraProps[item]
       }
     }
     return _extraProps

  },[value])

  const [showPassword, setShowPassword] = useState<boolean>(false);

  const toggleShowPassword = (e: any) => {
    e.preventDefault();
    setShowPassword(!showPassword);
  }


  return (
    <IonCard className={classes.inputCard}>
      <IonCardContent>
        <IonItem lines="none">
          {inputType === 'textarea' ? (
            <IonTextarea
              required
              placeholder={title}
              name={name}
              color="primary"
              minlength={minLength}
              maxlength={maxLength}
              onIonChange={(e) => {
                setForm((prev: any) => ({
                  ...prev,
                  [name]: e.detail!.value,
                }));
              }}
              {...extraProps}
            ></IonTextarea>
          ) : (
            <IonItem lines="none">
              <IonInput
                required
                type={(type === 'password' && showPassword) ? 'text' : type}
                placeholder={title}
                name={name}
                color="primary"
                minlength={minLength}
                maxlength={maxLength}
                onIonChange={(e) => {
                  setForm((prev: any) => ({
                    ...prev,
                    [name]: e.detail!.value,
                  }));
                }}
                {...extraProps}
              ></IonInput>
              {icon && (
                <IonButton
                  className={classes['input-button']}
                  fill="clear"
                  slot={iconPosition}
                  aria-label="Show/hide"
                  onClick={type === 'password' ? toggleShowPassword : onIconClick}
                >
                  <IonIcon
                    className={classes['input-icon']}
                    icon={ showPassword ? eyeOff : icon}
                    slot="icon-only"
                    aria-hidden="true"
                  />
                </IonButton>
              )}
            </IonItem>
          )}
        </IonItem>
      </IonCardContent>
    </IonCard>
  );
};

export default InputCard;
