import { IonButton, IonModal, IonRadio, IonRadioGroup } from '@ionic/react';
import React, { useEffect, useRef, useState } from 'react';
import classes from './styles.module.scss';
import { useHistory } from 'react-router';

const CreateProductsModal = ({
  open,
  openCtx,
}: {
  open: boolean;
  openCtx: (param: boolean) => void;
}) => {
  const modal = useRef<any>();
  const [_open, setOpen] = useState<boolean>(false);
  const [option, setOption] = useState<{ name: string; path: string } | null>(
    null
  );
  const history = useHistory();
  useEffect(() => {
    setOpen(open);
  }, [open]);

  const options = [
    {
      name: 'Single Product',
      path: '/dashboard/create-product',
    },
    {
      name: 'Bulk Products',
      path: '/dashboard/bulk-products',
    },
  ];
  function submit() {
    history.push(option?.path!);
  }
  function cancel() {
    modal.current.dismiss();
    setTimeout(() => openCtx(false), 200);
  }
  return (
    <IonModal
      ref={modal}
      isOpen={_open}
      animated
      backdropDismiss
      onWillDismiss={cancel}
      breakpoints={[0, 0.7]}
      className={classes.modalCont}
    >
      <div className={classes.head}>Create Products</div>
      <div className={classes.content}>
        <IonRadioGroup value={option?.name}>
          {options.map((item, index) => {
            return (
              <div
                className={classes.item}
                key={index}
                onClick={() => setOption(item)}
              >
                <IonRadio color="secondary" value={item.name}></IonRadio>
                <div className={classes.label}>{item.name}</div>
              </div>
            );
          })}
        </IonRadioGroup>
      </div>
      <div className={classes.actions}>
        <IonButton color="secondary" fill="clear" onClick={cancel}>
          Cancel
        </IonButton>
        <IonButton
          disabled={!option}
          color="secondary"
          fill="solid"
          onClick={submit}
        >
          Submit
        </IonButton>
      </div>
    </IonModal>
  );
};
export default CreateProductsModal;
