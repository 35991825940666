import React from 'react'
import Layout from '../../../components/Layout/admin'

const Home = () => {
  return (
    <Layout>
        home
    </Layout>
  )
}

export default Home