import { IonIcon } from '@ionic/react';
import { grid, reorderFour } from 'ionicons/icons';
import React, { useContext, useEffect, useRef, useState } from 'react';
import classes from './styles.module.scss';
import { gsap } from 'gsap';
import { DeviceContext } from '../../deviceType';

const DisplaySegment = (props: {
  action: (arg0: (form: any) => any) => void;
}) => {
  const dragRef = useRef<any>(null);
  const dragContainer = useRef<any>(null);
  const item0 = useRef<any>(null);
  const item1 = useRef<any>(null);
  const [active, setActive] = useState(0);
  const size = useContext(DeviceContext);

  useEffect(() => {
    if (active === 0) {
      gsap.to(dragRef.current, {
        x: size.width > 769 ? -24 : -25,
        rotation: 0,
        yoyo: false,
        ease: 'sine.inOut',
        duration: 0.5,
      });
    }
    if (active === 1) {
      gsap.to(dragRef.current, {
        x: size.width > 769 ? 24 : 25,
        rotation: 0,
        yoyo: false,
        ease: 'sine.inOut',
        duration: 0.5,
      });
    }

    item0.current.addEventListener('click', (e: any) => {
      e.stopPropagation();
      setActive(0);
      props.action((e: any) => {
        return 'grid';
      });
    });
    item1.current.addEventListener('click', (e: any) => {
      e.stopPropagation();
      setActive(1);
      props.action((e: any) => {
        return 'flex';
      });
    });
  }, [active]);
  return (
    <div className={classes.segmentHolder} ref={dragContainer}>
      <div className={classes.grid} ref={item0}>
        <IonIcon
          slot="icon-only"
          icon={grid}
          color={active === 0 ? 'secondary' : 'primary'}
        ></IonIcon>
      </div>
      <div className={classes.flex} ref={item1}>
        <IonIcon
          slot="icon-only"
          icon={reorderFour}
          color={active === 1 ? 'secondary' : 'primary'}
        ></IonIcon>
      </div>
      <div className={classes.drawer} style={{ left: '' }} ref={dragRef}></div>
    </div>
  );
};

export default DisplaySegment;
