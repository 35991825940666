import React, {
  useState,
  createContext,
  useLayoutEffect,
} from 'react';

export const DeviceContext = createContext({width:0, height:0});

export const DeviceTypeProvider = ({ children }: any) => {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  useLayoutEffect(() => {
    function updateSize() {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return (
    <DeviceContext.Provider value={{width, height}}>{children}</DeviceContext.Provider>
  );
};
