import { CONTACT_US, CONTACT_US_ERROR } from '../actions/types';

export const contactUsInitialState = {
  contactMessageId: null,
  contactMessageIdError: null,
};

const contactUsReducer = (
  state: any,
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case CONTACT_US:
      return {
        ...state,
        contactMessageId: action.payload,
        contactMessageIdError: null,
      };
    case CONTACT_US_ERROR:
      return {
        ...state,
        contactMessageId: null,
        contactMessageIdError: action.payload,
      };
    default:
      return state;
  }
};

export default contactUsReducer;
