// import { SignupUser } from '../../types/user';
import {
  LOGIN,
  LOGIN_ERROR,
  SIGNUP,
  SIGNUP_ERROR,
  GETUSER,
  GETUSERERROR,
  FORGOTPASSWORD,
  FORGOTPASSWORD_ERROR,
  VERIFYPASSWORD,
  VERIFYPASSWORD_ERROR,
  RESETPASSWORD,
  RESETPASSWORD_ERROR,
  ADMINLOGIN,
  ADMINLOGIN_ERROR,
  BECOMESELLER,
  BECOMESELLER_ERROR,
  UPDATE_USER_ERROR,
  UPDATE_USER,
} from '../actions/types';

export interface userState {
  getUser?: any;
  [name: string]: {
    [name: string]: any | undefined;
  } | null;
  updateUser: any
}
export const userInitialState: userState = {
  // signup:{},
  // signupError:null
  updateUser: null
};

const userReducer = (
  state: userState,
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case SIGNUP:
      return { ...state, signup: action.payload, signupError: null };
    case SIGNUP_ERROR:
      return { ...state, signupError: action.payload, signup: null };
    case LOGIN:
      return { ...state, logIn: action.payload, logInError: null };
    case LOGIN_ERROR:
      return { ...state, logInError: action.payload, logIn: null };
    case GETUSER:
      return { ...state, getUser: action.payload, getUserError: null };
    case GETUSERERROR:
      return { ...state, getUserError: action.payload, getUser: null };
    case FORGOTPASSWORD:
      return {
        ...state,
        forgotPassword: action.payload,
        forgotPasswordError: null,
      };
    case FORGOTPASSWORD_ERROR:
      return {
        ...state,
        forgotPasswordError: action.payload,
        forgotPassword: null,
      };
    case VERIFYPASSWORD:
      return {
        ...state,
        verifyPassword: action.payload,
        verifyPasswordError: null,
      };
    case VERIFYPASSWORD_ERROR:
      return {
        ...state,
        verifyPasswordError: action.payload,
        verifyPassword: null,
      };
    case RESETPASSWORD:
      return {
        ...state,
        resetPassword: action.payload,
        resetPasswordError: null,
      };
    case RESETPASSWORD_ERROR:
      return {
        ...state,
        resetPasswordError: action.payload,
        resetPassword: null,
      };
    case ADMINLOGIN:
      return {
        ...state,
        AdminLogin: action.payload,
        AdminLoginError: null,
      };
    case ADMINLOGIN_ERROR:
      return {
        ...state,
        AdminLoginError: action.payload,
        AdminLogin: null,
      };
    case BECOMESELLER:
      return {
        ...state,
        BecomeSeller: action.payload,
        BecomeSellerError: null,
      };
    case BECOMESELLER_ERROR:
      return {
        ...state,
        BecomeSeller: null,
        BecomeSellerError: action.payload,
      };
    case UPDATE_USER:
      return {
        ...state,
        updateUser: action.payload,
        updateUserError: null,
      };
    case UPDATE_USER_ERROR:
      return {
        ...state,
        updateUser: null,
        updateUserError: action.payload,
      };
    default:
      return state;
  }
};

export default userReducer;
