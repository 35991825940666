import { IonButton, IonTabBar, IonTabButton } from '@ionic/react';
import React, { useEffect, useRef, useState } from 'react';
import classes from './styles.module.scss';
import { gsap } from 'gsap';
import DashProductCard from '../cards/dashboardProduct';
import Spinner from '../spinner';
import { getElementPosition } from '../../utils/getElementPosition';

interface TabData {
  label: string;
  default?: boolean;
  data: any[];
}

/**
 * Can display data in separate tabs, the default tab is declared by adding the default property to it
 * The component can be extended to display data using different cards or no card at all, for now Ive implemented
 * display for the home card only and if needed other cards can be addedto the display
 *
 * @param {TabData[]} - data
 * @returns {React.FC}
 */
const MultipleTabCard = ({
  data,
}: {
  data: TabData[];
}) => {
  const [tabs, setTabs] = useState<string[]>([]);
  const [activeTab, setActiveTab] = useState<string>();
  const [_data, setData] = useState<any[]>([]);
  const selectorRef = useRef<any>();
  const activeTabRef = useRef<any>();

  useEffect(() => {
    const labels = data.map((group) => {
      return group.label;
    });
    const defaultTab = data.find((tab, index) => {
      return tab.default;
    });
    if (defaultTab) {
      setData(defaultTab.data);
      setActiveTab(defaultTab.label);
    }
    setTabs(labels);
  }, [data]);

  useEffect(() => {
    if (activeTabRef.current) {
      const activeTabPosition = getElementPosition(activeTabRef.current);
      const selectorPosition = getElementPosition(selectorRef.current);
      const slideDistance = activeTabPosition.x - selectorPosition.x;
      gsap.to(selectorRef.current, {
        x: slideDistance,
        width: activeTabRef.current.getBoundingClientRect().width,
        rotation: 0,
        yoyo: false,
        ease: 'sine.inOut',
        duration: 0.5,
      });
    }
    const activeData = data.find((group) => {
      return group.label === activeTab;
    });
    if (activeData) setData(activeData.data);
  }, [activeTab]);

  const setActive = (e: any) => {
    const labels = e.target.inheritedAttributes;
    setActiveTab(labels['aria-label']);
    activeTabRef.current = e.target;
  };
  return (
    <div className={classes.ProductsCard}>
      <div className={classes.tabHeader}>
        {tabs.map((tab, index) => {
          return (
            <IonButton
              className={classes.button}
              key={index}
              aria-label={tab}
              fill="clear"
              ref={tab === activeTab ? activeTabRef : undefined}
              onClick={setActive}
              style={{ color: tab === activeTab ? '#A94064' : '#0f324d' }}
            >
              {tab}
            </IonButton>
          );
        })}
        <div ref={selectorRef} className={classes.selector}></div>
      </div>
      <div className={classes.tabs}>
        {_data.length > 0 ? (
          _data.map((item, index) => {
            return <DashProductCard data={item} key={index} />;
          })
        ) : (

            <Spinner type="circular" />

        )}
      </div>
    </div>
  );
};

export default MultipleTabCard;
