import { useContext, useEffect, useState } from 'react';
import classes from './styles.module.scss';
import {
  IonButton,
  IonChip,
  IonCol,
  IonGrid,
  IonIcon,
  IonLabel,
  IonList,
  IonListHeader,
  IonRippleEffect,
  IonRow,
} from '@ionic/react';
import logo from '../../../../Assets/logo/logo_white_background_96.svg';
import { useHistory, useLocation } from 'react-router';
import {
  caretForwardSharp,
  caretUpSharp,
  closeSharp,
  fileTraySharp,
  gridSharp,
  logOutSharp,
  personSharp,
  settingsSharp,
  storefrontSharp,
} from 'ionicons/icons';
import { User } from '../../../../types/user';
import { DeviceContext } from '../../../../deviceType';
import { menuController } from '@ionic/core';

const SideNav = ({ adminDetails, action }: { adminDetails: User, action?:any }) => {
  const [currentRoute, setCurrentRoute] = useState<string>('');
  const [approvalLinks, setApprovalLinks] = useState<boolean>(true);
  const size = useContext(DeviceContext);
  const history = useHistory();
  const location = useLocation();
  useEffect(() => {
    if (location.pathname) {
      setCurrentRoute(location.pathname);
    }
  }, [location]);
  return (
    <IonGrid className={classes.sidenav_grid}>
      <IonRow className={classes.logo}>
        <IonCol size="12">
          <div
            className={classes.holder}
            onClick={() => {
              history.push('/admin/dashboard/home');
            }}
          >
            <IonIcon src={logo}></IonIcon>
            <span className={classes.span1}>Bazaar</span>
            <span className={classes.span2}>Cheap</span>
            <span className={classes.span3}>Admin</span>
          </div>
          {size.width < 768 && (
            <IonButton
              className={classes.menuClose}
              fill="clear"
              onClick={() => {
                action.close();
              }}
            >
              <IonIcon slot="icon-only" icon={closeSharp} color="primary" />
            </IonButton>
          )}
        </IonCol>
      </IonRow>
      <IonRow className={classes.admin_bar}>
        <IonCol size="12">
          <IonChip className={classes.adminbar_chip}>
            <div className={classes.user}>
              <IonIcon icon={personSharp} color="primary" size="normal" />
            </div>
            <IonLabel>{adminDetails.firstName}</IonLabel>
            {/* <IonIcon
              icon={props.open ? caretDownOutline : caretForwardOutline}
              color="primary"
            /> */}
          </IonChip>
        </IonCol>
      </IonRow>
      <IonRow className={classes.main_links}>
        <IonCol size="12">
          <IonList>
            {Routes.map((item, index) => {
              if (item.section === 'main') {
                return (
                  <div
                    onClick={() => {
                      history.push(item.path);
                    }}
                    key={index}
                    className={
                      classes.link + ' ion-activatable ripple-parent circle'
                    }
                  >
                    <IonRippleEffect></IonRippleEffect>
                    <IonIcon
                      // slot="end"
                      icon={item.icon}
                      color={
                        currentRoute.includes(item.path)
                          ? 'secondary'
                          : 'primary'
                      }
                      className={classes.btn_icon}
                    ></IonIcon>
                    <span
                      style={{
                        color: currentRoute.includes(item.path)
                          ? 'var(--ion-color-secondary)'
                          : 'var(--ion-color-primary)',
                      }}
                    >
                      {item.name}
                    </span>
                  </div>
                );
              }
              return null;
            })}
          </IonList>
        </IonCol>
      </IonRow>
      <IonRow className={classes.approval_links}>
        <IonCol size="12">
          <IonList>
            <IonListHeader
              onClick={() => {
                setApprovalLinks(!approvalLinks);
              }}
              className="ion-activatable ripple-parent circle"
            >
              <IonRippleEffect></IonRippleEffect>
              <div className={classes.btn}>
                <IonIcon
                  icon={!approvalLinks ? caretForwardSharp : caretUpSharp}
                  slot="icon-only"
                  color="primary"
                ></IonIcon>
              </div>
              <IonLabel>Approvals</IonLabel>
            </IonListHeader>

            {approvalLinks &&
              Routes.map((item, index) => {
                if (item.section === 'approval') {
                  return (
                    <div
                      onClick={() => {
                        history.push(item.path);
                      }}
                      key={index}
                      className={
                        classes.link + ' ion-activatable ripple-parent circle'
                      }
                    >
                      <IonRippleEffect></IonRippleEffect>
                      <IonIcon
                        // slot="end"
                        icon={item.icon}
                        color={
                          currentRoute.includes(item.path)
                            ? 'secondary'
                            : 'primary'
                        }
                        className={classes.btn_icon}
                      ></IonIcon>
                      <span
                        style={{
                          color: currentRoute.includes(item.path)
                            ? 'var(--ion-color-secondary)'
                            : 'var(--ion-color-primary)',
                        }}
                      >
                        {item.name}
                      </span>
                    </div>
                  );
                }
                return null;
              })}
          </IonList>
        </IonCol>
      </IonRow>
      <IonRow className={classes.bottom_links}>
        <IonCol size="12">
          <IonList>
            {Routes.map((item, index) => {
              if (item.section === 'bottom') {
                return (
                  <div
                    onClick={() => {
                      history.push(item.path);
                    }}
                    key={index}
                    className={
                      classes.link + ' ion-activatable ripple-parent circle'
                    }
                  >
                    <IonRippleEffect></IonRippleEffect>
                    <IonIcon
                      // slot="end"
                      icon={item.icon}
                      color={
                        currentRoute.includes(item.path)
                          ? 'secondary'
                          : 'primary'
                      }
                      className={classes.btn_icon}
                    ></IonIcon>
                    <span
                      style={{
                        color: currentRoute.includes(item.path)
                          ? 'var(--ion-color-secondary)'
                          : 'var(--ion-color-primary)',
                      }}
                    >
                      {item.name}
                    </span>
                  </div>
                );
              }
              return null;
            })}
          </IonList>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default SideNav;

export const Routes: {
  path: string;
  name: string;
  section: 'main' | 'approval' | 'bottom';
  icon: any;
  isLogOut?: boolean;
}[] = [
  {
    path: '/admin/dashboard/home',
    name: 'Home',
    section: 'main',
    icon: gridSharp,
  },
  {
    path: '/admin/dashboard/stores',
    name: 'Stores',
    section: 'main',
    icon: storefrontSharp,
  },
  {
    path: '/admin/dashboard/products',
    name: 'Products',
    section: 'main',
    icon: fileTraySharp,
  },
  {
    path: '/admin/dashboard/approvals/stores',
    name: 'Stores',
    section: 'approval',
    icon: storefrontSharp,
  },
  {
    path: '/admin/dashboard/approvals/products',
    name: 'Products',
    section: 'approval',
    icon: fileTraySharp,
  },
  {
    path: '/admin/dashboard/settings',
    name: 'Settings',
    section: 'bottom',
    icon: settingsSharp,
  },
  {
    path: '/admin/login',
    name: 'LogOut',
    section: 'bottom',
    isLogOut: true,
    icon: logOutSharp,
  },
];
