import React, { useContext } from 'react';
import { DeviceContext } from '../../../deviceType';
import {
  IonCard,
  IonCardContent,
  IonCol,
  IonGrid,
  IonImg,
  IonRow,
} from '@ionic/react';
import classes from './styles.module.scss';

const CategoryCard = (props: {
  img: string;
  name: string;
  children: any[];
  onClick?: (event: any)=>void
}) => {
  const size = useContext(DeviceContext);

  const newArray = size.width < 768 ? props.children.slice(0,3): props.children.slice(0,4)

  return (
    <IonCard className={classes.container} onClick={props.onClick}>
      <IonCardContent>
        <IonGrid>
          <IonRow className={classes.topBar}>
            <IonCol size="5" className={classes.head}>
              <IonImg src={props.img}></IonImg>
            </IonCol>
            <IonCol size="7" className={classes.name}>
              {props.name}
            </IonCol>
          </IonRow>
          <IonRow className={classes.itemList}>
            {newArray.map((item, index) =>
              size.width < 768
                ?(
                    <IonCol size="4" className={classes.item} key={index}>
                      <IonImg src={props.img}></IonImg>
                      <div>{item.name}</div>
                    </IonCol>
                  )
                : (
                    <IonCol size="3" className={classes.item} key={index}>
                      <IonImg src={props.img}></IonImg>
                      <div>{item.name}</div>
                    </IonCol>
                  )
            )}
          </IonRow>
        </IonGrid>
      </IonCardContent>
    </IonCard>
  );
};

export default CategoryCard;
